import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonDirectivesModule, CommonToastModule } from '@dmv/common';
import { CoreModule } from '@dmv/core';
import { PublicSharedUtilsModule } from '@dmv/public/shared/utils';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DocAiNotificationComponent } from './doc-ai-notification/doc-ai-notification.component';
import { DocumentUploadHelperTextComponent } from './document-upload-helper-text/document-upload-helper-text.component';
import { MULTI_UPLOAD_PANEL_CONFIGURATION } from './multi-upload-panel.types';
import { MultiUploadPanelsComponent } from './multi-upload-panels/multi-upload-panels.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { UploadPanelModalComponent } from './upload-panel-modal/upload-panel-modal.component';
import { UploadPanelComponent } from './upload-panel/upload-panel.component';

@NgModule({
  declarations: [
    DocumentUploadHelperTextComponent,
    UploadDocumentsComponent,
    UploadPanelComponent,
    UploadPanelModalComponent,
    MultiUploadPanelsComponent,
    DocAiNotificationComponent,
  ],
  exports: [UploadDocumentsComponent, UploadPanelComponent, MultiUploadPanelsComponent],
  imports: [
    CommonModule,
    CoreModule,
    CommonToastModule,
    CommonDirectivesModule,
    NgbModule,
    MatButtonModule,
    MatIconModule,
    PublicSharedUtilsModule,
  ],
  providers: [{ provide: MULTI_UPLOAD_PANEL_CONFIGURATION, useValue: { stagingType: 'staging-documents' } }],
})
export class PublicSharedFeatureMultiUploadPanelModule {}
export { MultiUploadPanelsComponent } from './multi-upload-panels/multi-upload-panels.component';
