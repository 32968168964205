import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@dmv/common';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, map, take, tap } from 'rxjs/operators';
import { accessPermissionKeys, PermissionKey } from './role-permissions.const';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionsService {
  // using a BehaviorSubject to have an initial permission set
  // as a fail safe in case we can't fetch enabledPermissions.
  public readonly enabledPermissions: BehaviorSubject<PermissionKey[]> = new BehaviorSubject<PermissionKey[]>([]);
  public readonly initialLoaded$: Observable<boolean>;
  private readonly _initialLoaded = new ReplaySubject<boolean>(1);
  constructor(@Inject('env') private readonly _env: Environment, private readonly _httpClient: HttpClient) {
    this.initialLoaded$ = this._initialLoaded.asObservable();
    this._getPermissions()
      .pipe(
        take(1),
        tap(permissions => {
          this._setPermissions(permissions);
          this._initialLoaded.next(true);
          this._initialLoaded.complete();
        }),
        catchError(error => {
          this._initialLoaded.next(false);
          this._initialLoaded.complete();

          return error;
        }),
      )
      // Subscribe to the observable to populate our enabled permissions.
      .subscribe();
  }

  public hasPermission$(permissionKey: PermissionKey): Observable<boolean> {
    return this.enabledPermissions.pipe(
      debounceTime(100),
      map((keys: PermissionKey[]) => keys.includes(permissionKey)),
    );
  }

  public getAccessPermissionKeyArray(): Observable<PermissionKey[]> {
    return this.enabledPermissions.pipe(
      debounceTime(100),
      map(keys => keys.filter(key => accessPermissionKeys.includes(key))),
    );
  }

  public hasPermission(permissionKey: PermissionKey): boolean {
    return this._getRolePermissionsValue(permissionKey);
  }

  private _getPermissions(): Observable<PermissionKey[]> {
    return this._httpClient.get<PermissionKey[]>(`${this._env.apiUrl}/access-control/permissions`);
  }

  private _getRolePermissionsValue(permissionKey: PermissionKey): boolean {
    return this.enabledPermissions.value.includes(permissionKey);
  }

  private _setPermissions(enabledPermissions: PermissionKey[]): void {
    this.enabledPermissions.next(enabledPermissions);
  }
}
