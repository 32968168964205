<header>
  <div id="header">
    <div class="container">
      <nav class="navbar navbar-dark">
        <a class="navbar-brand mr-auto">
          <img
            class="img-fluid"
            src="/assets/images/nys-logo.svg"
            alt="New York State (State of opportunity) - Department of Motor Vehicles logo"
          />
        </a>
        <div class="user-control ml-auto" *ngIf="userName">
          <span id="main-skip-span" tabIndex="-1"></span>
          <a class="main-skip-link" (click)="clickMainSkip.emit()" tabindex="0" href="javascript:void(0);">Skip to main content</a>
          <span class="user-info hide-on-mobile">{{ userName }}</span>
          <a class="nav-link logoutBtn" (click)="logoutClick.emit()" href="javascript:;" tabindex="0">Logout</a>
        </div>
      </nav>
    </div>
  </div>

  <ng-container *ngIf="headData">
    <div class="transaction-title">
      <div class="container py-2">
        <ng-container *ngIf="headData.backToText">
          <div class="button-container">
            <button class="font-size-14" (click)="backClick.emit(headData.backToUrl)">
              <mat-icon>chevron_left</mat-icon>
              <span class="hide-on-mobile">{{ headData.backToText }}</span>
              <span class="show-on-mobile">{{ headData.backToTextMobile }}</span>
            </button>
          </div>
          <div class="divider"></div>
        </ng-container>
        <div class="transaction-title-content font-size-16">{{ headData.title }}</div>
      </div>
    </div>
    <ng-container *ngIf="headData.showNearestAppointmentsDateInfo && nearestAppointmentsDateInfo">
      <mat-card class="warning-container mat-elevation-z0" appearance="outlined">
        Upon approval, you will need to schedule an in person visit.
        <ng-container *ngIf="nearestAppointmentsDateInfo.date && nearestAppointmentsDateInfo.stateRunCounty">
          Appointments are currently available as soon as {{ nearestAppointmentsDateInfo.date | date : 'shortDate' }}.
        </ng-container>
      </mat-card>
    </ng-container>
  </ng-container>
</header>
