<div class="qr-code-container">
  <div class="transaction-id-label" *ngIf="applicationName">
    Your application for <span>{{ applicationName }}</span
    ><br />
    Transaction ID: <span>{{ transactionId }}</span>
  </div>
  <img #qrCodeImage *ngIf="!errorMessage" alt="QR Code" />

  <div *ngIf="errorMessage">
    <lib-spinner *ngIf="!errorMessage" [showSpinner]="true"></lib-spinner>

    <section class="inspection-info" id="error-information" *ngIf="errorMessage">
      <h4 class="text-danger">
        {{ errorMessage }}
      </h4>
    </section>
  </div>
</div>
