import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BottomNavigationModule, CommonDirectivesModule, CommonFooterModule, CommonToastModule } from '@dmv/common';
import { PublicSharedFeatureMultiUploadPanelModule } from '@dmv/public/shared/feature-multi-upload-panel';
import { FeatureFlagModule } from '@libs/feature-flag';
import { SurveyWrapperModule } from '../survey-wrapper/survey-wrapper.module';
import { TransactionSectionComponent } from './transaction-section.component';

@NgModule({
  declarations: [TransactionSectionComponent],
  exports: [TransactionSectionComponent],
  imports: [
    PublicSharedFeatureMultiUploadPanelModule,
    BottomNavigationModule,
    CommonDirectivesModule,
    CommonFooterModule,
    CommonModule,
    CommonToastModule,
    FeatureFlagModule,
    SurveyWrapperModule,
  ],
})
export class TransactionSectionModule {}
