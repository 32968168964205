import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgxSpinnerModule],
  selector: 'dmv-spinner',
  standalone: true,
  styleUrls: ['./spinner.component.scss'],
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent implements OnChanges {
  @Input() public fullScreen = false;
  @Input() public showSpinner = false;
  @Input() public loadingText = 'Loading, please wait...';
  @Input() public name = 'primary';
  @Input() public disableAnimation = false;
  @Input() public size: SpinnerSize = SpinnerSize.DEFAULT;
  @Input() public color: SpinnerColor = SpinnerColor.DMVBLUE;
  @Input() public bdColor: BackdropColor = BackdropColor.TRANSPARENT;
  @Input() public type = 'ball-clip-rotate';

  constructor(private readonly _spinner: NgxSpinnerService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.showSpinner?.currentValue) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
  }
}

enum SpinnerSize {
  DEFAULT = 'default',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

enum SpinnerColor {
  DEFAULT = '#fff', // white
  DMVBLUE = '#002d72', // dmv blue
}

enum BackdropColor {
  DEFAULT = '#eaeaea', // light gray
  TRANSPARENT = 'transparent',
}
