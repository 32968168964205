import { BaseOption } from './base-option.model';

export const genderOptions: BaseOption[] = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
  {
    label: 'X',
    value: 'X',
  },
];
