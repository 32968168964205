<div class="notification" [ngClass]="error.notificationClass">
  <ng-container *ngIf="error.headerText">
    <div class="alert-heading" [ngClass]="error.headerClass">
      <i class="fas alert-icon" *ngIf="error.iconClass" [ngClass]="error.iconClass"></i>
      {{ error.headerText }}
      <mat-icon class="close-icon" *ngIf="error.showClose" (click)="closeClicked()">close</mat-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="error.bodyText">
    <div class="alert-body" [ngClass]="error.bodyClass">{{ error.bodyText }}</div>
  </ng-container>
</div>
