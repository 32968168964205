import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationAdapter } from '../authentication.adapter';
import { AuthenticatedInfo, User } from '../authentication.type';

@Injectable({
  providedIn: 'root',
})
export class OktaAuthenticationAdapter implements AuthenticationAdapter {
  constructor(@Inject(OKTA_AUTH) private readonly _oktaAuth: OktaAuth, private readonly _oktaAuthStateService: OktaAuthStateService) {}

  public getIsAuthenticated$(): Observable<boolean> {
    return this._oktaAuthStateService.authState$.pipe(
      map(authState => {
        const isAuthenticated = !!authState.isAuthenticated;

        if (isAuthenticated) {
          sessionStorage.setItem('persist_session', 'true');
        }

        return isAuthenticated;
      }),
    );
  }

  public getUser$(): Observable<User> {
    return this._oktaAuthStateService.authState$.pipe(
      map(authState => {
        const claims = authState.idToken?.claims;
        if (claims) {
          return {
            email: claims.email || '',
            firstName: claims.given_name || claims.name?.split(' ')[0] || '',
            id: claims.at_hash || '',
            lastName: claims.family_name || claims.name?.split(' ')[1] || '',
            username: claims.preferred_username || '',
          };
        } else {
          return {
            username: '',
            id: '',
            email: '',
            firstName: '',
            lastName: '',
          };
        }
      }),
    );
  }

  public getAuthenticationInfo$(): Observable<AuthenticatedInfo> {
    return combineLatest([this.getIsAuthenticated$(), this.getUser$()]).pipe(
      tap(([isAuthenticated, _]) => {
        if (isAuthenticated) {
          sessionStorage.setItem('persist_session', 'true');
        }
      }),
      map(([isAuthenticated, user]) => {
        return {
          isAuthenticated,
          user,
        };
      }),
    );
  }

  public async logout(): Promise<boolean | void> {
    this._oktaAuth.tokenManager.clear();
    const location = window.location;
    const targetedPostLogoutRedirectUri = `${this._oktaAuth.options.postLogoutRedirectUri}?TARGET=${location?.href ?? ''}`;

    return this._oktaAuth
      .signOut({
        postLogoutRedirectUri: targetedPostLogoutRedirectUri,
      })
      .catch(_err => {
        this.logout();
      });
  }

  public async clearSession(): Promise<void> {
    this._oktaAuth.tokenManager.clear();
    await this._oktaAuth.revokeAccessToken();
    await this._oktaAuth.closeSession();
  }

  public async login(redirectUrl?: string): Promise<void> {
    await this._oktaAuth.signInWithRedirect({
      originalUri: this._oktaAuth.getOriginalUri(),
    });
  }

  public getAccessToken(): string {
    return this._oktaAuth.getAccessToken();
  }

  public getOriginalUri(): string {
    return this._oktaAuth.getOriginalUri();
  }
}
