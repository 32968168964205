import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionDisabledDirective } from './directives/permission-disabled.directive';
import { PermissionPermittedDirective } from './directives/permission-permitted.directive';

@NgModule({
  declarations: [PermissionDisabledDirective, PermissionPermittedDirective],
  exports: [PermissionDisabledDirective, PermissionPermittedDirective],
  imports: [CommonModule],
})
export class RolePermissionsModule {}
