export const ErrorMessage = {
  BOAT_BODY_TYPE_REQUIRED: getEmptyMsg('Boat Body Type'),
  BOAT_TYPE_REQUIRED: getEmptyMsg('Type of Boat'),
  BUSINESS_NAME_REQUIRED: getEmptyMsg('Business Name '),
  CITY_REQUIRED: getEmptyMsg('Residence City'),
  CLUB_NAME_REQUIRED: getEmptyMsg('Club Name '),
  CONFIRM_EMAIL_REQUIRED: getEmptyMsg('Confirm Email'),
  CONTACT_PHONE_NUMBER_REQUIRED: getEmptyMsg('10-Digit Contact Phone Number'),
  COUNTY_REQUIRED: 'Please select New York County of Residence',
  CURRENT_FIRST_NAME_CHARACTER_ONLY: getCharacterOnlyMsg('Current First Name'),
  CURRENT_FIRST_NAME_REQUIRED: getEmptyMsg('Current First Name'),
  CURRENT_LAST_NAME_CHARACTER_ONLY: getCharacterOnlyMsg('Current Last Name'),
  CURRENT_LAST_NAME_REQUIRED: getEmptyMsg('Current Last Name'),
  CURRENT_MIDDLE_NAME_CHARACTER_ONLY: getCharacterOnlyMsg('Current Middle Name'),
  EMAIL_MATCH: 'Email & Confirm email should match',
  EMAIL_REQUIRED: getEmptyMsg('Email'),
  ENTER_BOAT_OTHER_BODY_TYPE: 'Please enter the Boat Type in the Boat Type (Other) field',
  ENTER_MAILING_ZIP: enterFieldMsg('Mailing Zip Code'),
  ENTER_OTHER_BODY_TYPE: 'Please enter the Vehicle Body Type in the Body Type (Other) field',
  ENTER_VALID_CONTACT_PHONE_NUMBER: enterFieldMsg('10-Digit Contact Phone Number'),
  ENTER_VALID_EMAIL: enterFieldMsg('Email Address'),
  ENTER_VALID_NUMBER: enterFieldMsg('Number'),
  ENTER_VALID_VEHICLE_BODY_TYPE: selectFieldMsg('Vehicle Body Type'),
  ENTER_VALID_VEHICLE_ENGINEPOWER: enterFieldMsg('Engine Power'),
  ENTER_VALID_VEHICLE_YEAR: 'Please enter a valid Vehicle Year (e.g. 2021)',
  ENTER_VALID_VIN: enterFieldMsg('Vehicle Identification Number'),
  ENTER_VEHICLE_PLATE: enterFieldMsg('Vehicle plate number'),
  ENTER_ZIP: enterFieldMsg('Zip Code'),
  FIRST_NAME_CHARACTER_ONLY: getCharacterOnlyMsg('First Name'),
  FIRST_NAME_REQUIRED: getEmptyMsg('First Name'),
  FUEL_TYPE_REQUIRED: getEmptyMsg('Fuel Type'),
  HULL_MATERIAL_REQUIRED: getEmptyMsg('Hull Material'),
  LAST_NAME_CHARACTER_ONLY: getCharacterOnlyMsg('Last Name'),
  LAST_NAME_REQUIRED: getEmptyMsg('Last Name'),
  LENGTH_FEET_REQUIRED: getEmptyMsg('Length'),
  LENGTH_INCHES_REQUIRED: getEmptyMsg('Inches'),
  LICENSE_ID_PATTERN: 'ID must be 9 digits long.',
  LICENSE_ID_REQUIRED: getEmptyMsg('ID'),
  LICENSE_ID_VALID: 'Please enter a valid 9 digit ID',
  MAILING_CITY_REQUIRED: getEmptyMsg('Mailing City'),
  MAILING_COUNTY_REQUIRED: getEmptyMsg('Mailing County'),
  MAILING_STREET_REQUIRED: getEmptyMsg('Mailing Street Address'),
  MAILING_ZIP_REQUIRED: getEmptyMsg('Mailing Zip'),
  MEMBER_NUMBER_REQUIRED: getEmptyMsg('Club Member Number '),
  MIDDLE_NAME_CHARACTER_ONLY: getCharacterOnlyMsg('Middle Name'),
  MINIMUM_AGE: `To submit, the applicant must be three days or less from their 16th birthday`,
  OWNER_NAME_PATTERN: getCharacterOnlyMsg('Co-owner Full Name'),
  OWNER_NAME_REQUIRED: getEmptyMsg('Co-owner Full Name'),
  PLATE_REGISTRATION_REQUIRED: 'Plate Registration is required',
  PROPULSION_REQUIRED: getEmptyMsg('Propulsion'),
  REGISTRATION_NUMBER_REQUIRED: getEmptyMsg('Registration Number'),
  SELECT_BIRTH_DAY: selectFieldMsg('day of birth'),
  SELECT_BIRTH_MONTH: selectFieldMsg('month of birth'),
  SELECT_BIRTH_YEAR: selectFieldMsg('year of birth'),
  SELECT_EYE_COLOR: selectFieldMsg('eye color'),
  SELECT_GENDER: selectFieldMsg('gender'),
  SELECT_HEIGHT: selectFieldMsg('height'),
  SELECT_LANGUAGE: selectFieldMsg('language'),
  SELECT_MAILING_STATE: selectFieldMsg('Mailing State'),
  SELECT_STATE: selectFieldMsg('State'),
  SNOWMOBILE_CLUB_MEMBER_PATTERN: 'Club Member Number must be 6 digits long.',
  STREET_REQUIRED: getEmptyMsg('Residence Street Address'),
  VEHICLE_AXLES_PATTERN: enterFieldMsg('Vehicle Axles'),
  VEHICLE_AXLES_REQUIRED: getEmptyMsg('Vehicle Axles'),
  VEHICLE_AXLE_DISTANCE_PATTERN: enterFieldMsg('Vehicle Axles Distance'),
  VEHICLE_AXLE_DISTANCE_REQUIRED: getEmptyMsg('Vehicle Axles Distance'),
  VEHICLE_BODY_REQUIRED: getEmptyMsg('Vehicle Body Type'),
  VEHICLE_COLOR_REQUIRED: selectFieldMsg('Vehicle Color'),
  VEHICLE_CYLINDERS_PATTERN: enterFieldMsg('Vehicle Cylinders'),
  VEHICLE_CYLINDERS_REQUIRED: getEmptyMsg('Vehicle Cylinders'),
  VEHICLE_ENGINEPOWER_REQUIRED: getEmptyMsg('Engine Power'),
  VEHICLE_FUEL_TYPE_REQUIRED: selectFieldMsg('Vehicle Fuel Type'),
  VEHICLE_GROSS_WEIGHT_LESS_THAN_UNLADEN: 'Vehicle Maximum Gross Weight must be greater than Unladen Weight',
  VEHICLE_GROSS_WEIGHT_PATTERN: enterFieldMsg('Vehicle Maximum Gross Weight'),
  VEHICLE_GROSS_WEIGHT_REQUIRED: getEmptyMsg('Vehicle Maximum Gross Weight'),
  VEHICLE_MAKE_REQUIRED: getEmptyMsg('Vehicle Make'),
  VEHICLE_MODIFICATIONS_DESCRIPTION_REQUIRED: getEmptyMsg('Vehicle Modification Description'),
  VEHICLE_MODIFICATIONS_REQUIRED: getEmptyMsg('Vehicle Modifications'),
  VEHICLE_ODOMETER_PATTERN: enterFieldMsg('Vehicle Odometer Reading'),
  VEHICLE_ODOMETER_REQUIRED: getEmptyMsg('Vehicle Odometer Reading'),
  VEHICLE_PLATE_REQUIRED: 'Vehicle Plate Number field is empty, it is a required field and must be filled in',
  VEHICLE_PRIMARYCOUNTY_REQUIRED: selectFieldMsg('NYS County for Primary use'),
  VEHICLE_SEATING_CAPACITY_PATTERN: enterFieldMsg('Vehicle Seating Capacity'),
  VEHICLE_SEATING_CAPACITY_REQUIRED: getEmptyMsg('Vehicle Seating Capacity'),
  VEHICLE_UNLADEN_WEIGHT_GREATER_THAN_ZERO: 'Vehicle Unladen Weight must be greater than 0',
  VEHICLE_UNLADEN_WEIGHT_PATTERN: enterFieldMsg('Vehicle Unladen Weight'),
  VEHICLE_UNLADEN_WEIGHT_REQUIRED: getEmptyMsg('Vehicle Unladen Weight'),
  VEHICLE_USE_REQUIRED: selectFieldMsg('Vehicle Use'),
  VEHICLE_YEAR_REQUIRED: getEmptyMsg('Vehicle Year'),
  VIN_NOT_FOUND: 'VIN Number not found',
  VIN_REQUIRED: getEmptyMsg('Vehicle, Snowmobile, or Boat Identification Number'),
  ZIP_REQUIRED: getEmptyMsg('Residence Zip'),
};

export function getEmptyMsg(fieldName: string) {
  return `The ${fieldName} field is empty, it is a required field and must be filled in`;
}

export function getCharacterOnlyMsg(fieldName: string) {
  return `Only characters allowed in ${fieldName}`;
}

export function enterFieldMsg(fieldName: string) {
  return `Please enter a valid ${fieldName}`;
}

export function selectFieldMsg(fieldName: string) {
  return `Please select your ${fieldName}`;
}
