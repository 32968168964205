import { Inject, Injectable } from '@angular/core';
import { Environment } from '@dmv/common';
import { TransactionUri } from '@dmv/public/shared/http';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable()
export class EnvironmentRoutingService {
  constructor(@Inject('env') private readonly _env: Environment, @Inject(OKTA_AUTH) private readonly _oktaAuth: OktaAuth) {}

  public getRedirectUrl() {
    if (this._oktaAuth.getOriginalUri() && this._oktaAuth.getOriginalUri().includes(TransactionUri.EXCHANGE_LICENSE)) {
      return this._env.registerRedirectUrls.licenseReciprocity;
    } else if (this._oktaAuth.getOriginalUri() && this._oktaAuth.getOriginalUri().includes(TransactionUri.ORIGINAL_NON_DRIVER_ID)) {
      return this._env.registerRedirectUrls.originalNdid;
    } else if (this._oktaAuth.getOriginalUri() && this._oktaAuth.getOriginalUri().includes(TransactionUri.ORIGINAL_PERMIT)) {
      return this._env.registerRedirectUrls.originalPermit;
    } else if (this._oktaAuth.getOriginalUri() && this._oktaAuth.getOriginalUri().includes(TransactionUri.ORIGINAL_REGISTRATION)) {
      return this._env.registerRedirectUrls.originalRegistration;
    } else if (this._oktaAuth.getOriginalUri() && this._oktaAuth.getOriginalUri().includes(TransactionUri.REAL_ID_EDL_UPGRADE)) {
      return this._env.registerRedirectUrls.realId;
    } else {
      // Handle bookmarks to the login page or the possibility that someone has directly typed the login route
      return this._env.registerRedirectUrls.originalPermit;
    }
  }
}
