import { Injectable } from '@angular/core';
import { orderBy } from 'lodash';
import { boatDocuments, nonSpecificDocuments, trailerDocuments, TransactionDocument } from '../models';

/**
 * @deprecated Do not use this as we should not have this responsibility on the front end
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentSortingService {
  // For MVR (MVR uses TransactionDocument array)
  /**
   * @deprecated OR v1 used this. It got copied to other services
   * @param documents
   */
  public sortTransactionDocuments(documents: TransactionDocument[]): TransactionDocument[] {
    return orderBy(
      documents,
      [
        doc => nonSpecificDocuments.includes(doc.documentType.id),
        doc => boatDocuments.includes(doc.documentType.id),
        doc => trailerDocuments.includes(doc.documentType.id),
        doc => doc.documentType.popularity,
      ],
      ['desc', 'desc', 'desc', 'desc'],
    );
  }
}
