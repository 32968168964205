import { OrderedSection, Section, SectionInfo, SurveySectionType } from '@dmv/public/shared/http';

export const transformSections = (sections: Section[]): OrderedSection[] => {
  const transformed: OrderedSection[] = [];
  const addedParents: Record<string, boolean> = {}; // to track which parentSections are already grouped
  const activeSections: Section[] = sections.filter(section => section.active);
  activeSections.forEach(section => {
    if (!section.parentSection) {
      transformed.push({ section, type: 'standalone' });
    } else {
      if (!addedParents[section.parentSection]) {
        addedParents[section.parentSection] = true;
        const groupedSections = activeSections.filter(s => s.parentSection === section.parentSection);
        transformed.push({
          complete: groupedSections.every(sec => sec.status === 'complete'),
          parentSection: section.parentSection,
          sections: groupedSections,
          type: 'grouped',
        });
      }
    }
  });

  return transformed;
};

export const buildSteps = (orderedSections: OrderedSection[]): SectionInfo[] => {
  return orderedSections
    .map((orderedSection: OrderedSection) => {
      switch (orderedSection.type) {
        case 'standalone':
          return [sectionToSectionInfo(orderedSection.section)];
        case 'grouped':
          return [
            {
              active: true,
              description: orderedSection.parentSection,
              parentSection: orderedSection.parentSection,
              sectionId: orderedSection.sections[0].id as SurveySectionType,
              status: orderedSection.sections.every(s => s.status === 'complete') ? 'complete' : 'not_started',
            } as SectionInfo,
          ];
      }
    })
    .reduce((acc, curr) => acc.concat(curr), []);
};

export const sectionToSectionInfo = (section: Section): SectionInfo => {
  return {
    active: true,
    description: section.description,
    parentSection: undefined,
    sectionId: section.id as SurveySectionType,
    status: section.status,
  } as SectionInfo;
};
