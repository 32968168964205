export const CLUB_MEMBER_NUMBER = `^[0-9]{6}$`;
export const DIN = `(^\\d{2}-[a-zA-Z]{1}-\\d{4}$)`;
export const EMAIL = `^[a-zA-Z0-9!#$%&'*+-/=?^_\`{|}~]+@[a-zA-Z0-9.-]+\\.((?!-)[a-zA-Z-]{2,63})$`;
export const ID_NUMBER = '^[a-zA-Z0-9]{5,17}$';
export const INT_GREATER_THAN_ZERO = `^[1-9][0-9]*$`;
export const INT_ZERO_OR_GREATER = `^[0-9][0-9]*$`;
export const LICENSE_ID = `^[0-9]{9}$`;
export const LEGAL_NAME = `^[a-zA-Z ’'.,-]*$`;
export const NAME = `^[a-zA-Z '-]*$`;
export const ODOMETER = `^[0-9]$`;
export const PHONE = '^[0-9]{10}$';
export const YEAR = `^[0-9]{4}$`;
export const ZIP_CODE = '^[0-9]{5}$';
export const ZIP_FIVE_OR_TEN_CHARACTERS = '(^\\d{5}$)|(^\\d{5}-\\d{4}$)';
