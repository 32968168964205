import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@dmv/shared/utils-authentication';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private readonly _authenticationService: AuthenticationService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = this._authenticationService.getAccessToken();
    const reqUrlbegin = request.url.substring(0, 21);
    const qmaticReq = reqUrlbegin === 'https://nysdmvstaging';
    let { headers } = request;

    if (jwt && !qmaticReq) {
      headers = headers.set('Authorization', `Bearer ${jwt}`);
    }

    // Temporary hack, remove when the backend has the logic to whitelist IP addresses
    const xForwardedFor = localStorage.getItem('x-forwarded-for');
    if (xForwardedFor) {
      headers = headers.set('X-Forwarded-For', xForwardedFor);
    }

    request = request.clone({ headers });

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        // This section is to convert an error that has been converted to a Blob back to JSON. This happens when you make an HTTP request
        // that explicitly requests a blob.  If an error occurs, the error object remains a blob.  We need to make it JSON again.
        if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
          // https://github.com/angular/angular/issues/19888
          // When request of type Blob, the error is also in Blob instead of object of the json data
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: Event) => {
              try {
                const errorMessage = JSON.parse((event.target as any).result);
                reject(
                  new HttpErrorResponse({
                    error: errorMessage,
                    headers: err.headers,
                    status: err.status,
                    statusText: err.statusText,
                    url: err.url,
                  }),
                );
              } catch (e) {
                reject(err);
              }
            };
            reader.onerror = _e => {
              reject(err);
            };
            reader.readAsText(err.error);
          });
        }

        return throwError(err);
      }),
    );
  }
}
