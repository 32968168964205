import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { DocAiChecklist, TransactionType } from '../../models';
import { DocumentService } from '../../services';
import { BaseComponent } from '../base/base.component';
import { PdfViewerWrapperComponent } from '../pdf-viewer/pdf-viewer-wrapper.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'lib-pdf-modal',
  styleUrls: ['./pdf-modal.component.scss'],
  templateUrl: './pdf-modal.component.html',
})
export class PdfModalComponent extends BaseComponent implements OnInit {
  @Input() public id: string | number;
  @Input() public title: string;
  @Input() public errorString: string;
  @Input() public error?: boolean;
  @Input() public disabled?: boolean;
  @Input() public documentAiChecklist: DocAiChecklist[];
  @Input() public viewAll = false;
  @Input() public transactionType: TransactionType;

  public viewLabel = 'View';
  public pdfModal: PdfViewerWrapperComponent;

  constructor(private readonly _documentService: DocumentService, private readonly _modalService: NgbModal) {
    super();
  }

  public ngOnInit(): void {
    if (this.viewAll) {
      this.viewLabel = 'VIEW / PRINT ALL';
    }
  }

  public open($event: MouseEvent) {
    $event?.stopPropagation();
    this.pdfModal = this._modalService.open(PdfViewerWrapperComponent, {
      scrollable: true,
      size: 'xl',
      windowClass: 'full-size',
    }).componentInstance;
    this.pdfModal.title = this.title;
    this.pdfModal.documentAiChecklist = this.documentAiChecklist;

    this.pdfModal.close = this.close;
    const blobObserv = this.viewAll
      ? this._documentService.getTransactionDocumentsBlob(this.id, this.transactionType)
      : this._documentService.getImageBlob(this.id, this.transactionType);
    blobObserv.pipe(takeUntil(this.destroy$)).subscribe((response: Blob) => {
      if (this.error) {
        this.pdfModal.errorTitle = this.errorString;
        this.pdfModal.error = true;
        this.pdfModal.loading = false;
      } else {
        this.pdfModal.setPdfSrc(response);
      }
    });
  }

  public close = () => {
    this._modalService.dismissAll();
  };
}
