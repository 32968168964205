import { TransactionDocumentType } from './transaction-document-type.model';

export interface DashboardAppointmentInformationModel {
  appointmentStatus: AppointmentStatus;
  appointmentTime: string;
  branchAddress: string;
  branchCity: string;
  branchName: string;
  calendarLink: string;
  hasAppointment: boolean;
  id: number;
  mapsLink: string;
  qrCodeLink?: string;
  rejectionReason: string;
  status: string;
  submittedOn: string;
  txnDocTypes?: TransactionDocumentType[];
}

export enum AppointmentStatus {
  SCHEDULED = 'Scheduled',
  RESCHEDULED = 'Rescheduled',
}

export const dashboardAppointmentInformationModel: DashboardAppointmentInformationModel = {
  appointmentStatus: AppointmentStatus.SCHEDULED,
  appointmentTime: '',
  branchAddress: '',
  branchCity: '',
  branchName: '',
  calendarLink: '',
  hasAppointment: false,
  id: 0,
  mapsLink: '',
  qrCodeLink: '',
  rejectionReason: '',
  status: '',
  submittedOn: '',
  txnDocTypes: [],
};
