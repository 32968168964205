import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PermissionKey } from '../role-permissions.const';
import { RolePermissionsService } from '../role-permissions.service';

/**
 * This directive provides an easy way to enable/disable a DOM element based on the user's role.
 * Use it like a *ngIf directive
 * ```html
 *    <a *libPermissionPermitted="permission-key">Permission required link</a>
 * ````
 * To use this directive, add it to the imports array of the module you wish to use it in.
 */

@UntilDestroy()
@Directive({
  selector: '[libPermissionPermitted]',
})
export class PermissionPermittedDirective implements OnInit {
  private readonly _permissionKey$ = new ReplaySubject<PermissionKey>(1);
  private _viewCreated = false;

  constructor(
    private readonly _rolePermissionsService: RolePermissionsService,
    private readonly _templateRef: TemplateRef<unknown>,
    private readonly _viewContainer: ViewContainerRef,
  ) {}

  @Input('libPermissionPermitted')
  public set permissionKey(key: PermissionKey) {
    this._permissionKey$.next(key);
  }

  public ngOnInit() {
    this._permissionKey$
      .pipe(
        distinctUntilChanged(),
        switchMap(key => this._rolePermissionsService.hasPermission$(key)),
        untilDestroyed(this),
      )
      .subscribe(hasPermission => {
        this._updateView(hasPermission);
      });
  }

  private _updateView(hasPermission: boolean) {
    if (hasPermission && !this._viewCreated) {
      this._viewCreated = true;
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else if (!hasPermission) {
      this._viewCreated = false;
      this._viewContainer.clear();
    }
  }
}
