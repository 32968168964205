import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonToastModule } from '@dmv/common';
import { DisplayErrorComponent } from './display-error.component';

@NgModule({
  declarations: [DisplayErrorComponent],
  exports: [DisplayErrorComponent],
  imports: [CommonModule, CommonToastModule],
})
export class DisplayErrorModule {}
