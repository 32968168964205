import {
  AnswerPrompt,
  DocumentsReview,
  DocumentTypes,
  Section,
  SectionContext,
  StepType,
  SurveyEvent,
  SurveyFunctionContext,
  SurveySectionState,
  SurveySectionType,
  UploadDocumentDetail,
  UploadItem,
  UsedDocumentTypes,
} from '@dmv/public/shared/http';
import { Action, createAction, props } from '@ngrx/store';

export const updateAnswers = createAction(
  '[SurveySection] Update Answers',
  props<{ answerId: string; answers: string[]; questionId: string; questionPrompt: string }>(),
);

export const updateSectionReview = createAction('[SurveySection] Update Section Review', props<{ sectionReview: DocumentsReview }>());

export const buildSurveySectionUploadItems = createAction('[SurveySection] Build Survey Section Upload Items');

/**
 * Form steps that dispatch this action should first validate and mark as submitted
 * before dispatching this action.
 */
export const continueSurveySection = createAction(
  '[SurveySection] Continue Survey Section',
  props<{
    func?: (
      id: string,
      activeStep?: StepType,
      answers?: Record<string, { answerId: string; answers: string[]; questionPrompt: string }>,
      currentQuestionId?: string,
    ) => SectionContext;
    submitAction?: Action;
    functionContext?: SurveyFunctionContext;
  }>(),
);

export const determineSectionContext = createAction(
  '[SurveySection] Determine Section Context',
  props<{
    // technically all of these are guaranteed to be passed to our question services, but they might not use them
    func?: (
      id: string,
      activeStep?: StepType,
      answers?: Record<string, { answerId: string; answers: string[]; questionPrompt: string }>,
      questionId?: string,
      sectionId?: SurveySectionType,
      functionContext?: SurveyFunctionContext,
    ) => SectionContext;
    functionContext: SurveyFunctionContext;
  }>(),
);

/**
 * Used for survey wrapper sections or a question with a static questions array
 */
export const buildSectionReviewItems = createAction('[SurveySection] Build Section Review Items');
/**
 * Used for dynamic survey sections
 */
export const buildSectionReviewItemsFromAnswers = createAction('[SurveySection] Build Section Review Items From Answers');

export const buildUploadItemsSuccess = createAction(
  '[SurveySection] Build Upload Documents Success',
  props<{ uploadItems: UploadItem[] }>(),
);

export const displayErrors = createAction('[SurveySection] Display Errors', props<{ display: boolean; errors?: string[] }>());

export const initializeDocumentTypes = createAction('[SurveySection] Initialize Document Types');

export const initializeUsedDocuments = createAction('[SurveySection] Initialize Used Documents');

export const initializeSectionSuccess = createAction(
  '[SurveySection] Initialize Survey Section Success',
  props<{
    activeStep?: StepType;
    documentTypes: DocumentTypes[];
    newState: SurveySectionState;
    section: Section;
    usedDocumentTypes: UsedDocumentTypes[];
  }>(),
);

export const markSectionReviewed = createAction('[SurveySection] Mark Section Reviewed', props<{ isReviewed: boolean }>());

export const previousQuestionIndex = createAction('[SurveySection] Previous Question Index');
export const resetSections = createAction('[SurveySection] Reset Sections');
export const restartSectionConfirm = createAction('[SurveySection] Restart Section Confirm', props<{ sectionId: SurveySectionType }>());
export const sectionMarkDirty = createAction('[SurveySection] Section Mark Dirty', props<{ isDirty: boolean }>());
export const setActiveStep = createAction('[SurveySection] Section Active Step', props<{ activeStep: StepType }>());
export const uploadDetailReuploaded = createAction('[SurveySection] Upload Detail Reuploaded', props<{ detail: UploadDocumentDetail }>());

export const setSurveySectionState = createAction(
  '[SurveySection] Set Survey Section State',
  props<{ surveyState: SurveySectionState; section: Section }>(),
);
export const submitTransactionSuccess = createAction('[SurveySection] Submit Transaction Success');
// We may create a document state to hold the lists
export const updateSurveyQuestionAnswers = createAction(
  '[SurveySection] Update Survey Question Answers',
  props<{ questionIndex: number }>(),
);
export const updateSurveyQuestionIndex = createAction(
  '[SurveySection] Update Survey Question Index',
  props<{ questionIndex: number; clearRequiredDocumentIds?: boolean }>(),
);
export const updateDocumentTypes = createAction('[SurveySection] Update Document Types', props<{ documentTypes: DocumentTypes[] }>());
export const updateSurveySections = createAction('[SurveySection] Update Survey Sections', props<{ section: Section }>());

export const updateSurveyStateRequiredDocumentIds = createAction(
  '[SurveySection] Update Survey State Required Document Ids',
  props<{ ids?: number[] }>(),
);
export const checkAutoProceed = createAction('[SurveySection] Check Auto Proceed', props<{ isReset: boolean }>());

export const checkDocChange = createAction('[SurveySection] Check Doc Type Change Eligibility', props<{ detail: UploadDocumentDetail }>());
export const changeDocType = createAction('[SurveySection] Change Doc Type', props<{ item: UploadItem }>());

export const setDocChange = createAction(
  '[SurveySection] Set Doc Type Change Eligibility',
  props<{ docTypeChangeEligible: boolean; detail: UploadDocumentDetail }>(),
);

/**
 * Use this action for updating sections that cannot use the survey-wrapper
 */
export const updateSurveyStateQuestion = createAction(
  '[SurveySection] Update Survey State Question',
  props<{ surveyEvent: SurveyEvent; answerPrompts?: AnswerPrompt[] }>(),
);

/**
 * Use this action for updating sections that cannot use the survey-wrapper
 */
export const updateSurveyStateQuestionAnswer = createAction(
  '[SurveySection] Update Survey State Question Answer',
  props<{ surveyEvent: SurveyEvent }>(),
);

/**
 * Use this action for updating sections that must use the survey-wrapper
 */
export const updateSurveyWrapperState = createAction('[SurveySection] Update Survey Wrapper State', props<{ surveyEvent: SurveyEvent }>());

export const updateUploadItem = createAction(
  '[SurveySection] Update Upload Item',
  props<{ uploadItem: UploadItem; uploadsStatus?: 'completed' | 'fileUploaded' }>(),
);

export const replaceUploadItem = createAction(
  '[SurveySection] Replace Upload Item',
  props<{ newUploadItem: UploadItem; oldUploadItem: UploadItem }>(),
);

export const updateDocChangeRequiredDocIds = createAction(
  '[SurveySection] Update Doc Type Change Required Doc Ids',
  props<{ newUploadItem: UploadItem; oldUploadItem: UploadItem }>(),
);

export const updateDocChangeQuestionAnswers = createAction(
  '[SurveySection] Update Doc Type Change Question Answers',
  props<{ newUploadItem: UploadItem; oldUploadItem: UploadItem }>(),
);
