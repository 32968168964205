import { Injectable } from '@angular/core';
import { EventsAdapter } from './events.adapter';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private readonly eventsAdapter: EventsAdapter) {}

  public initialize(): Observable<boolean> {
    return this.eventsAdapter.initialize();
  }
  public pushEvent(eventsTag: EventsTag): Promise<void> {
    return this.eventsAdapter.pushEvent(eventsTag);
  }
}

export interface EventsTag {
  event: string;
  pageName: string;
  time: Date;
  userEmail: string;
  userName: string;
  [x: string]: any;
}
