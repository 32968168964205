import { InjectionToken } from '@angular/core';

export type FeatureFlagValueType = boolean | string | number;

export type FeatureKeys =
  | 'test-feature'
  | 'back-to-dashboard-header'
  | 'granicus-tms'
  | 'mvr-akts-post-fraud-stats'
  | 'mvr-ms7-docai-tools'
  | 'mvr-original-registration-v2'
  | 'mvr-preformatted-messages'
  | 'op-sms-support'
  | 'public-applus-scheduling'
  | 'public-doc-ai-classification'
  | 'public-doc-ai-extraction'
  | 'public-doc-ai-quality'
  | 'public-license-reciprocity-enabled'
  | 'original-ndid'
  | 'original-registration'
  | 'public-license-reciprocity-scheduling'
  | 'public-original-permit-v2'
  | 'public-pdf-password-protected-check'
  | 'public-scheduling-use-barcodes'
  | 'public-scheduling-disabled'
  | 'public-prefilled-mv44'
  | 'public-doc-ai-change-doc'
  | 'public-socure-device-unmask'
  | 'public-socure-device-unmask-enforced'
  | 'public-socure-environment';

export const DefaultFeatureFlags: Record<FeatureKeys, FeatureFlagValueType> = {
  'back-to-dashboard-header': false,
  'granicus-tms': false,
  'mvr-akts-post-fraud-stats': false,
  'mvr-ms7-docai-tools': false,
  'mvr-original-registration-v2': false,
  'mvr-preformatted-messages': false,
  'op-sms-support': false,
  'original-ndid': false,
  'original-registration': false,
  'public-applus-scheduling': false,
  'public-doc-ai-change-doc': false,
  'public-doc-ai-classification': false,
  'public-doc-ai-extraction': false,
  'public-doc-ai-quality': false,
  'public-license-reciprocity-enabled': false,
  'public-license-reciprocity-scheduling': false,
  'public-original-permit-v2': false,
  'public-pdf-password-protected-check': false,
  'public-prefilled-mv44': false,
  'public-scheduling-disabled': false,
  'public-scheduling-use-barcodes': false,
  'public-socure-device-unmask': false,
  'public-socure-device-unmask-enforced': false,
  'public-socure-environment': 'sandbox',
  'test-feature': false, // Do not delete, this is used in the tests
};

export const DEFAULT_FEATURE_FLAGS = new InjectionToken<Record<FeatureKeys, boolean>>('DEFAULT_FEATURE_FLAGS');

export const LOGIN_REDIRECT_CONFIGURATION = new InjectionToken<string>('LOGIN_REDIRECT_CONFIGURATION');
