import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from 'ngrx-forms';
import { AbstractDataService } from '../services/form-validation-error/form-validation-error.service';

@Pipe({ name: 'dmvValidationErrorMessage' })
export class ValidationErrorMessagePipe implements PipeTransform {
  constructor(@Inject('dataService') private readonly _validationErrorAbstract: AbstractDataService) {}
  public transform(errors: ValidationErrors, key: string): string | null {
    return `Error: ${this._validationErrorAbstract.getValidationError(key, errors)}`;
  }
}
