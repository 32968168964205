import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DecisionService } from './decision-service';
import {
  DEVICE_BLOCKED_RESUME_KEY,
  ORIGINAL_PERMIT_CHECKLIST_URL,
  ORIGINAL_PERMIT_CONFIRMATION_URL,
  PREVIOUS_BLOCKED_RESUME_KEY,
} from './models/decision';

export function deviceRiskGuard(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
  const decisionService = inject(DecisionService);
  const router = inject(Router);

  const previousTarget = sessionStorage.getItem(DEVICE_BLOCKED_RESUME_KEY);
  sessionStorage.setItem(PREVIOUS_BLOCKED_RESUME_KEY, previousTarget);

  let targetUrl = getConfiguredUrl(route.firstChild);
  // A hack to work around the fact that there is no `route.firstChild` on Permit application submit
  // To be fixed in https://nuvalence-dmv.atlassian.net/browse/DMV-6292
  if (!targetUrl && previousTarget === ORIGINAL_PERMIT_CHECKLIST_URL) {
    targetUrl = ORIGINAL_PERMIT_CONFIRMATION_URL;
  }
  sessionStorage.setItem(DEVICE_BLOCKED_RESUME_KEY, targetUrl);

  return decisionService.getDeviceRiskOutcome$(targetUrl).pipe(
    switchMap(decision => {
      if (decision.outcome !== 'RESUBMIT') {
        return of(true);
      } else {
        return of(router.parseUrl('blocked'));
      }
    }),
  );
}

function getConfiguredUrl(route: ActivatedRouteSnapshot): string {
  if (route) {
    return `/${route.pathFromRoot
      .filter(activatedRoute => activatedRoute.routeConfig)
      .map(activatedRoute => activatedRoute.routeConfig?.path)
      .join('/')
      .replace('//', '/')}`;
  } else {
    return '';
  }
}
