export enum VehicleUse {
  COMBO_HEARSE = 'Combination Hearse / Invalid Coach',
  COMMERCIAL_TOW = 'Commercial Tow Truck w/ Gross Weight rating >= 8600 pounds',
  HEARSE = 'Hearse',
  OTHER = 'Other',
  PRIVATE_RENTAL = 'Rented without a driver (private rental)',
  TRANSPORT = 'Used to transport passengers (For Hire)',
}

export const vehicleUseReadableMap: Record<VehicleUse, string> = {
  'Combination Hearse / Invalid Coach': 'COMBO_HEARSE_INVALID_COACH',
  'Commercial Tow Truck w/ Gross Weight rating >= 8600 pounds': 'COMMERCIAL_TOW',
  'Hearse': 'HEARSE',
  'Other': 'OTHER',
  'Rented without a driver (private rental)': 'PRIVATE_RENTAL',
  'Used to transport passengers (For Hire)': 'TRANSPORT_PASSENGERS_FOR_HIRE',
};
