import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticatedInfo, User } from '..';
import { AuthenticationAdapter } from './authentication.adapter';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  LOGIN_URL = 'resident/login';

  constructor(private readonly authenticationAdapter: AuthenticationAdapter) {}

  public async login(redirectUrl?: string): Promise<void> {
    return this.authenticationAdapter.login(redirectUrl);
  }

  public async logout(): Promise<boolean | void> {
    return this.authenticationAdapter.logout();
  }

  public async clearSession(): Promise<void> {
    return this.authenticationAdapter.clearSession();
  }

  public getIsAuthenticated$(): Observable<boolean> {
    return this.authenticationAdapter.getIsAuthenticated$();
  }

  public getUser$(): Observable<User> {
    return this.authenticationAdapter.getUser$();
  }

  public getAuthenticationInfo$(): Observable<AuthenticatedInfo> {
    return this.authenticationAdapter.getAuthenticationInfo$();
  }

  public getAccessToken(): string {
    return this.authenticationAdapter.getAccessToken();
  }

  public getOriginalUri(): string {
    return this.authenticationAdapter.getOriginalUri();
  }
}
