import { Injectable } from '@angular/core';
import { SurveySectionType, TransactionUri } from '@dmv/public/shared/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NavigationActions } from '../actions';
import { NavigationState } from '../reducers/navigation.reducer';
import { selectTransactionUri } from '../selectors/navigation.selectors';

@Injectable({
  providedIn: 'root',
})
export class NavigationFacade {
  public selectTransactionUri$: Observable<TransactionUri | string> = this._store.select(selectTransactionUri);
  constructor(private readonly _store: Store<NavigationState>) {}

  public navigateToPage(page: string): void {
    return this._store.dispatch(NavigationActions.navigateToPage({ urlFragments: [page] }));
  }

  public navigateToNextIncompleteSection(sectionId: SurveySectionType): void {
    return this._store.dispatch(NavigationActions.navigateToNextIncompleteSection({ sectionId }));
  }
}
