import { ContactPreferenceType, DropdownOption } from '@dmv/public/shared/http';
import { NgrxValueConverters } from 'ngrx-forms';

export const OUT_OF_STATE = 'Out Of State';
export const NY_STATE = 'NY';
export const dateConverter = NgrxValueConverters.dateToISOString;
export const onlyDigitsRegexString = /[^\d]/g;
export const dobDefaultDate = new Date(new Date().setFullYear(new Date().getFullYear() - 40));
export const expDefaultDate = new Date();

export const suffixOptions: DropdownOption[] = [
  {
    id: 'JR',
    name: 'JR',
  },
  {
    id: 'SR',
    name: 'SR',
  },
  {
    id: 'I',
    name: 'I',
  },
  {
    id: 'II',
    name: 'II',
  },
  {
    id: 'III',
    name: 'III',
  },
  {
    id: 'IV',
    name: 'IV',
  },
  {
    id: 'V',
    name: 'V',
  },
];

export const ERROR_DISPLAY_ORDER = [
  'contactPreferenceType',
  'businessName',
  'firstName',
  'businessFirstName',
  'coregistrantFirstName',
  'lastName',
  'businessLastName',
  'coregistrantLastName',
  'middleName',
  'businessRepTitle',
  'formerName',
  'coregistrantEmail',
  'coregistrantGender',
  'coregistrantDob',
  'dob',
  'email',
  'confirmEmail',
  'confirmationEmail',
  'phone',
  'localeId',
  'eyeColorId',
  'height',
  'gender',
  'cid',
  'coregistrantCid',
  'isOwner',
  'businessIsOwner',
  'hasCoregistrant',
  'hasSecondParty',
  'outOfStateState',
  'outOfStateExpirationDate',
  'outOfStateLicenseClass',
  'outOfStateIdNumber',
  'address',
  'apartment',
  'city',
  'state',
  'stateId',
  'countyId',
  'zip',
  'contactPreference',
  'mailingAddress',
  'mailingApartment',
  'mailingCountyId',
  'mailingCity',
  'mailingState',
  'mailingZip',
];
export interface OriginalNdidPersonalInfoRequest {
  address: string;
  apartment: string;
  email: string;
  firstName: string;
  suffix: string;
  lastName: string;
  phone: string;
  cid: string;
  city: string;
  contactPreferenceType: ContactPreferenceType;
  countyId: number;
  dob: string;
  eyeColorId: number;
  gender: string;
  height: string;
  mailingAddress: string;
  mailingApartment: string;
  mailingCity: string;
  mailingCountyId: number;
  mailingState: string;
  mailingZip: string;
  middleName: string;
  state: string;
  zip: string;
}
