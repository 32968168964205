import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountyService } from '@dmv/core';
import { TaxRateJurisdictionService } from '@dmv/public/shared/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as CountyActions from '../actions/county.actions';

@Injectable()
export class CountyEffects {
  public loadCounties$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(CountyActions.loadCounties),
      switchMap(_action => {
        return this._countyService.getAllCounties().pipe(
          map(counties => CountyActions.loadCountiesSuccess({ counties })),
          catchError((error: HttpErrorResponse) => {
            return of(CountyActions.loadCountiesFailure({ error }));
          }),
        );
      }),
    );
  });

  public loadJurisdictions$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(CountyActions.loadJurisdictions),
      switchMap(_action => {
        return this._taxRateJurisdictionService.getAllJurisdictions().pipe(
          map(response => CountyActions.loadJurisdictionsSuccess({ jurisdictions: response.jurisdictions })),
          catchError((error: HttpErrorResponse) => {
            return of(CountyActions.loadJurisdictionsFailure({ error }));
          }),
        );
      }),
    );
  });

  constructor(
    private readonly _actions$: Actions,
    private readonly _countyService: CountyService,
    private readonly _taxRateJurisdictionService: TaxRateJurisdictionService,
  ) {}
}
