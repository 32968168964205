import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

export interface BranchAddress {
  branchName: string;
  branchAddress: string;
  branchAddress2: string;
  branchZip: string;
  branchCity: string;
}
export interface CalendarLink extends BranchAddress {
  appointmentTime: string;
  idRequested: string;
}
@Injectable({
  providedIn: 'root',
})
export class PublicAppointmentUtils {
  public buildBranchAddress({ branchAddress, branchAddress2 }: Partial<BranchAddress>): string {
    return `${branchAddress ?? ''}${branchAddress2 ? `, ${branchAddress2}` : ''}`;
  }

  public buildCalendarLink(
    { appointmentTime, branchName, branchAddress, branchAddress2, branchCity, idRequested }: CalendarLink,
    url: string,
  ): string {
    const startTime: DateTime = DateTime.fromISO(appointmentTime).setZone('America/New_York');
    const endTime: DateTime = startTime.plus({ hour: 1 });

    const paramsObj = {
      end: endTime.toString(),
      location: `${branchName}, ${this.buildBranchAddress({ branchAddress, branchAddress2 })}, ${branchCity}`,
      start: startTime.toString(),
      summary: `${idRequested} Appointment`,
    };

    const searchParams = new URLSearchParams(paramsObj);

    return `${url}/calendar?${searchParams.toString()}`;
  }

  public generateMapsLink({ branchAddress, branchAddress2, branchCity, branchZip }: BranchAddress): string {
    const paramsObj = {
      api: '1',
      query: `${branchAddress ? branchAddress : ''} ${branchAddress2 ? branchAddress2 : ''}, ${branchCity} ${branchZip}`,
    };
    const searchParams = new URLSearchParams(paramsObj);

    return `https://www.google.com/maps/search/?${searchParams.toString()}`;
  }
}
