import { BaseOption } from './base-option.model';

export const licenseTypeOptions: BaseOption[] = [
  {
    label: 'Non-Commercial',
    value: 'NON_CDL',
  },
  {
    label: 'Commercial (CDL)',
    value: 'CDL',
  },
];
