import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonToastModule, ScrollUtils } from '@dmv/common';
import { DocumentsReview } from '@dmv/public/shared/http';
import { FeatureFlagService } from '@libs/feature-flag';
import { UploadListReviewComponent } from './upload-list-review/upload-list-review.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CommonToastModule, MatButtonModule, MatCheckboxModule, UploadListReviewComponent],
  selector: 'dmv-documents-review',
  standalone: true,
  styleUrls: ['./documents-review.component.scss'],
  templateUrl: './documents-review.component.html',
})
export class DocumentsReviewComponent implements AfterViewInit, OnInit {
  public displayDocumentImages = false;
  public docAIFeatureFlagEnabled = false;

  private _reviewObjects: DocumentsReview | undefined;
  private readonly _skipElementString = 'main-skip-span';

  /**
   * An @Input indicating whether this component should handle showing an error or not
   */
  @Input() public handleDisplayError = true;
  @Input() public hasReviewError = false;
  @Input() public isReviewed = false;
  @Input() public autoProceed = false;
  /**
   * An @Input indicating if this component should handle showing the header
   */
  @Input() public showHeading = true;

  @Input() public set reviewObjects(ro: DocumentsReview | undefined) {
    this._reviewObjects = ro;
    this.displayDocumentImages = this._displayDocumentImages();
  }
  public get reviewObjects(): DocumentsReview | undefined {
    return this._reviewObjects;
  }

  @Output() public readonly reviewedUploads = new EventEmitter<boolean>();

  constructor(private readonly _scrollUtils: ScrollUtils, private readonly _featureFlagService: FeatureFlagService) {}

  public ngOnInit(): void {
    this.docAIFeatureFlagEnabled = this._featureFlagService.isFeatureFlagEnabledFromArray([
      'public-doc-ai-classification',
      'public-doc-ai-extraction',
      'public-doc-ai-quality',
    ]);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.autoProceed &&
      (changes.reviewObjects?.currentValue.uploads?.documents.length > 0 ||
        (!changes.reviewObjects && this.reviewObjects?.uploads?.documents.length > 0))
    ) {
      this.onCheckboxSelection(changes.autoProceed.currentValue);
    }
  }

  public ngAfterViewInit() {
    this._scrollUtils.scrollToTop(this._skipElementString);
  }

  public onCheckboxSelection(checked: boolean) {
    this.isReviewed = checked;
    this.reviewedUploads.emit(checked);
  }

  private _displayDocumentImages(): boolean {
    return this.reviewObjects?.uploads
      ? this.reviewObjects.uploads.documents.reduce((a: boolean, b): boolean => {
          if (Array.isArray(b.imgSrc) && b.imgSrc.length > 0) {
            a = true;
          }

          return a;
        }, false)
      : false;
  }
}
