export enum VehicleType {
  AMBULANCE = 'AMBULANCE',
  ATV = 'ATV',
  BOAT = 'BOAT',
  BOAT_TRAILER = 'BOAT_TRAILER',
  BUS = 'BUS',
  CUSTOM = 'CUSTOM',
  LIMOUSINE = 'LIMOUSINE',
  MOTORCYCLE = 'MOTORCYCLE',
  MOPED_A = 'MOPED_A',
  MOPED_B = 'MOPED_B',
  MOPED_C = 'MOPED_C',
  OTHER = 'OTHER',
  MOTOR_VEHICLE = 'MOTOR_VEHICLE',
  SNOWMOBILE = 'SNOWMOBILE',
  TAXI = 'TAXI',
  TRAILER = 'TRAILER',
}

export const VehicleTypeDisplay: Record<string, string> = {
  ATV: 'ATV',
  BOAT: 'Boat',
  BOAT_TRAILER: 'Boat + Trailer',
  MOTORCYCLE: 'Motorcycle',
  MOPED_A: 'Moped Class A',
  MOPED_B: 'Moped Class B',
  MOPED_C: 'Moped Class C',
  MOTOR_VEHICLE: 'Motor Vehicle',
  SNOWMOBILE: 'Snowmobile',
  TRAILER: 'Trailer',
};
