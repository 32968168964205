import { AnswerPrompt, UsedDocumentTypes } from '@dmv/public/shared/http';

export function sortPrompts(prompts: AnswerPrompt[], usedDocumentTypes: UsedDocumentTypes[]): AnswerPrompt[] {
  const usedDocumentTypeIds = new Set(usedDocumentTypes.map(doc => doc.documentTypeId));
  const usedParentDocumentTypeIds = new Set(
    usedDocumentTypes.filter(usedDocumentType => !!usedDocumentType).map(doc => doc.parentDocumentTypeId),
  );

  return [...prompts].sort((a, b) => {
    /**
     * Sorting Prompts in order of:
     * 1. Previously used AND reusable
     * 2. A prompt for a document that was used on the transaction
     */
    return (
      +usedDocumentTypeIds.has(+b.id) - +usedDocumentTypeIds.has(+a.id) ||
      +usedParentDocumentTypeIds.has(+b.id) - +usedParentDocumentTypeIds.has(+a.id)
    );
  });
}
