export enum TransactionType {
  DOCCS_NDID = 'doccsNdid',
  ID_UPGRADE = 'idUpgrade',
  LICENSE_CLASS_CHANGE = 'licenseClassChange',
  LICENSE_RECIPROCITY = 'licenseReciprocity',
  ORIGINAL_NON_DRIVER_ID = 'originalNdid',
  ORIGINAL_REGISTRATION = 'originalRegistration',
  PERMIT = 'permit',
  REGISTRATION = 'registration',
}

export enum TransactionUri {
  EXCHANGE_LICENSE = 'exchange-license',
  LICENSE_CLASS_CHANGE = 'license-class-change',
  ORIGINAL_NON_DRIVER_ID = 'original-non-driver-id',
  ORIGINAL_PERMIT = 'original-permit',
  ORIGINAL_REGISTRATION = 'register-vehicle',
  REAL_ID_EDL_UPGRADE = 'real-id-edl-upgrade',
}

export enum BaseTransactionStatus {
  DRAFT = 'Draft',
  FULFILLED = 'Fulfilled',
  NO_SHOW = 'NoShow',
  AUTO_APPROVED = 'AutoApproved',
  INFO_RECEIVED = 'InfoReceived',
  INFO_REQUESTED = 'InfoRequested',
  INVOICED = 'Invoiced',
  PAID = 'Paid',
  NEW = 'New',
  REJECTED = 'Rejected',
  SUBMITTED = 'Submitted',
  CANCELLED = 'Cancelled',
}

export enum AppointmentStatus {
  SCHEDULED = 'Scheduled',
  RESCHEDULED = 'Rescheduled',
}

export interface UsedDocumentTypes {
  documentId: number;
  documentTypeId: number;
  earliestUploadedSurveySectionType: SurveySectionType;
  parentDocumentTypeId?: number | null;
  surveySectionTypes: SurveySectionType[];
}

export enum SurveySectionType {
  ADDITIONAL_FORM_INFORMATION = 'additional-form-information',
  ADDRESS_CHANGE = 'address-change',
  ADDRESS_INFORMATION = 'address-information',
  APPLICATION_CHECKLIST = 'application-checklist',
  ARRANGE_PICKUP = 'arrange-pickup',
  CONFIRMATION = 'confirmation',
  CONTACT_INFORMATION = 'contact-information',
  CURRENT_ID = 'current-id',
  CURRENT_LICENSE = 'current-license',
  FEE_ELIGIBILITY = 'fee-eligibility',
  ID_DETAILS = 'id-details',
  ID_TYPE = 'id-type',
  LAWFUL_STATUS = 'lawful-status',
  LICENSE_INFORMATION = 'license-information',
  LEGAL_NAME = 'proof-of-legal-name',
  MV44 = 'mv-four-four',
  MV82 = 'mv-eight-two',
  MV82B = 'mv-eight-two-b',
  MV82SN = 'mv-eight-two-sn',
  OWNER_PROOF_OF_NAME = 'owner-proof-of-name',
  OWNER_INFORMATION = 'owner-information',
  OWNER_PROOF_OF_BIRTH = 'owner-proof-of-birth',
  PROOF_OF_ACQUISITION = 'proof-of-acquisition',
  PROOF_OF_BIRTH = 'proof-of-birth',
  PROOF_OF_IDENTITY = 'proof-of-identity',
  PROOF_OF_INSURANCE = 'proof-of-insurance',
  PROOF_OF_OWNERSHIP = 'proof-of-ownership',

  PROOF_OF_OWNERSHIP_TRAILER = 'proof-of-ownership-trailer',
  PROOF_OF_NAME = 'proof-of-name',
  DTF_802 = 'dtf-802',
  DTF_803 = 'dtf-803',
  PERSONAL_INFORMATION = 'personal-information',
  PROOF_OF_SOCIAL_SECURITY = 'proof-of-social-security',
  PROOF_OF_RESIDENCY = 'proof-of-residency',
  CO_REGISTRANT_INFORMATION = 'coregistrant-information',
  CO_REGISTRANT_PROOF_OF_BIRTH = 'coregistrant-proof-of-birth',
  CO_REGISTRANT_PROOF_OF_NAME = 'coregistrant-proof-of-name',
  REGISTRANT_INFORMATION = 'registrant-information',
  REGISTRANT_PROOF_OF_BIRTH = 'registrant-proof-of-birth',
  REGISTRANT_PROOF_OF_NAME = 'registrant-proof-of-name',
  TRANSACTION_INFORMATION = 'dtf-information',
  SECOND_PARTY_AUTHORIZATION = 'second-party-authorization',
  VEHICLE_INFORMATION = 'vehicle-information',
  VEHICLE_INFORMATION_BOAT = 'vehicle-information-boat',
  VEHICLE_INFORMATION_TRAILER = 'vehicle-information-trailer',
  VEHICLE_TYPE = 'vehicle-type',
  VEHICLE_USE = 'vehicle-use',
}

export const SurveySectionTypeLabel = new Map<SurveySectionType, string>([
  [SurveySectionType.ADDRESS_CHANGE, 'proof of address change'],
  [SurveySectionType.CURRENT_ID, 'current ID'],
  [SurveySectionType.LAWFUL_STATUS, 'proof of lawful status'],
  [SurveySectionType.LEGAL_NAME, 'proof of legal name'],
  [SurveySectionType.MV44, 'MV four four'],
  [SurveySectionType.REGISTRANT_PROOF_OF_NAME, 'proof of name'],
  [SurveySectionType.CO_REGISTRANT_PROOF_OF_NAME, 'proof of name'],
  [SurveySectionType.REGISTRANT_PROOF_OF_BIRTH, 'proof of birth'],
  [SurveySectionType.CO_REGISTRANT_PROOF_OF_BIRTH, 'proof of birth'],
  [SurveySectionType.OWNER_PROOF_OF_BIRTH, 'proof of birth'],
  [SurveySectionType.OWNER_PROOF_OF_NAME, 'proof of name'],
  [SurveySectionType.PROOF_OF_IDENTITY, 'proof of identity'],
  [SurveySectionType.PROOF_OF_RESIDENCY, 'proof of residency'],
  [SurveySectionType.PROOF_OF_SOCIAL_SECURITY, 'proof of social security'],
  [SurveySectionType.REGISTRANT_INFORMATION, 'registrant information'],
  [SurveySectionType.ID_TYPE, 'current ID'],
]);

export const SURVEY_SECTION_TYPE_MAP = new Map<string, SurveySectionType>([
  ['application-checklist', SurveySectionType.APPLICATION_CHECKLIST],
  ['confirmation', SurveySectionType.CONFIRMATION],
  ['address-change', SurveySectionType.ADDRESS_CHANGE],
  ['address-information', SurveySectionType.ADDRESS_INFORMATION],
  ['contact-information', SurveySectionType.CONTACT_INFORMATION],
  ['current-id', SurveySectionType.CURRENT_ID],
  ['current-license', SurveySectionType.CURRENT_LICENSE],
  ['fee-eligibility', SurveySectionType.FEE_ELIGIBILITY],
  ['id-type', SurveySectionType.ID_TYPE],
  ['lawful-status', SurveySectionType.LAWFUL_STATUS],
  ['license-information', SurveySectionType.LICENSE_INFORMATION],
  ['proof-of-legal-name', SurveySectionType.LEGAL_NAME],
  ['mv-four-four', SurveySectionType.MV44],
  ['proof-of-birth', SurveySectionType.PROOF_OF_BIRTH],
  ['proof-of-identity', SurveySectionType.PROOF_OF_IDENTITY],
  ['proof-of-insurance', SurveySectionType.PROOF_OF_INSURANCE],
  ['proof-of-ownership', SurveySectionType.PROOF_OF_OWNERSHIP],
  ['proof-of-name', SurveySectionType.PROOF_OF_NAME],
  ['dtf-802', SurveySectionType.DTF_802],
  ['dtf-803', SurveySectionType.DTF_803],
  ['personal-information', SurveySectionType.PERSONAL_INFORMATION],
  ['proof-of-social-security', SurveySectionType.PROOF_OF_SOCIAL_SECURITY],
  ['proof-of-residency', SurveySectionType.PROOF_OF_RESIDENCY],
  ['registrant-information', SurveySectionType.REGISTRANT_INFORMATION],
  ['dtf-information', SurveySectionType.TRANSACTION_INFORMATION],
  ['vehicle-type', SurveySectionType.VEHICLE_TYPE],
]);
