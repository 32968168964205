import { Injectable } from '@angular/core';
import { DocAIExtractionData, DocAiNotificationError, UploadDocumentDetail } from '@dmv/public/shared/http';

@Injectable({
  providedIn: 'root',
})
export class DocAIExtractionService {
  public setNotificationForExtractionFail(detail: UploadDocumentDetail): DocAiNotificationError {
    const notificationError: DocAiNotificationError = { showClose: false };
    notificationError.bodyText = detail.extractionScoreDocAiResults[0].validationError;
    notificationError.headerText = 'Possible Issue Detected';
    notificationError.iconClass = 'fail-icon fa-exclamation-triangle';
    notificationError.notificationClass = 'fail-color';
    notificationError.showClose = true;

    return notificationError;
  }

  public findFailingExtraction(array: DocAIExtractionData[]): DocAIExtractionData[] {
    return array.filter(result => result.validationError !== null && result.validationError !== 'PASS');
  }
}
