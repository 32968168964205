import { FormGroupState, setValue, StateUpdateFns, updateGroup } from 'ngrx-forms';

/**
 * A reusable function that can take any form group state and trim string fields.
 * Intended to be used on submit actions.
 *
 * @param state
 */
export function trimStringFields<T>(state: FormGroupState<T>): FormGroupState<T> {
  // Define the update function object for each control that needs trimming
  const trimUpdateFns: StateUpdateFns<T> = Object.keys(state.controls).reduce((acc, key) => {
    const control = state.controls[key];
    // Check if the control's value is a string that needs trimming
    if (typeof control.value === 'string') {
      acc[key] = (controlState: never) => setValue(control.value.trim())(controlState);
    }

    return acc;
  }, {} as { [K in keyof T]?: (controlState: never) => never });

  // Apply the trimming update functions to the form group state
  return updateGroup<T>(state, trimUpdateFns);
}
