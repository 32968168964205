import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonDirectivesModule, AccordionModule, BottomNavigationModule, CommonFooterModule, CommonToastModule } from '@dmv/common';
import { NavigationModule } from '../navigation/navigation.module';
import { SurveyWrapperComponent } from './survey-wrapper.component';
import { SurveyWrapperMultipleComponent } from './survey-multiple.component';
import { SurveyDataService } from './survey/survey-data.service';
import { CheckboxQuestionComponent } from './survey/survey-question/checkbox-question/checkbox-question.component';
import { RadioQuestionComponent } from './survey/survey-question/radio-question/radio-question.component';
import { SelectQuestionComponent } from './survey/survey-question/select-question/select-question.component';
import { TextQuestionComponent } from './survey/survey-question/text-question/text-question.component';
import { SurveyComponent } from './survey/survey.component';

@NgModule({
  declarations: [
    CheckboxQuestionComponent,
    RadioQuestionComponent,
    SelectQuestionComponent,
    TextQuestionComponent,
    SurveyWrapperComponent,
    SurveyWrapperMultipleComponent,
    SurveyComponent,
  ],
  exports: [
    CheckboxQuestionComponent,
    RadioQuestionComponent,
    SurveyComponent,
    SurveyWrapperComponent,
    SurveyWrapperMultipleComponent,
    TextQuestionComponent,
  ],
  imports: [
    AccordionModule,
    BottomNavigationModule,
    CommonDirectivesModule,
    CommonFooterModule,
    CommonModule,
    CommonToastModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NavigationModule,
    ReactiveFormsModule,
  ],
  providers: [SurveyDataService],
})
export class SurveyWrapperModule {}
