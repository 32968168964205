import { Branch } from '@dmv/common';
import { TaxRateJurisdictionModel } from '@dmv/public/shared/http';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as CountyActions from '../actions/county.actions';
import { County } from '../models/county.model';

export const COUNTIES_FEATURE_KEY = 'counties';

export interface CountyState extends EntityState<County> {
  loading: boolean;
  error?: string | null;
  jurisdictions?: TaxRateJurisdictionModel[];
  offices?: Branch[];
  selectedId?: string | number;
}

export const countyAdapter: EntityAdapter<County> = createEntityAdapter<County>();

export const initialState: CountyState = countyAdapter.getInitialState({
  jurisdictions: [],
  loading: false,
  offices: null,
  selectedId: -1,
});

const workflowReducer = createReducer(
  initialState,
  on(CountyActions.loadCounties, state => ({ ...state, loading: true })),
  on(CountyActions.loadCountyOffices, state => ({ ...state, loading: true })),
  on(CountyActions.loadCountiesSuccess, (state, { counties }) => {
    return {
      ...countyAdapter.setAll(counties, state),
      loading: false,
    };
  }),
  on(CountyActions.loadCountyOfficesSuccess, (state, { countyOffices }) => {
    return {
      ...state,
      loading: false,
      offices: countyOffices,
    };
  }),
  on(CountyActions.loadJurisdictionsSuccess, (state, { jurisdictions }) => {
    return {
      ...state,
      jurisdictions,
      loading: false,
    };
  }),
  on(CountyActions.removeCounty, (state, { id }) => {
    return {
      ...countyAdapter.removeOne(id, state),
      loading: false,
    };
  }),
  on(CountyActions.setSelectedCountyId, (state, { id }) => {
    return {
      ...state,
      selectedId: id,
    };
  }),
  on(CountyActions.loadCountiesFailure, (_, { error }) => {
    return {
      ...initialState,
      error: error.message,
      loading: false,
    };
  }),
  on(CountyActions.loadJurisdictionsFailure, (_, { error }) => {
    return {
      ...initialState,
      error: error.message,
      loading: false,
    };
  }),
);

export function countyReducer(state: CountyState = initialState, action: Action) {
  return workflowReducer(state, action);
}

export const {
  selectIds: selectCountyIds,
  selectEntities: selectCountyEntities,
  selectAll: selectAllCounties,
  selectTotal: selectCountyTotal,
} = countyAdapter.getSelectors();
