import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlagService } from './feature-flag.service';

/**
 * This guard is to be used when a transaction workflow is not behind a feature flag but should be blocked
 * from loading until a resident result has been returned and mapped from the call to /resident/login.
 */
@Injectable({
  providedIn: 'root',
})
export class ResidentGuard implements CanLoad {
  constructor(private readonly _router: Router, private readonly _featureFlagService: FeatureFlagService) {}
  public canLoad(_route: Route, _segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this._featureFlagService.residentIdentified$();
  }
}
