/* eslint-disable no-useless-escape */
export const RegularExpressionsConstants = {
  CLUB_MEMBER_NUMBER: `^[0-9]{6}$`,
  EMAIL: `^[a-zA-Z0-9!#$%&'*+-/=?^_\`{|}~]+@[a-zA-Z0-9.-]+\\.((?!-)[a-zA-Z-]{2,63})$`,
  ID_NUMBER: '^[a-zA-Z0-9]{5,17}$',
  INT_GREATER_THAN_ZERO: `^[1-9][0-9]*$`,
  INT_ZERO_OR_GREATER: `^[0-9][0-9]*$`,
  LICENSE_ID: `^[0-9]{9}$`,
  NAME: `^[a-zA-Z \'-]*$`,
  ODOMETER: `^[0-9]$`,
  PHONE: '^[0-9]{10}$',
  YEAR: `^[0-9]{4}$`,
  ZIP_CODE: '^[0-9]{5}$',
};
