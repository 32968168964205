import { AppointmentStatus, Branch } from '@dmv/common';

export type WorkFlowType =
  | 'scheduling'
  | 'contact-information'
  | 'reservation-summary'
  | 'id-upgrade-type'
  | 'cid'
  | 'survey'
  | 'upload'
  | 'confirmation';
export interface Error {
  message: string;
}

// Time Slot DTO
export interface TimeSlot {
  startTime: string;
  endTime: string;
}

export interface AppointmentReservation {
  publicId: string; // appointmentId
  title: string;
  branch: Branch;
  services: QmaticService[];
  start: string;
  end: string;
  notes: string;
}

// Reservation

export interface QmaticService {
  duration: number;
  name: string;
  publicId: string;
}

// Appointment

export interface Appointment {
  publicId: string;
  title: string;
  customers: Customer[];
  start: string;
  end: string;
  branch: Branch;
  services: QmaticService[];
  notes: string;
}

export interface ContactInformationCustomer {
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  zip: string;
  email: string;
  licenseId: string;
  contactPreferenceType: 'phone' | 'sms' | 'email';
  birthDay: string;
  birthMonth: string;
  birthYear: number;
}

export interface Customer extends ContactInformationCustomer {
  publicId: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
  addressCountry: string;
}

export interface RealIdEdlDTO {
  id: number;
  transactionId: string;
  status: string;
  currentFirstName: string;
  currentMiddleName: string;
  currentLastName: string;
  documentIds: number[];
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  mailingAddress: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  mailingCounty: string;
  currentIdType: string;
  idRequested: string;
  idType: string;
  appointmentTime: string;
  appointmentReferenceNumber: string;

  /**
   * Used to generate QR codes, this is different from the {@link appointmentReferenceNumber}
   */
  appointmentReservationNumber: string;
  appointmentStatus: AppointmentStatus;
  branchName: string;
  branchAddress: string;
  branchAddress2: string;
  branchCity: string;
  branchState: string;
  branchZip: string;
  createdAt: string;
  updatedAt: string;
  licenseId: string;
  contactPreferenceType: 'phone' | 'sms' | 'email';
  birthDay: string;
  birthMonth: string;
  birthYear: number;
  submittedOn: string;
  applicationId: string;
  rejectionReason: string;
}

// Front End Models

export interface AppointmentDetails extends SchedulingDetail {
  contactPreference: ContactPreference;
}

export interface SchedulingDetail {
  branch: Branch;
  date: string;
  timeSlot: TimeSlot;
  previousStartTime?: string;
}

//TODO: this doesn't belong here; it's used by OP, OR, etc., not just realID. move to more logical location,
// then determine what other interfaces here should be moved (rob's opinion here is better than mine).
export interface BranchSearchParams {
  currentSortOption?: BranchSortOption;
  withinMiles?: number;
  show?: boolean;
  address?: string;
  locationId?: number;
}

export interface ContactPreference {
  type: 'phone' | 'sms' | 'email';
  label: ContactPreferenceLabel;
  icon: ContactPreferenceTypeIcon;
}

export type ContactPreferenceTypeIcon = 'fas fa-phone-alt' | 'far fa-comment-alt' | 'far fa-envelope';
export enum ContactPreferenceLabel {
  PHONE = 'Phone Call',
  TEXT_SMS = 'Text / SMS',
  EMAIL = 'Email',
}

export type BranchSortType = 'availability' | 'proximity';

export interface BranchSortOption {
  title: string;
  type: BranchSortType;
}

// TODO Move this to to a file not specific to real id
export enum StatusText {
  IN_PROGRESS = 'In Progress',
  PENDING_APPROVAL = 'Pending Pre-Approval',
  PENDING_IN_PERSON_VISIT = 'Pending In-Person Visit',
  APPROVED = 'Pre-Approved',
  PAID = 'Payment Received',
  PAY_INVOICE = 'Pay Your Invoice',
  SUCCESSFUL = 'Approved',
  REJECTED = 'Rejected',
  ADDITIONAL_STEPS_NEEDED = 'Additional Steps Needed',
  CANCELLED = 'Cancelled',
}
