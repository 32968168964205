import { Injectable } from '@angular/core';
import { UploadStatus } from '@dmv/common';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  public createPdf = (files: File[]) =>
    new Promise<File>((resolve, reject) => {
      try {
        const doc = new jsPDF();
        const filePromises: Array<Promise<string | ArrayBuffer>> = [];
        for (const file of files) {
          filePromises.push(this.readFile(file));
        }
        Promise.all(filePromises).then((values: Array<string | ArrayBuffer>) => {
          const imagePromises: Array<Promise<HTMLImageElement>> = [];
          for (const image of values) {
            imagePromises.push(this.loadImage(image));
          }
          Promise.all(imagePromises).then((imageElements: HTMLImageElement[]) => {
            for (let i = 0; i < values.length; i++) {
              const image = imageElements[i];
              const dimensions = this.scale(doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), image.width, image.height);
              const fileExtension = this.getExtension(files[i].name);
              if (i > 0) {
                doc.addPage();
                doc.setPage(i + 1);
              }
              doc.addImage(image, fileExtension, 0, 0, dimensions.width, dimensions.height);
              if (i + 1 === files.length) {
                const pdf = doc.output('blob');
                const aFile = this.blobToFile(pdf, 'generated.pdf');
                resolve(aFile);
              }
            }
          });
        });
      } catch (err) {
        reject(err);
      }
    });

  public async createPdfWithMixFileTypes(files: File[]) {
    if (!this.isValidInput(files)) {
      return null;
    }
    const pdfFiles: File[] = [];
    const imageFiles: File[] = [];

    for (const file of files) {
      if (this.isImage(file.name)) {
        imageFiles.push(file);
      }
      if (this.isPdf(file.name)) {
        pdfFiles.push(file);
      }
    }
    console.log(imageFiles);
    console.log(pdfFiles);

    if (imageFiles.length > 0) {
      const generatedFile = await this.createPdf(imageFiles);

      return [...pdfFiles, generatedFile];
    } else {
      return [...pdfFiles];
    }
  }

  public getExtension(filename): string {
    const index = filename.lastIndexOf('.');
    const parts = filename.slice(index + 1);
    // console.log(parts);

    return parts;
  }

  public isImage(filename) {
    const ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'png':
      case 'jpeg':
        // case 'tiff';e
        return true;
    }

    return false;
  }

  public isPdf(filename) {
    const ext = this.getExtension(filename);

    return ext.toLowerCase() === 'pdf';
  }

  public blobToFile = (blob, fileName: string): File => {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;

    return blob as File;
  };

  public loadImage = url =>
    new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });

  public readFile = file =>
    new Promise<string | ArrayBuffer>((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => resolve(fr.result);
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });

  public scale(maxWidth, maxHeight, width, height) {
    let ratio = 0;

    if (width > maxWidth) {
      ratio = maxWidth / width; // get ratio for scaling image
      width = maxWidth; // Set new width
      height = height * ratio; // Scale height based on ratio
    }

    // Check if current height is larger than max
    if (height > maxHeight) {
      ratio = maxHeight / height; // get ratio for scaling image
      width = width * ratio; // Set new height
      height = maxHeight; // Scale width based on ratio
    }

    return {
      height,
      width,
    };
  }

  public isValidInput = (files: File[]) => {
    if (files && files.length > 0) {
      for (const file of files) {
        if (!this.isPdf(file.name) && !this.isImage(file.name)) {
          return false;
        }
      }

      return true;
    } else {
      return false;
    }
  };

  public async isValidFiles(files: File[]): Promise<UploadStatus> {
    if (!this.isValidInput(files)) {
      return UploadStatus.INVALID;
    }
    let valid = UploadStatus.VALID;
    for (const file of files) {
      const fileStatus = await this.isValidFile(file);
      if (fileStatus !== UploadStatus.VALID) {
        valid = fileStatus;
        break;
      }
    }

    return valid;
  }

  public async isValidFile(file: File): Promise<UploadStatus> {
    if (!file) {
      return UploadStatus.INVALID;
    }

    const fileName = file.name;
    if (this.isImage(fileName)) {
      const valid = await this.validateImage(file);
      if (!valid) {
        return UploadStatus.CORRUPTED_FILE;
      }

      return UploadStatus.VALID;
    } else if (this.isPdf(fileName)) {
      // Don't have a way to validate a real PDF file now
      return UploadStatus.VALID;
    }

    return UploadStatus.INVALID;
  }

  /**
   * To validate whether a corrupted image
   */
  public validateImage(file: File): Promise<boolean> {
    return new Promise(resolve => {
      if (!file) {
        resolve(false);
      }

      const image = new Image();

      image.onload = () => {
        resolve(true);
      };
      image.onerror = () => {
        resolve(false);
      };
      image.src = URL.createObjectURL(file);
    });
  }
}
