import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CountyModel } from '@dmv/common';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountyService {
  public allCounties: CountyModel[] = null;

  constructor(private readonly _http: HttpClient, @Inject('env') private readonly _env) {}

  public getAllCounties() {
    if (this.allCounties) {
      return of(this.allCounties);
    }

    return this._http.get<CountyModel[]>(`${this._env.apiUrl}/county`).pipe(tap(res => (this.allCounties = res)));
  }

  public filterStateCounties(data: CountyModel[]) {
    return data.filter(county => county.stateRun === true);
  }

  public filterClerkCounties(data: CountyModel[]) {
    return data.filter(county => county.stateRun === false);
  }
}
