import { Injectable } from '@angular/core';
import { DocumentService, TransactionType } from '@dmv/core';
import { BookingData } from '@dmv/public/shared/feature-scheduling/data-access';
import { DocumentTypes } from '@dmv/public/shared/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FlowMessage } from '../shared/models/flow-message.model';
import { SurveyAnswersModel } from '../shared/models/survey-answers.model';
import { BaseFlowSteps } from '../shared/static-data/base-flow-steps';

@Injectable({
  providedIn: 'root',
})
export class GeneralFlowServiceService {
  public $showStepperSubject: Observable<boolean>;
  public $titleSubject: Observable<string>;
  public observableMessage: Observable<FlowMessage>;
  public observableSurveyAnswers: Observable<SurveyAnswersModel>;
  public observableDocumentList: Observable<DocumentTypes[]>;
  public observableTransactionSteps: Observable<Array<{ status: string; stepNumber: number; title: string }>>;
  public bookingData: BookingData = {};
  public transactionSteps = BaseFlowSteps.data;
  public message = new FlowMessage();
  public surveyAnswers = new SurveyAnswersModel();
  public documentList: DocumentTypes[] = [];
  public mandatoryUploadItems = null;
  public transactionId = null;

  protected _showStepperSubject = new BehaviorSubject(true);

  protected _transactionStepsSubject = new BehaviorSubject(this.transactionSteps);
  protected _messageSubject = new BehaviorSubject(this.message);
  protected _surveyAnswersSubject = new BehaviorSubject(this.surveyAnswers);
  protected _documentListSubject = new BehaviorSubject(this.documentList);

  private readonly _titleSubject = new BehaviorSubject(null);
  constructor(public documentService: DocumentService) {
    this.$showStepperSubject = this._showStepperSubject.asObservable();
    this.$titleSubject = this._titleSubject.asObservable();
    this.observableMessage = this._messageSubject.asObservable();
    this.observableSurveyAnswers = this._surveyAnswersSubject.asObservable();
    this.observableDocumentList = this._documentListSubject.asObservable();
    this.observableTransactionSteps = this._transactionStepsSubject.asObservable();
  }

  public showStepper(showStepper: boolean) {
    this._showStepperSubject.next(showStepper);
  }

  public setTitle(newTitle: string) {
    this._titleSubject.next(newTitle);
  }

  public getTransaction(transactionId) {
    return of(transactionId);
  }

  public updateSurveyAnswer(key, value) {
    this.surveyAnswers[key] = value;
    if (this.markRequiredDocuments()) {
      return true;
    } else {
      return false;
    }
  }

  public markRequiredDocuments() {
    return true;
  }

  public getDocumentTypes(transactionType: TransactionType) {
    this.documentService.getDocumentTypes(transactionType).subscribe(res => {
      if (res) {
        this.documentList = res;
        this._documentListSubject.next(this.documentList);
        this.markRequiredDocuments();
      }
    });
  }

  public setMessage(message, linkText, link, messageOptions?) {
    this.message.message = message;
    this.message.linkText = linkText;
    this.message.link = link;
    this.message.messageOptions = messageOptions;
    this._messageSubject.next(this.message);
  }

  public unsetMessage() {
    this.message.message = '';
    this.message.linkText = '';
    this.message.link = '';
    this.message.messageOptions = [];
    this._messageSubject.next(this.message);
  }
}
