import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FeatureFlagService, FeatureFlagUserModel } from '@libs/feature-flag';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Resident } from '../models/resident';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public LOGIN_URL = 'resident/login';

  public resident = new BehaviorSubject<Resident>(null);
  public isAuthenticated = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly FLOW_FILE_UPLOAD_ERROR_MSG = 'Your file uploads are incomplete, you cannot submit your application';

  constructor(
    @Inject('env') private readonly _env,
    private readonly _httpClient: HttpClient,
    private readonly _featureFlagService: FeatureFlagService,
  ) {}

  public residentLogin(): Observable<Resident> {
    return this._httpClient.get<Resident>(`${this._env.apiUrl}/${this.LOGIN_URL}`).pipe(
      tap((user: Resident) => {
        this.resident.next(user);
        let featureFlagUserModel = null;
        if (
          ['@nuvalence.io', '@levelaccess.com', '@dmv.ny.gov'].some(email => user?.email?.toLowerCase().includes(email)) ||
          user?.username?.toLowerCase().includes('nuvalence')
        ) {
          // Preserve old usernames
          featureFlagUserModel = new FeatureFlagUserModel({
            emailAddress: user.email,
            id: user.id.toString(),
            username: user.username,
          });
        }
        this._featureFlagService.identify(featureFlagUserModel);
      }),
    );
  }

  public scrollToTop() {
    const el = document.getElementById('header');
    if (el) {
      setTimeout(() => {
        el.scrollIntoView();
        this.resetFocus();
      }, 300);
    }
  }

  public resetFocus() {
    // for accessibility, reset the tab index as the dom currently keeps
    // focus on the "continue" button. set focus on the step progress
    // bar (and fall back to document.body if it doesn't exist)
    const el = document.getElementById('step-progress') || document.body;
    el.setAttribute('tabindex', '-1');
    el.focus();
    el.removeAttribute('tabindex');
  }

  public setFocus(eleId: string) {
    // resetFocus will scroll page to the top, then need a setTimeout to scroll to error-box
    setTimeout(() => {
      const el = document.getElementById(eleId);
      if (el) {
        el.setAttribute('tabindex', '0');
        el.scrollIntoView();
        el.focus();
      }
    }, 300);
  }

  public getFlowFileUploadErrorMsg(): string {
    return this.FLOW_FILE_UPLOAD_ERROR_MSG;
  }
}
