export function validateLicense(_value: string): boolean {
  const set1 = `00${_value.toString().substring(0, 3)}`;
  const set2 = _value.toString().substring(3);
  let total = 0;
  for (let i = 0; i < 5; i++) {
    total += (parseInt(set1.charAt(i), 10) + parseInt(set2.charAt(i), 10)) * (6 - i);
  }

  return total % 10 === parseInt(_value.charAt(8), 10);
}
