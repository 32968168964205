<section class="navigation-row" id="navigation">
  <ng-container *ngIf="showLeftText">
    <ng-content></ng-content>
  </ng-container>
  <div class="col-md-3 col-sm-6" *ngIf="showPrevious">
    <!-- prettier-ignore -->
    <button (click)="previousClick.emit()" dmvScrollToTop mat-raised-button color="primary" data-cy="previousBtn">{{ previousText }}</button>
  </div>
  <div class="col-md-3 col-sm-6" *ngIf="showContinue && !showSubmit">
    <!-- prettier-ignore -->
    <button (click)="continueClick.emit()" type="submit" dmvScrollToTop mat-raised-button color="primary" data-cy="continueBtn">{{ continueText }}</button>
  </div>
  <div class="col-md-3 col-sm-6" *ngIf="showSubmit">
    <!-- prettier-ignore -->
    <button [disabled]="submitDisabled" (click)="submitClick.emit()" type="submit" dmvScrollToTop mat-raised-button color="primary" data-cy="submitBtn">{{ submitText }}</button>
  </div>
</section>
