import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonToastModule } from '@dmv/common';
import { CoreModule } from '@dmv/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgrxFormsModule } from 'ngrx-forms';
import { environment } from '../../environments/environment';
import { NgrxAddressComponent } from './components/address/ngrx-address.component';
import { ConfirmationDocumentsComponent } from './components/confirmation-documents/confirmation-documents.component';
import { DocumentUploadHelperTextComponent } from './components/document-upload-helper-text/document-upload-helper-text.component';
import { HeaderComponent } from './components/header/header.component';

import { IdComparisonModalComponent } from './components/id-comparison-modal/id-comparison-modal.component';
import { IdComparisonComponent } from './components/id-comparison/id-comparison.component';
import { MessageComponent } from './components/message/message.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RequiredDocumentsListComponent } from './components/required-documents-list/required-documents-list.component';
import { SharedAppointmentConfirmationComponent } from './components/shared-appointment-confirmation/shared-appointment-confirmation.component';
import { SharedReserveAppointmentComponent } from './components/shared-reserve-appointment/shared-reserve-appointment.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { TransactionNavigationComponent } from './components/transaction-navigation/transaction-navigation.component';
import { MaterialModule } from './material.module';
import { AppointmentInfoModalModule } from './modals/appointment-info-modal/appointment-info-modal.module';

@NgModule({
  declarations: [
    HeaderComponent,
    StepperComponent,
    TransactionNavigationComponent,
    MessageComponent,
    RequiredDocumentsListComponent,
    SharedAppointmentConfirmationComponent,
    SharedReserveAppointmentComponent,
    IdComparisonModalComponent,
    ProgressBarComponent,
    ConfirmationDocumentsComponent,
    NgrxAddressComponent,
    IdComparisonComponent,
    DocumentUploadHelperTextComponent,
  ],
  exports: [
    HeaderComponent,
    StepperComponent,
    TransactionNavigationComponent,
    MessageComponent,
    RequiredDocumentsListComponent,
    CoreModule,
    SharedAppointmentConfirmationComponent,
    SharedReserveAppointmentComponent,
    IdComparisonModalComponent,
    ProgressBarComponent,
    AppointmentInfoModalModule,
    ConfirmationDocumentsComponent,
    NgbModule,
    NgrxAddressComponent,
    IdComparisonComponent,
    DocumentUploadHelperTextComponent,
  ],
  imports: [
    CommonModule,
    CommonToastModule,
    FormsModule,
    RouterModule,
    NgbModule,
    CoreModule,
    ReactiveFormsModule,
    AppointmentInfoModalModule,
    NgrxFormsModule,
    MaterialModule,
  ],
  providers: [{ provide: 'env', useValue: environment }],
})
export class SharedModule {}
