import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { QrCodeService } from './qr-code.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-qr-code',
  styleUrls: ['./qr-code.component.scss'],
  templateUrl: './qr-code.component.html',
})
export class QrCodeComponent implements OnInit {
  @ViewChild('qrCodeImage') public qrCodeImage: ElementRef;

  public errorMessage?: string;
  public transactionId?: string;

  public qrCodeInfo$ = this._activatedRoute.params.pipe(
    map(({ reservationId }) => {
      if (!reservationId) {
        this.errorMessage = 'The application encountered an error, error code = 400';
        this._changeDetectorRef.markForCheck();

        return throwError('The application encountered an error, error code = 400');
      }

      return reservationId;
    }),
    switchMap((reservationId: string) => this._qrCodeService.getQrCode$(reservationId)),
    tap((qrCode: Blob) => {
      if (!this.errorMessage) {
        const reader = new FileReader();
        reader.readAsDataURL(qrCode);
        reader.onload = _event => {
          this.qrCodeImage.nativeElement.src = reader.result;
        };
      }
    }),
    catchError((error: HttpErrorResponse) => {
      this.errorMessage = `The application encountered an error, error code = ${error.status}`;
      this._changeDetectorRef.markForCheck();

      return throwError(error);
    }),
  );
  public applicationName: string;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _qrCodeService: QrCodeService,
  ) {}

  public ngOnInit(): void {
    switch (this._activatedRoute.snapshot.params.application) {
      case 'original-permit':
        this.applicationName = 'NY State Learner Permit';
        break;
      case 'real-id-edl-upgrade':
        this.applicationName = 'Enhanced or Real ID';
        break;
      case 'exchange-license':
        this.applicationName = 'Out of State License Exchange';
        break;
      default:
        this.applicationName = null;
    }

    this.transactionId = this._activatedRoute.snapshot.params.transactionId;

    this.qrCodeInfo$.pipe(take(1)).subscribe();
  }
}
