import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthenticationAdapter } from '../authentication.adapter';
import { AuthenticatedInfo, User } from '../authentication.type';

const mockUser: User = {
  email: 'mock@mock.com',
  firstName: 'mock first name',
  id: 'mock-id',
  lastName: 'mock last name',
  username: 'mock username',
};

const mockAccessToken = 'mock-access-token';
const mockOriginalUri = '/home/application/route';

@Injectable()
export class MockAuthenticationAdapter implements AuthenticationAdapter {
  public getUser$(): Observable<User> {
    return of(mockUser);
  }

  public getIsAuthenticated$(): Observable<boolean> {
    return of(true);
  }

  public getAuthenticationInfo$(): Observable<AuthenticatedInfo> {
    return of({
      isAuthenticated: true,
      user: mockUser,
    });
  }

  public getAccessToken(): string {
    return mockAccessToken;
  }

  public getOriginalUri(): string {
    return mockOriginalUri;
  }

  public login(): Promise<void> {
    return Promise.resolve();
  }

  public clearSession(): Promise<void> {
    return Promise.resolve();
  }

  public logout(): Promise<boolean | void> {
    return Promise.resolve();
  }
}
