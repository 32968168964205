import { SurveySectionType } from '@dmv/public/shared/http';
import { createAction, props } from '@ngrx/store';

export const initializeNavigation = createAction('[Navigation] Initialize Navigation', props<{ transactionUri: string }>());
export const navigateToApplicationChecklist = createAction('[Navigation] Navigate to Application Checklist');
export const navigateToApplicationChecklistAndShowSubmit = createAction(
  '[Navigation] Navigate to Application Checklist and show Submit',
  props<{ showCounties: boolean }>(),
);
export const navigateToPage = createAction('[Navigation] Navigate To Page', props<{ urlFragments: string[] }>());
export const navigateToTransactionPage = createAction('[Navigation] Navigate To Transaction Page', props<{ urlFragments: string[] }>());
/**
 * This action should be handled by the parent transaction effects file.
 */
export const navigateToNextIncompleteSection = createAction(
  '[Navigation] Navigate Next Incomplete Section',
  props<{ sectionId: SurveySectionType }>(),
);
