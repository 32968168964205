import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LoginRedirectComponent } from './login-redirect.component';

@NgModule({
  declarations: [LoginRedirectComponent],
  imports: [
    RouterModule.forChild([
      {
        component: LoginRedirectComponent,
        path: '',
      },
    ]),
    CommonModule,
    RouterModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class LoginRedirectModule {}
