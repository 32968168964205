export enum DashboardCurrentStepTitle {
  ADDITIONAL_INFO = 'Submit Additional Request',
  TAKE_AN_ONLINE_TEST = 'Take an Online Test',
  SUBMIT = 'Submit Your Pre-Screening',
  SUBMITTED = 'Submission Pre-Approval',
  SCHEDULE = 'Make Your Reservation',
  ARRANGE_AN_OFFICE_VISIT = 'Plan Your Office Visit',
  ATTEND = 'Prepare for Your Visit',
  PAY_INVOICE = 'Pay Your Invoice',
  OFFICE_VISIT = 'Plan Your Office Visit',
  DMV_OFFICE_INFORMATION = 'Plan Your DMV Office Visit',
}

export enum DashboardCurrentStepMessage {
  TAKE_YOUR_TEST = 'Take Your Test',
  RETAKE_YOUR_TEST = 'Retake Your Test',
  CONTINUE_APPLICATION = 'Continue your submission for',
  YOUR_APPLICATION = 'Your application for',
  PAY = 'Pay online using debit or credit card',
  RESCHEDULE = 'Reschedule',
  RESUME_APPLICATION = 'Resume Your Application',
  REUPLOAD = 'Correct Your Documents',
}
