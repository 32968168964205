<div class="upload-accordion-wrapper" class="upload-item-{{ uploadItem?.id }}-container">
  <label class="upload-input-label" for="app-upload-panel-input-label">Image file input </label>
  <input
    id="app-upload-panel-input-label"
    #fileInput
    [attr.data-cy]="'fileInput-' + uploadItem?.id"
    [multiple]="showMultiple()"
    [attr.aria-labelledby]="'app-upload-panel-title-label-' + uploadItem?.id"
    (change)="browseFile($event)"
    (cancel)="cancelFile()"
    accept="application/pdf,image/jpeg,image/png"
    hidden
    type="file"
  />

  <ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds" (panelChange)="onUploadToggle($event)">
    <ngb-panel
      id="upload-item-{{ itemIndex + itemIndexBias }}"
      *ngIf="uploadItem"
      [cardClass]="docAIFeatureFlagEnabled ? 'dmv-docai-enabled-accordion dmv-accordion' : 'dmv-accordion'"
    >
      <ng-template ngbPanelTitle>
        <div
          class="completedIcon"
          *ngIf="uploadItem.uploaded && !displayDocAIErrorItem(uploadItem)"
          [attr.data-cy]="'uploadCompleted-' + uploadItem?.id"
        >
          <i class="fas fa-check-circle" *ngIf="completed"></i>
        </div>
        <div class="uncompletedIcon" *ngIf="displayDocAIErrorItem(uploadItem)" [attr.data-cy]="'uploadNotCompleted-' + uploadItem?.id">
          <i class="fas fa-exclamation-circle"></i>
        </div>
        <span class="sr-only" *ngIf="!completed">Not Completed</span>
        <span class="upload-toggle-icon">
          <i
            class="fa fa-chevron-right toggleIcon"
            [ngClass]="{ showExpand: expanded }"
            [attr.data-cy]="'file-upload-document-' + completed + '-' + uploadItem.id"
          ></i>
        </span>
        <h2 class="upload-item">
          <div class="upload-item-info" [attr.data-cy]="'uploadToggle-' + uploadItem.id">
            <div class="upload-item-title" [attr.id]="'app-upload-panel-title-label-' + uploadItem.id">
              {{ uploadItem.title }}
              <span
                class="tipHelper fa fa-question-circle"
                *ngIf="uploadItem.documentTypeId === 15"
                [ngbTooltip]="tipContent"
                placement="right"
                tabindex="0"
              ></span>
            </div>
            <ng-template #tipContent>Make sure to select all pages of the MV-44 to upload</ng-template>
            <lib-pdf-modal
              class="mobile-hidden previewIcon"
              *ngIf="uploadItem && uploadItem.uploadedDocumentId"
              [id]="uploadItem.uploadedDocumentId"
              [transactionType]="transactionType"
              [title]="uploadItem.title"
            >
            </lib-pdf-modal>
          </div>
        </h2>
      </ng-template>
      <ng-template *ngIf="uploadItem.details" ngbPanelContent>
        <div class="panel-heading-nuv" *ngIf="docAIFeatureFlagEnabled">
          <div class="instructions-container">
            <p
              class="instructions-paragraph"
              *ngIf="uploadItem.details[0] && uploadItem.details[0].instructions"
              [innerHTML]="uploadItem.details[0].instructions | dmvmarkdown"
              [attr.id]="uploadItem.details[0] + 'instr_' + uploadItem.id"
            ></p>

            <a class="preview-link" *ngIf="!uploadItem.details[0].isSample" (click)="openPreviewModal(uploadItem.details)"
              >See an example.</a
            >
          </div>

          <p class="mb-4" *ngIf="showMultiple()">
            If uploading documents with multiple pages, click 'Upload Documents' and select all the associated files you would like to
            upload at once.
          </p>
        </div>
        <div class="panel-body-nuv">
          <div
            class="row"
            *ngFor="let detail of uploadItem.details; let i = index"
            [ngClass]="{ 'document-side': docAIFeatureFlagEnabled }"
            [attr.aria-label]="getItemErrorMsg(detail)"
            [id]="detail.id"
          >
            <div *ngIf="detail.placeHolderUrl" [ngClass]="{ 'col-md-12': docAIFeatureFlagEnabled, 'col-md-5': !docAIFeatureFlagEnabled }">
              <h3
                class="mb-2 subTitle upload-title"
                *ngIf="docAIFeatureFlagEnabled && !uploadItem.link && detail.imageSrc"
                [attr.id]="i + 'desc_' + uploadItem.id"
                [ngClass]="{ required: detail.required }"
              >
                {{ detail.subTitle }}
              </h3>

              <div class="preview-image" *ngIf="!detail.imageSrc && !docAIFeatureFlagEnabled">
                <img class="img-fluid" [src]="detail.placeHolderUrl" alt="" />
              </div>

              <div class="image-container preview-image" *ngIf="detail.imageSrc && !docAIFeatureFlagEnabled">
                <img
                  class="img-fluid"
                  *ngFor="let fileSrc of detail.imageSrc"
                  [src]="fileSrc"
                  alt="User uploaded document for {{ detail.subTitle }}"
                />
              </div>

              <div
                class="image-container docai-image"
                *ngIf="detail.imageSrc && docAIFeatureFlagEnabled && !detail.loading"
                [ngClass]="{ 'alert-border': displayDocAIErrorDetail(detail) }"
              >
                <img class="img-fluid" [src]="detail.imageSrc[0]" alt="User uploaded document for {{ detail.subTitle }}" />
                <div class="doc-number" *ngIf="detail.imageSrc.length > 1">1/{{ detail.imageSrc.length }}</div>
              </div>
            </div>

            <div
              [ngClass]="{ 'col-md-12': docAIFeatureFlagEnabled, 'col-md-7': !docAIFeatureFlagEnabled }"
              [attr.aria-labeledby]="i + 'desc_' + uploadItem.id"
              [attr.data-testid]="i + 'desc_' + uploadItem.id"
              [attr.aria-describedby]="i + 'instr_' + uploadItem.id"
            >
              <h3
                class="mb-2 subTitle upload-title"
                *ngIf="!docAIFeatureFlagEnabled && !uploadItem.link"
                [attr.id]="i + 'desc_' + uploadItem.id"
                [ngClass]="{ required: detail.required }"
              >
                {{ detail.subTitle }}
              </h3>
              <h3 class="required-document has-link upload-title" *ngIf="uploadItem.link">
                <a href="{{ uploadItem.link }}" target="_blank">
                  {{ detail.subTitle }}
                  <span class="sr-only">(external page, opens in a new tab)</span>
                  <i class="fas fa-external-link-alt ml-1" aria-hidden="true"></i>
                </a>
              </h3>

              <p
                class="mb-4"
                *ngIf="detail.instructions !== '' && !docAIFeatureFlagEnabled"
                [innerHTML]="detail.instructions | dmvmarkdown"
                [attr.id]="i + 'instr_' + uploadItem.id"
              ></p>
              <p class="mb-4" *ngIf="showMultiple() && !docAIFeatureFlagEnabled">
                If uploading documents with multiple pages, click 'Upload Documents' and select all the associated files you would like to
                upload at once.
              </p>
              <dmv-doc-ai-notification
                *ngIf="
                  docAIFeatureFlagEnabled &&
                  detail.status &&
                  ((!detail.subTitle.includes('Back') && showFrontAlert) || (detail.subTitle.includes('Back') && showBackAlert))
                "
                [error]="getNotificationError(detail)"
                (closed)="docAiNotificationClosed(detail)"
              ></dmv-doc-ai-notification>
              <div class="button-container">
                <button
                  [attr.aria-busy]="docAIFeatureFlagEnabled && detail.loading"
                  [attr.aria-describedby]="i + 'upl_' + uploadItem.id"
                  [attr.aria-label]="detail.subTitle + ' upload button'"
                  [attr.data-cy]="'fileUpload-' + uploadItem.id + '-' + i"
                  [attr.data-testid]="'fileUploadButton'"
                  [attr.id]="i + 'upl_' + uploadItem.id"
                  [disabled]="docAIFeatureFlagEnabled && detail.loading"
                  [ngClass]="{
                    'upload-button': docAIFeatureFlagEnabled,
                    'uploaded': detail.uploaded && docAIFeatureFlagEnabled,
                    'half-button':
                      docAIFeatureFlagEnabled && docTypeChangeFeatureFlagEnabled && detail.docTypeChangeEligible && !detail.loading
                  }"
                  (click)="browseButtonClicked(detail)"
                  mat-flat-button
                  color="primary"
                  type="button"
                >
                  {{ getUploadTag(detail) }}
                  <mat-icon class="cloud-icon" *ngIf="docAIFeatureFlagEnabled && !detail.loading">cloud_upload</mat-icon>
                  <mat-icon class="cloud-icon spin-icon" *ngIf="docAIFeatureFlagEnabled && detail.loading">refresh</mat-icon>
                </button>
                <button
                  *ngIf="docAIFeatureFlagEnabled && docTypeChangeFeatureFlagEnabled && detail.docTypeChangeEligible && !detail.loading"
                  [ngClass]="{
                    'half-button':
                      docAIFeatureFlagEnabled && docTypeChangeFeatureFlagEnabled && detail.docTypeChangeEligible && !detail.loading
                  }"
                  (click)="docTypeChangeButtonClicked(uploadItem)"
                  mat-flat-button
                  color="primary"
                  type="button"
                >
                  Update
                  <mat-icon class="cloud-icon" *ngIf="docAIFeatureFlagEnabled">refresh</mat-icon>
                </button>
              </div>
              <span class="sr-only"> Upload document for {{ detail.subTitle }} </span>
              <ngb-alert
                *ngIf="!docAIFeatureFlagEnabled && detail.status"
                [ngClass]="{ 'mt-3': !docAIFeatureFlagEnabled }"
                [type]="getAlertType(detail)"
                [dismissible]="false"
                data-testid="upload-message"
                aria-live="polite"
                >{{ getItemErrorMsg(detail) }}</ngb-alert
              >
              <div [ngClass]="{ 'size-warning': docAIFeatureFlagEnabled }">The file must be 15MB or smaller in size.</div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <dmv-toast
    [setFocus]="true"
    [float]="true"
    [show]="isNotUniqueFile"
    [autohide]="true"
    [delay]="5000"
    (toastHidden)="isNotUniqueFile = false"
    heading="Error"
  >
    <div>
      <ol class="toast-content-error-list">
        <li>You have already selected this file for this upload.</li>
      </ol>
    </div>
  </dmv-toast>
</div>
