import { VehicleInfo } from './vehicle-info';

export class SurveyAnswersModel {
  public coRegistrant = '';
  public coRegistrantHasSameName = '';
  public coRegistrantIdCheck = '';
  public coRegistrantProofOfBirthDocumentIds: any[] = [];
  public coRegistrantProofOfNameDocumentIds: any[] = [];
  public commercialDriverLicense = '';
  public enhanced = '';
  public hasSameName = '';
  public idCheck = '';
  public idRequested = '';
  public licenseRenewal = '';
  public licenseStatus = '';
  public nameChangeStatus = '';
  public oosSalesTax = '';
  public personalUse = '';
  public proofOfBirthDocumentIds: any[] = [];
  public proofOfIncorporationDocumentId: number;
  public proofOfNameDocumentIds: any[] = [];
  public proofOfOwnership = '';
  public proofOfResidencyDocumentIds: any[] = [];
  public registeredUnder = '';
  public salesTaxCollected = '';
  public salesTaxState = '';
  public socialSecurityCardStatus = '';
  public validTitle = '';
  public vehicleAltered = '';
  public vehicleDamage = '';
  public vehicleInfo: VehicleInfo = { permanentCap: undefined, usedFor: undefined, weight: undefined };
  public vehicleIntention = '';
  public vehicleOwnership = '';
  public vehicleStatus = '';
  public vehicleType = '';
}
