<div class="container">
  <div class="banner" tabindex="0">Get Started</div>

  <div class="login-register-container">
    <div class="register-container">
      <h4 tabindex="0">New Users</h4>
      <button class="register-btn" (click)="onRegisterClick()" mat-raised-button color="primary">
        Register<mat-icon>chevron_right</mat-icon>
      </button>
      <p tabindex="0">Create a NY.GOV ID</p>
    </div>
    <mat-divider></mat-divider>
    <div class="login-container">
      <h4 tabindex="0">Returning Users</h4>
      <button class="login-btn" (click)="onLoginClick()" mat-raised-button>Login<mat-icon>chevron_right</mat-icon></button>
      <p tabindex="0">With your NY.GOV ID</p>
    </div>
  </div>
</div>
