import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DocumentTypes } from '@dmv/public/shared/http';
import { Observable } from 'rxjs';
import {
  CorrectionDocMap,
  CorrectionReasonDTO,
  DocumentCorrectionReasonDTO,
  DocumentStatus,
  GetAdditionalInformationResponse,
  TransactionType,
} from '../models';

@Injectable({ providedIn: 'root' })
export class DocumentService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  public SAMPLE_PDF = 'https://storage.googleapis.com/dmv-digital-intake-dev-logos/pdf-default.png';

  constructor(private readonly _http: HttpClient, @Inject('env') private readonly _env) {}

  public setStatus(id: string, status: string): Observable<void> {
    return this._http.post<void>(
      `${this._env.apiUrl}/document/${id}/setStatus`,
      { status },
      {
        headers: this.httpOptions.headers,
      },
    );
  }

  public uploadDocument(
    documentTypeId: number,
    transactionId: number,
    transactionType: string,
    formData: FormData,
    newUpload: boolean,
    uploadAttempts: number,
    coRegistrant?: boolean,
  ) {
    const options = {
      coRegistrant,
      documentTypeId,
      newUpload,
      transactionId,
      transactionType,
      uploadAttempts,
    };
    formData.append('options', JSON.stringify(options));

    return this._http.post(`${this._env.apiUrl}/document`, formData);
  }

  public getImageBlob(id: string | number, transactionType: TransactionType): Observable<Blob> {
    return this._http.get(`${this._env.apiUrl}/document/${id}/view`, { params: { transactionType }, responseType: 'blob' });
  }

  public getTransactionDocumentsBlob(id: string | number, transactionType: TransactionType): Observable<Blob> {
    return this._http.get(`${this._env.apiUrl}/transactions/${id}/documents/view`, { params: { transactionType }, responseType: 'blob' });
  }

  public getDocumentTypes(transactionType: TransactionType): Observable<DocumentTypes[]> {
    return this._http.get<DocumentTypes[]>(`${this._env.apiUrl}/documentTypes`, {
      params: { transactionType },
    });
  }

  public getAllDocumentTypes(): Observable<DocumentTypes[]> {
    return this._http.get<DocumentTypes[]>(`${this._env.apiUrl}/mvr/v2/document/document-types`);
  }

  // this might be deprecated for v2, we will mainly fetch reasons by document type id
  public getCorrectionReasons(): Observable<CorrectionReasonDTO[]> {
    return this._http.get<CorrectionReasonDTO[]>(`${this._env.apiUrl}/correctionReasons`);
  }

  public addDocumentCorrections(id: string, documentCorrectionReasons: DocumentCorrectionReasonDTO[]): Observable<void> {
    return this._http.put<void>(`${this._env.apiUrl}/document/${id}/corrections`, {
      documentCorrectionReasons,
      status: DocumentStatus.NEEDSCORRECTIONS,
    });
  }

  public getDocumentCorrectionReasons(documentId: number): Observable<DocumentCorrectionReasonDTO[]> {
    return this._http.get<DocumentCorrectionReasonDTO[]>(`${this._env.apiUrl}/document/${documentId}/corrections`);
  }

  public deleteCorrectionReasons(correctionReasonIds: number[]): Observable<void> {
    return this._http.delete<void>(`${this._env.apiUrl}/document/corrections?ids=${correctionReasonIds}`);
  }

  public getMultipleDocumentCorrectionReasons(documentIds: number[]): Observable<CorrectionDocMap[]> {
    return this._http.get<CorrectionDocMap[]>(`${this._env.apiUrl}/document/corrections?idList=${documentIds}`);
  }

  public getAdditionInformation(transactionType: string, transactionId?: string): Observable<GetAdditionalInformationResponse> {
    return this._http.get<GetAdditionalInformationResponse>(
      `${this._env.apiUrl}/public/v1/additional-information/request?transactionId=${transactionId}&&transactionType=${transactionType}`,
    );
  }
}
