export enum TransactionStatus {
  APPROVED = 'Approved',
  AUTO_APPROVED = 'AutoApproved',
  CANCELLED = 'Cancelled',
  DRAFT = 'Draft',
  FULFILLED = 'Fulfilled',
  INFO_RECEIVED = 'InfoReceived',
  INFO_REQUESTED = 'InfoRequested',
  INVOICED = 'Invoiced',
  NEW = 'New',
  NO_SHOW = 'NoShow',
  PAID = 'Paid',
  REJECTED = 'Rejected',
  SUBMITTED = 'Submitted',
  SUCCESSFUL_VISIT = 'SuccessfulVisit',
  TITLE_RECEIVED = 'TitleReceived',
  TITLE_NON_COMPLIANT = 'TitleNonCompliant',
  UNASSIGNED = 'Unassigned',
  UNSUCCESSFUL_VISIT = 'UnsuccessfulVisit',
}
