export const DmvOfficeUrl = 'https://dmv.ny.gov/';

export enum TransactionTypeLabel {
  ORIGINAL_REGISTRATION = 'Original Registration',
  ORIGINAL_PERMIT = 'Original Permit',
  LICENSE_RECIPROCITY = 'License Reciprocity',
  ID_UPGRADE = 'Upgrade to Enhanced or REAL ID',
  DOCCS_NDID = 'DOCCS Non Driver ID',
  ORIGINAL_NDID = 'Original Non-Driver ID',
}

export const TransactionDataLabel = {
  AMENDMENT: 'Amendment',
  APPLICANT_NAME: 'Applicant Name',
  APPOINTMENT_DATE: 'Appointment Date',
  APPROVAL_MVR: 'Approval MVR',
  APPROVED_BY: 'Approved By',
  BOAT_FEE: 'Boat Fee',
  CANCEL_AFTER_DATE: 'Cancel After Date',
  CID: 'CID',
  CLIENT_ID: 'Client ID',
  CONTACT_PREFERENCE: 'Contact Preference',
  COUNTY: 'County',
  COUNTY_PRIMARY_USE: 'County of Primary Use',
  DIN: 'DIN',
  DOB: 'Date of Birth',
  DOCUMENT_REQUESTED: 'Document Requested',
  EMAIL: 'Email',
  FACILITY: 'Facility',
  ID_REQUESTED: 'ID Requested',
  LICENSE_TYPE_REQUESTED: 'License Type Requested',
  NAME: 'Name',
  OFFICE: 'Office',
  OUTCOME_MVR: 'Visit Outcome MVR',
  PARTNER: 'Partner',
  PAYMENT_TYPE: 'Payment Type',
  PERMIT_TYPE_REQUESTED: 'Permit Type Requested',
  PHONE: 'Phone',
  PLATES_ISSUED: 'Plates Issued',
  PROCESSED_ON: 'Processed On',
  RELEASE_DATE: 'Release Date',
  RESERVATION_DATE: 'Reservation Date',
  RESIDENCE_COUNTY: 'Residence County',
  SIGNATURE_SLIP_NUMBER: 'Signature Slip Number',
  STATUS: 'Status',
  SUBMITTED_ON: 'Submitted On',
  TRAILER_FEE: 'Trailer Fee',
  TRANSACTION_ID: 'Transaction ID',
  USERNAME: 'Username',
  VEHICLE: 'Vehicle',
  VIN: 'VIN/SIN/HIN#',
};
