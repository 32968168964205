import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DashboardStepData } from '@dmv/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-active-step',
  styleUrls: ['./active-step.component.scss'],
  templateUrl: './active-step.component.html',
})
export class ActiveStepComponent {
  @Input() public step!: DashboardStepData;
  @Output() public readonly submitClick: EventEmitter<void> = new EventEmitter<void>();
}
