<div class="row">
  <div class="col-12">
    <div class="container" data-cy="navStepperHeader">
      <nav>
        <button class="checklist" *ngIf="showHome" (click)="navigateToHome.emit()"><mat-icon>chevron_left</mat-icon>{{ homeLabel }}</button>
        <ul #navListContainer [ngClass]="{ scroll: isMobile }">
          <ng-container *ngFor="let section of sections; let idx = index">
            <li #navListContainerX *ngIf="section.active" data-test="section-item">
              <button
                [ngClass]="{
                  steps: true,
                  selected: section.sectionId === currentSection || (activeParentSection && activeParentSection === section.parentSection),
                  completed: section.status === 'complete'
                }"
                [dmvFocus]="section.sectionId === currentSection"
                [focusOnce]="true"
                [attr.aria-disabled]="section.status === 'complete' || section.sectionId === currentSection"
                [attr.aria-current]="section.sectionId === currentSection ? 'page' : null"
                (click)="navClicked(section.parentSection, section.sectionId, section.status)"
              >
                <span
                  ><span class="subsection-index" *ngIf="showNumber">{{ idx + 1 }}.</span>{{ section.description }}</span
                >
                <mat-icon *ngIf="section.status === 'complete' && section.sectionId !== currentSection" data-testid="completed-step-icon"
                  >check_circle</mat-icon
                >
              </button>
            </li>
          </ng-container>
        </ul>
      </nav>
    </div>
  </div>
</div>
