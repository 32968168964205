export const NOT_USABLE_PROOFS_FOR_ENHANCED_OR_REAL_ID = `
  <span>
  Most proofs are usable for an <a href="https://dmv.ny.gov/driver-license/which-id-right-me" target="_blank">Enhanced or REAL ID
  <span class="open-in-new-icon material-icons">open_in_new</span></a>. Proofs which are not usable are noted below the document name.
  </span>
`;

export const BRING_INTO_APPOINTMENT_FOR_ENHANCED_OR_REAL_ID = `
  <span>You must bring your card to your in-person visit to receive an Enhanced or REAL ID. If you lost your card, you can request a free replacement card from <a href="https://www.ssa.gov/number-card/replace-card" target="_blank">the SSA website
  <span class="open-in-new-icon material-icons">open_in_new</span></a>.
  </span>
`;
