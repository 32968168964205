import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DocAiChecklist } from '@dmv/core';

@Component({
  encapsulation: ViewEncapsulation.None, //!!!REQUIRED for custom tooltip css
  selector: 'lib-docai-checklist-popover',
  styleUrls: ['./doc-ai-checklist.component.scss'],
  templateUrl: './doc-ai-checklist.component.html',
})
export class DocAiChecklistComponent {
  @Input() public documentAiChecklist: DocAiChecklist[];
}
