import { Action, createReducer, on } from '@ngrx/store';
import { NavigationActions } from '../actions';

export const NAV_FEATURE_KEY = 'navigation';

export interface NavigationState {
  transactionUri?: string;
}

export const initialState: NavigationState = {
  transactionUri: null,
};

const workflowReducer = createReducer(
  initialState,
  on(NavigationActions.initializeNavigation, (state, { transactionUri }) => ({ ...state, transactionUri })),
);

export function navigationReducer(state: NavigationState = initialState, action: Action) {
  return workflowReducer(state, action);
}
