import { TransactionType, TransactionUri } from '@dmv/public/shared/http';

/**
 * Map from a transactionUri to a TransactionType.  Used for mapping a workflow
 * to an api endpoint.
 * @param transactionUri
 */
export const fromTransactionUri = (transactionUri: TransactionUri | string): TransactionType => {
  switch (transactionUri) {
    case TransactionUri.EXCHANGE_LICENSE:
      return TransactionType.LICENSE_RECIPROCITY;
    case TransactionUri.LICENSE_CLASS_CHANGE:
      return TransactionType.LICENSE_CLASS_CHANGE;
    case TransactionUri.ORIGINAL_NON_DRIVER_ID:
      return TransactionType.ORIGINAL_NON_DRIVER_ID;
    case TransactionUri.ORIGINAL_PERMIT:
      return TransactionType.PERMIT;
    case TransactionUri.ORIGINAL_REGISTRATION:
      return TransactionType.ORIGINAL_REGISTRATION;
    case TransactionUri.REAL_ID_EDL_UPGRADE:
      return TransactionType.ID_UPGRADE;
    default:
      throw new Error(`Unsupported transaction URI: ${transactionUri}`);
  }
};
