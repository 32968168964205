import { Observable, of } from 'rxjs';
import { BodyType, FuelType, HullMaterial, Propulsion, VehicleColor, VehicleUse } from '../models/vehicle';
import { YesNoTypes } from '../models/yes-no.enum';

export class VehicleInfoUtil {
  public static bodyType$: Observable<BodyType[]> = of([
    BodyType.TWO_DOOR,
    BodyType.FOUR_DOOR,
    BodyType.PICK_UP,
    BodyType.VAN,
    BodyType.CONVERTIBLE,
    BodyType.SUBURBAN_SUB,
    BodyType.TRAILER,
    BodyType.MOTORCYCLE,
    BodyType.OTHER,
  ]);

  public static color$: Observable<VehicleColor[]> = of([
    VehicleColor.BLACK,
    VehicleColor.BLUE,
    VehicleColor.BROWN,
    VehicleColor.GOLD,
    VehicleColor.GRAY,
    VehicleColor.GREEN,
    VehicleColor.MAROON,
    VehicleColor.ORANGE,
    VehicleColor.PINK,
    VehicleColor.PURPLE,
    VehicleColor.RED,
    VehicleColor.TAN,
    VehicleColor.WHITE,
    VehicleColor.YELLOW,
  ]);

  public static vehicleModifiedOptions = [
    {
      id: YesNoTypes.NO,
      label: 'No',
      value: false,
    },
    {
      id: YesNoTypes.YES,
      label: 'Yes',
      value: true,
    },
  ];

  public static fuelType$: Observable<string[]> = of(['Gas', 'Diesel', 'Electric', 'Flex', 'CNG', 'Propane', 'None']);

  public static use$: Observable<VehicleUse[]> = of([
    VehicleUse.PLEASURE,
    VehicleUse.DEALER,
    VehicleUse.RENTAL,
    VehicleUse.COMMERCIAL,
    VehicleUse.GOVERNMENT,
    VehicleUse.EMERGENCY,
  ]);

  public static hullMaterial$: Observable<HullMaterial[]> = of([
    HullMaterial.WOOD,
    HullMaterial.PLASTIC,
    HullMaterial.FIBERGLASS,
    HullMaterial.ALUMINUM,
    HullMaterial.STEEL,
    HullMaterial.INFLATABLE,
    HullMaterial.OTHER,
  ]);

  public static boatFuelType$: Observable<FuelType[]> = of([
    FuelType.GAS,
    FuelType.DIESEL,
    FuelType.ELECTRIC,
    FuelType.OTHER,
    FuelType.NONE,
  ]);

  public static boatPropulsion$: Observable<Propulsion[]> = of([
    Propulsion.OUTBOARD,
    Propulsion.IN_BOARD,
    Propulsion.I_O,
    Propulsion.SAIL,
    Propulsion.OTHER,
  ]);

  public static boatType$: Observable<BodyType[]> = of([BodyType.OPEN, BodyType.HOUSE, BodyType.CABIN, BodyType.OTHER]);

  public static boatUse$: Observable<VehicleUse[]> = of([
    VehicleUse.PLEASURE,
    VehicleUse.MANUFACTURER,
    VehicleUse.GOVERNMENT,
    VehicleUse.LEASED,
    VehicleUse.RENTAL,
    VehicleUse.COMMERCIAL_PASSENGER_UNDER_6,
    VehicleUse.COMMERCIAL_PASSENGER_6_OR_MORE,
    VehicleUse.DEALER,
    VehicleUse.COMMERCIAL,
    VehicleUse.FISHING_COMM,
  ]);
}
