<div data-testid="sectionReviewContainer">
  <dmv-toast
    [setFocus]="true"
    [show]="autoProceed && reviewObjects?.uploads?.documents.length > 0"
    [showIcon]="false"
    type="info"
    heading="Please Note:"
  >
    <ul class="toast-content-list toast-content-list-error">
      <li>You have already satisfied the requirements for this section based on the documents previously uploaded.</li>
    </ul>
  </dmv-toast>
  <div class="upload-header-text" *ngIf="showHeading">Please review your information</div>
  <div>
    <div>
      <dmv-toast
        [setFocus]="true"
        [show]="hasReviewError && !isReviewed && handleDisplayError"
        [showIcon]="true"
        type="error"
        heading="Error:"
      >
        <ol class="toast-content-list toast-content-list-error">
          <li>Please confirm that you have reviewed your information by checking the checkbox below</li>
        </ol>
      </dmv-toast>
      <ng-container *ngFor="let question of reviewObjects?.questions">
        <ng-container *ngIf="question.answer || question.showList">
          <div class="object-title">{{ question.title }}</div>
          <div class="proof-list">
            <div *ngIf="question.answer" data-testid="sectionReviewField">{{ question.answer }}</div>

            <ul *ngIf="question.showList">
              <ng-container *ngFor="let listItem of reviewObjects.uploads?.documents">
                <li *ngIf="listItem.listDisplay">{{ listItem.title }}</li>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </ng-container>

      <dmv-upload-list-review
        [displayDocumentImages]="this.displayDocumentImages"
        [uploads]="reviewObjects?.uploads"
        [docAIFeatureFlagEnabled]="docAIFeatureFlagEnabled"
      ></dmv-upload-list-review>

      <div class="review-checkbox">
        <mat-checkbox
          class="font-weight-normal"
          [checked]="autoProceed && reviewObjects?.uploads?.documents.length > 0"
          (change)="onCheckboxSelection($event.checked)"
          color="primary"
          data-testid="reviewCheckbox"
          name="review-checkbox"
          ><div>
            <div>I have reviewed my submission and wish to confirm my response.</div>
          </div>
        </mat-checkbox>
        <div class="error" *ngIf="hasReviewError && !isReviewed" aria-atomic="true" aria-live="polite">
          Error: Please confirm that you have reviewed your information
        </div>
      </div>
    </div>
  </div>
</div>
