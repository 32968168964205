import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DocAiNotificationError } from '@dmv/public/shared/http';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-doc-ai-notification',
  styleUrls: ['./doc-ai-notification.component.scss'],
  templateUrl: './doc-ai-notification.component.html',
})
export class DocAiNotificationComponent {
  @Input() public error: DocAiNotificationError;

  @Output() public readonly closed = new EventEmitter<void>();

  public closeClicked() {
    this.closed.emit();
  }
}
