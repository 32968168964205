import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadPanelModal } from '../upload-panel-modal.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-app-upload-panel-modal',
  styleUrls: ['./upload-panel-modal.component.scss'],
  templateUrl: './upload-panel-modal.component.html',
})
export class UploadPanelModalComponent {
  constructor(
    private readonly _dialogRef: MatDialogRef<UploadPanelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadPanelModal,
  ) {}

  public closeModal(): void {
    this._dialogRef.close();
  }
}
