import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { AccordionModule, BottomNavigationModule, CommonDirectivesModule, CommonFooterModule, CommonToastModule } from '@dmv/common';
import { PublicSharedFeatureMultiUploadPanelModule } from '@dmv/public/shared/feature-multi-upload-panel';
import { MvWrapperDataService } from '../../services';
import { DisplayErrorModule } from '../display-error/display-error.module';
import { DocumentsReviewComponent } from '../documents-review/documents-review.component';
import { MvWrapperComponent } from './mv-wrapper.component';

@NgModule({
  declarations: [MvWrapperComponent],
  exports: [MvWrapperComponent],
  imports: [
    AccordionModule,
    CommonModule,
    DocumentsReviewComponent,
    MatDividerModule,
    MatIconModule,
    DisplayErrorModule,
    BottomNavigationModule,
    CommonFooterModule,
    CommonToastModule,
    PublicSharedFeatureMultiUploadPanelModule,
    CommonDirectivesModule,
    MatButtonModule,
  ],
  providers: [MvWrapperDataService],
})
export class MvWrapperModule {}
