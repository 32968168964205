export interface FAQ {
  question: string;
  answer: string;
}

export const FAQ_OP_TEST: FAQ[] = [
  {
    answer:
      'This is a security feature of the online permit test, so that DMV is able to verify that the person who took the permit test is the same person appearing at a DMV office and receiving the permit document.',
    question: 'Why is my picture required to take a permit test?',
  },
  {
    answer:
      'Individuals who do not allow their picture to be taken will not be able to take the permit test online and must make a reservation to take the permit test in a DMV office.',
    question: 'What if I do not allow my picture to be taken?',
  },
  {
    answer: 'No, the online permit test does not record any audio when individuals are taking the test.',
    question: 'Is there any audio recorded while I am taking the test?',
  },
  {
    answer: 'No, the online permit test does not record any video when individuals are taking the test.',
    question: 'Is there any video recorded while I am taking the test?',
  },
  {
    answer:
      'DMV will use bulletin-images to verify that the person who took the permit test is the same person appearing at a DMV office and receiving the permit document. Pictures may also be used when there is an allegation of deception, substitution, identity theft, or other type of fraud. Pictures may also be compared to any previous or future bulletin-images of the applicant from previous test sessions or in the records of DMV.',
    question: 'How will my picture be used by the DMV?',
  },
  {
    answer:
      'Regardless of when you are issued your permit document, your test information and bulletin-images will be stored for two years from the date you take your permit test.',
    question: 'How long will my image be stored with DMV?',
  },
  {
    answer: 'No, the pictures taken during the permit test will not be available under FOIL.',
    question: 'Will my picture be available under FOIL?',
  },
  {
    answer: 'Yes, your picture will be available in response to court orders or subpoenas.',
    question: 'Will my picture be available in response to court orders or subpoenas?',
  },
  {
    answer: 'Pictures are stored in a secure location within the Continental United States.',
    question: 'Where will the pictures be stored?',
  },
];
