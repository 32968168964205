import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import { MatIconModule } from '@angular/material/icon';
import { IdComparisonComponent } from './id-comparison.component';

@NgModule({
  declarations: [IdComparisonComponent],
  exports: [IdComparisonComponent],
  imports: [A11yModule, CommonModule, MatIconModule],
})
export class IdComparisonModule {}
