import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DocAiChecklist } from '@dmv/core';
import { RolePermissionsService } from '@libs/role-permissions';
import { take } from 'rxjs/operators';

const delayInMS = 3000;

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"',
  },
  selector: 'lib-pdf-viewer',
  styleUrls: ['./pdf-viewer-wrapper.component.scss'],
  templateUrl: './pdf-viewer-wrapper.component.html',
})
export class PdfViewerWrapperComponent implements OnInit {
  @Input() public pdfSrc: string;
  @Input() public title: string;
  @Input() public errorTitle: string;
  @Input() public documentAiChecklist: DocAiChecklist[];
  @Input() public close: () => void;
  @ViewChild('pdfViewer') public pdfViewer;

  public loading = true;
  public error = false;
  public showDownloadButton = false;
  public showPrintButton = false;

  constructor(private readonly _permissionService: RolePermissionsService) {}

  public ngOnInit(): void {
    this._permissionService
      .hasPermission$('document-detail-view-download-from-document-modal')
      .pipe(take(1))
      .subscribe(hasPermission => {
        this.showDownloadButton = hasPermission;
      });
    this._permissionService
      .hasPermission$('document-detail-view-print-from-document-modal')
      .pipe(take(1))
      .subscribe(hasPermission => {
        this.showPrintButton = hasPermission;
      });
  }

  public pageLoaded(): void {
    this.addAriaToButtons();
  }

  public addAriaToButtons(): void {
    setTimeout(() => {
      const allButtons = document.querySelectorAll('button');
      allButtons.forEach(element => {
        if (element.id === 'zoomIn' || element.id === 'zoomOut') {
          element.setAttribute('aria-label', element.title);
        }
      });
    }, delayInMS);
  }

  public setPdfSrc(res) {
    this.pdfViewer.src = res;
    this.loading = false;
  }
}
