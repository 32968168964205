import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollUtils {
  public scrollToTop(focusElementID?: string) {
    setTimeout(() => window.scrollTo(0, 0), 100);
    if (focusElementID) {
      this._focusSkipElement(focusElementID);
    }
  }

  private _focusSkipElement(focusElementID: string) {
    document.getElementById(focusElementID)?.focus();
  }
}
