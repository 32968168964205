export interface UsState {
  name: string;
  abbreviation: string;
}

export const US_TERRITORIES: UsState[] = [
  {
    abbreviation: 'AS',
    name: 'American Samoa',
  },
  {
    abbreviation: 'GU',
    name: 'Guam',
  },
  {
    abbreviation: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    abbreviation: 'PR',
    name: 'Puerto Rico',
  },
  {
    abbreviation: 'VI',
    name: 'U.S. Virgin Islands',
  },
  {
    abbreviation: 'DC',
    name: 'District of Columbia',
  },
];

export const US_STATES: UsState[] = [
  {
    abbreviation: 'AL',
    name: 'Alabama',
  },
  {
    abbreviation: 'AK',
    name: 'Alaska',
  },
  {
    abbreviation: 'AZ',
    name: 'Arizona',
  },
  {
    abbreviation: 'AR',
    name: 'Arkansas',
  },
  {
    abbreviation: 'CA',
    name: 'California',
  },
  {
    abbreviation: 'CO',
    name: 'Colorado',
  },
  {
    abbreviation: 'CT',
    name: 'Connecticut',
  },
  {
    abbreviation: 'DE',
    name: 'Delaware',
  },
  {
    abbreviation: 'DC',
    name: 'District of Columbia',
  },
  {
    abbreviation: 'FL',
    name: 'Florida',
  },
  {
    abbreviation: 'GA',
    name: 'Georgia',
  },
  {
    abbreviation: 'HI',
    name: 'Hawaii',
  },
  {
    abbreviation: 'ID',
    name: 'Idaho',
  },
  {
    abbreviation: 'IL',
    name: 'Illinois',
  },
  {
    abbreviation: 'IN',
    name: 'Indiana',
  },
  {
    abbreviation: 'IA',
    name: 'Iowa',
  },
  {
    abbreviation: 'KS',
    name: 'Kansas',
  },
  {
    abbreviation: 'KY',
    name: 'Kentucky',
  },
  {
    abbreviation: 'LA',
    name: 'Louisiana',
  },
  {
    abbreviation: 'ME',
    name: 'Maine',
  },
  {
    abbreviation: 'MD',
    name: 'Maryland',
  },
  {
    abbreviation: 'MA',
    name: 'Massachusetts',
  },
  {
    abbreviation: 'MI',
    name: 'Michigan',
  },
  {
    abbreviation: 'MN',
    name: 'Minnesota',
  },
  {
    abbreviation: 'MS',
    name: 'Mississippi',
  },
  {
    abbreviation: 'MO',
    name: 'Missouri',
  },
  {
    abbreviation: 'MT',
    name: 'Montana',
  },
  {
    abbreviation: 'NE',
    name: 'Nebraska',
  },
  {
    abbreviation: 'NV',
    name: 'Nevada',
  },
  {
    abbreviation: 'NH',
    name: 'New Hampshire',
  },
  {
    abbreviation: 'NJ',
    name: 'New Jersey',
  },
  {
    abbreviation: 'NM',
    name: 'New Mexico',
  },
  {
    abbreviation: 'NY',
    name: 'New York',
  },
  {
    abbreviation: 'NC',
    name: 'North Carolina',
  },
  {
    abbreviation: 'ND',
    name: 'North Dakota',
  },
  {
    abbreviation: 'OH',
    name: 'Ohio',
  },
  {
    abbreviation: 'OK',
    name: 'Oklahoma',
  },
  {
    abbreviation: 'OR',
    name: 'Oregon',
  },
  {
    abbreviation: 'PA',
    name: 'Pennsylvania',
  },
  {
    abbreviation: 'PR',
    name: 'Puerto Rico',
  },
  {
    abbreviation: 'RI',
    name: 'Rhode Island',
  },
  {
    abbreviation: 'SC',
    name: 'South Carolina',
  },
  {
    abbreviation: 'SD',
    name: 'South Dakota',
  },
  {
    abbreviation: 'TN',
    name: 'Tennessee',
  },
  {
    abbreviation: 'TX',
    name: 'Texas',
  },
  {
    abbreviation: 'UT',
    name: 'Utah',
  },
  {
    abbreviation: 'VT',
    name: 'Vermont',
  },
  {
    abbreviation: 'VA',
    name: 'Virginia',
  },
  {
    abbreviation: 'WA',
    name: 'Washington',
  },
  {
    abbreviation: 'WV',
    name: 'West Virginia',
  },
  {
    abbreviation: 'WI',
    name: 'Wisconsin',
  },
  {
    abbreviation: 'WY',
    name: 'Wyoming',
  },
];

export const CANADIAN_PROVINCES: UsState[] = [
  {
    abbreviation: 'ON',
    name: 'Ontario',
  },
  {
    abbreviation: 'QC',
    name: 'Quebec',
  },
  {
    abbreviation: 'NS',
    name: 'Nova Scotia',
  },
  {
    abbreviation: 'NB',
    name: 'New Brunswick',
  },
  {
    abbreviation: 'MB',
    name: 'Manitoba',
  },
  {
    abbreviation: 'BC',
    name: 'British Columbia',
  },
  {
    abbreviation: 'PE',
    name: 'Prince Edward Island',
  },
  {
    abbreviation: 'SK',
    name: 'Saskatchewan',
  },
  {
    abbreviation: 'AB',
    name: 'Alberta',
  },
  {
    abbreviation: 'NL',
    name: 'Newfoundland and Labrador',
  },
];

export const CANADIAN_TERRITORIES: UsState[] = [
  {
    abbreviation: 'NT',
    name: 'Northwest Territories',
  },
  {
    abbreviation: 'YT',
    name: 'Yukon',
  },
  {
    abbreviation: 'NU',
    name: 'Nunavut',
  },
];

export const ALL_STATES_TERRITORIES: UsState[] = Array.from(
  [...US_STATES, ...US_TERRITORIES, ...CANADIAN_PROVINCES, ...CANADIAN_TERRITORIES].reduce((m, t) => m.set(t.name, t), new Map()).values(),
);

export const sortedStatesAndTerritories: UsState[] = [...ALL_STATES_TERRITORIES.sort((a, b) => a.name.localeCompare(b.name))];
