import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'lib-spinner',
  styleUrls: ['./spinner.component.scss'],
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent implements OnChanges {
  @Input() public fullScreen = false;
  @Input() public showSpinner = false;
  @Input() public loadingText = 'Loading, please wait...';
  @Input() public name = 'primary';
  @Input() public template: string = null;

  @Input() public disableAnimation = false;
  @Input() public size: SpinnerSize = SpinnerSize.DEFAULT;
  @Input() public color: SpinnerColor = SpinnerColor.DEFAULT;
  @Input() public bdColor: BackdropColor = BackdropColor.DEFAULT;

  public type = 'ball-clip-rotate'; // more fonts here https://labs.danielcardoso.net/load-awesome/animations.html

  constructor(private readonly _spinner: NgxSpinnerService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.showSpinner?.currentValue) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
  }

  public getValidBackdropColor() {
    return BackdropColor[this.bdColor] || BackdropColor.DEFAULT;
  }

  public getValidColor() {
    return SpinnerColor[this.color] || SpinnerColor.DEFAULT;
  }

  public getValidSize() {
    return SpinnerSize[this.size] || SpinnerSize.DEFAULT;
  }
}

enum SpinnerSize {
  DEFAULT = 'default',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

enum SpinnerColor {
  DEFAULT = '#fff', // white
  DMVBLUE = '#002d72', // dmv blue
}

enum BackdropColor {
  DEFAULT = '#eaeaea', // light gray
  TRANSPARENT = 'transparent',
}
