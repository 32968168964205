import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CommonDirectivesModule } from '../../directives/common-directives.module';

import { BottomNavigationComponent } from './bottom-navigation.component';

@NgModule({
  declarations: [BottomNavigationComponent],
  exports: [BottomNavigationComponent],
  imports: [CommonModule, CommonDirectivesModule, MatButtonModule],
})
export class BottomNavigationModule {}
