import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { FeatureFlagService } from '@libs/feature-flag';
import { switchMap, tap } from 'rxjs/operators';
import { Environment } from './types/environment.type';
import { DecisionResponse, DEFAULT_DECISION, DEVICE_RISK_OUTCOME_KEY } from './models/decision';
import { DeviceRiskSdk } from './device-risk-sdk';

@Injectable({
  providedIn: 'root',
})
export class DecisionService {
  public destroy$: Subject<boolean> = new Subject<boolean>();

  // limit device risk checks to at most once every 3.6 seconds, matching Socure outcome TTL
  private readonly _deviceSessionLength: number = 3600;

  constructor(
    private readonly _http: HttpClient,
    @Inject('env') private readonly _env: Environment,
    private readonly _featureFlagService: FeatureFlagService,
    private readonly _deviceRiskSdk: DeviceRiskSdk,
  ) {}

  public getDeviceSessionId$(): Observable<string> {
    return this._deviceRiskSdk.loadDeviceRiskScript$().pipe(switchMap(() => this._deviceRiskSdk.getDeviceSessionId$()));
  }

  public getDeviceRiskOutcome$(context: string): Observable<DecisionResponse> {
    return combineLatest([
      this._featureFlagService.isFeatureFlagEnabled$('public-socure-device-unmask'),
      this._featureFlagService.isFeatureFlagEnabled$('public-socure-device-unmask-enforced'),
    ]).pipe(
      switchMap(([deviceUnmaskEnabled, deviceUnmaskEnforcedEnabled]) => {
        // Logic based on flags
        if (deviceUnmaskEnabled) {
          const storedOutcome = sessionStorage.getItem(DEVICE_RISK_OUTCOME_KEY);
          if (storedOutcome) {
            const outcome = JSON.parse(atob(storedOutcome));
            if (this._isNotExpired(outcome.expiration)) {
              return of(outcome);
            }
          }

          return this._deviceRiskSdk.loadDeviceRiskScript$().pipe(
            switchMap(() => this._deviceRiskSdk.getDeviceSessionId$()),
            switchMap((deviceSessionId: string) => this.getDeviceDecision(deviceSessionId, context)),
            switchMap((socureOutcome: DecisionResponse) => of(deviceUnmaskEnforcedEnabled ? socureOutcome : DEFAULT_DECISION)),
            tap(socureOutcome => {
              sessionStorage.setItem(
                DEVICE_RISK_OUTCOME_KEY,
                btoa(JSON.stringify({ ...socureOutcome, expiration: Date.now() + this._deviceSessionLength })),
              );
            }),
          );
        } else {
          // If device unmasking is not enabled, return default outcome
          return of(DEFAULT_DECISION);
        }
      }),
    );
  }

  public getDeviceRiskOutcome(): string {
    let outcome = 'UNKNOWN';
    const storedOutcome = sessionStorage.getItem(DEVICE_RISK_OUTCOME_KEY);
    if (storedOutcome) {
      const parsedOutcome = JSON.parse(atob(storedOutcome));
      if (this._isNotExpired(parsedOutcome.expiration)) {
        outcome = parsedOutcome.outcome;
      }
    }

    return outcome;
  }

  public clearDeviceRiskOutcome(): void {
    sessionStorage.removeItem(DEVICE_RISK_OUTCOME_KEY);
  }

  // REST call to Socure, proxied via DI back-end
  public getDeviceDecision(deviceSessionId: string, context: string): Observable<DecisionResponse> {
    return this._http.get<DecisionResponse>(`${this._env.apiUrl}/decision/device?id=${deviceSessionId}&context=${context}`);
  }

  private _isNotExpired(timestamp: number): boolean {
    return Date.now() - timestamp < 0;
  }
}
