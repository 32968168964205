export class BaseFlowSteps {
  public static data = [
    {
      status: 'active',
      stepNumber: 1,
      title: 'ID and license type',
    },
    {
      status: '',
      stepNumber: 2,
      title: 'Personal info',
    },
    {
      status: '',
      stepNumber: 3,
      title: 'Required documents',
    },
    {
      status: '',
      stepNumber: 4,
      title: 'Upload documents',
    },
    {
      status: '',
      stepNumber: 5,
      title: 'Schedule Reservation',
    },
  ];
}
