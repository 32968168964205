<section>
  <div class="container">
    <div>
      <div>
        <h4 tabindex="0">Oops, we encountered a problem.</h4>
        <br />
        <h5>Possible reasons may include:</h5>
        <ul>
          <li>Your web browser (Google, Bing, Safari) is out of date</li>
          <li>Your online address is concealed</li>
        </ul>
      </div>
      <div>
        <h5>Consider the following:</h5>
        <ul>
          <li>Try again with a different device (phone or computer)</li>
          <li>Try again with a different web browser</li>
          <li>Try from a different location or internet connection</li>
          <li>Temporarily disable your VPN (Virtual Private Network software)</li>
        </ul>
      </div>
      <div class="continue-msg">
        <h5>When ready, click Continue to try again.</h5>
      </div>
    </div>
  </div>
</section>
<dmv-bottom-navigation
  [showContinue]="true"
  [showPrevious]="false"
  [showSubmit]="false"
  [submitDisabled]="false"
  (continueClick)="onContinueClick()"
>
</dmv-bottom-navigation>
