import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { CustomToastComponent } from './toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly _durationInSeconds = 5;

  constructor(private readonly _toast: MatSnackBar) {}

  public showToast(message: string) {
    this.showCustomToast(message);
  }

  public showCustomToast(message: string) {
    this.showCustomToastWithLocation(message, 'bottom');
  }

  public showCustomToastWithLocation(message: string, verticalLocation: MatSnackBarVerticalPosition) {
    this._toast.openFromComponent(CustomToastComponent, {
      data: message,
      duration: this._durationInSeconds * 1000,
      verticalPosition: verticalLocation,
    });
  }
}
