import { Environment, WindowWithEnvironment } from '@dmv/common';

declare let window: WindowWithEnvironment;

export const environment: Environment = {
  apiUrl: '',
  authenticationConfiguration: {
    okta: {
      clientId: '',
      issuer: '',
      postLogoutRedirectUri: '',
      redirectUri: '',
      scopes: [''],
      state: 'originalUri',
      tokenManager: {
        storage: '',
      },
    },
  },
  barcodeUrlPrefix: '',
  eventsConfiguration: {
    googleTagManager: {
      id: '',
    },
  },
  featureFlagConfiguration: {
    launchDarkly: {
      apiKey: '',
    },
  },
  idUpgradeQmaticOffice: '',
  production: true,
  qmaticServiceIds: {
    changeNameOnId: '',
    exchangeOutOfStateLicense: '',
    idUpgrade: '',
    nonDriverId: '',
    permitId: '',
  },
  registerRedirectUrls: {
    licenseReciprocity: '',
    originalNdid: '',
    originalPermit: '',
    originalRegistration: '',
    realId: '',
  },
  smsReplyPhone: '',
  socureSdkKey_cert: 'c1e997dd-af10-49ea-969a-5d09a156b99b',
  socureSdkKey_prod: 'bb82c1e1-d404-4c69-867b-afd32b377e98',
  socureSdkKey_sandbox: '4b64eee5-c677-4654-bba4-83d353b1cc8e',
  ...window.environment, // must be last
};
