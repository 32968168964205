import { animate, style, transition, trigger } from '@angular/animations';

export const slideInUp = trigger('slideInUp', [
  transition(':enter', [
    style({
      transform: 'translateY(100%)',
    }),
    animate(
      '150ms',
      style({
        display: 'flex',
        transform: 'translateY(0)',
      }),
    ),
  ]),
]);

export const slideInDown = trigger('slideInDown', [
  transition(':enter', [
    style({
      transform: 'translateY(-100%)',
    }),
    animate(
      '150ms',
      style({
        display: 'flex',
        transform: 'translateY(0)',
      }),
    ),
  ]),
]);
