import { AdditionalFormInformationFeatureState } from '@dmv/public/shared/http';

export interface AdditionalFormInformationRequest {
  addToDonateLifeRegistry: boolean;
  cdlDrivingType: string;
  conditionWorsened?: boolean;
  disqualifiedFromCdl: boolean;
  donateToLifeFund: boolean;
  hadRecentOosCdl: boolean;
  idType: string;
  isVeteran: boolean;
  licenseInvalidated: boolean;
  licenseRestored: boolean;
  lostLimbOrEye: boolean;
  lostSinceLastLicense?: boolean;
  optOutSelectiveService: boolean;
  recentOosCdlState?: string;
  receivedTreatment: boolean;
  registerToVote: string;
  sectionId?: string;
  teensProgramParentId?: string;
  usesDrivingAid: boolean;
}

export const buildAdditionalFormInformation = (
  afInfo: AdditionalFormInformationFeatureState,
  sectionId: string,
): AdditionalFormInformationRequest => {
  const recentOosCdlState =
    afInfo.additionalFormInformationCDL.value.anotherState === true
      ? afInfo.additionalFormInformationCDL.value.anotherStateList['value'].join(',')
      : null;
  const registerToVote = afInfo.additionalFormInformationRegistration.value.voterRegistrationYes
    ? 'Checked'
    : afInfo.additionalFormInformationRegistration.value.voterRegistrationNo
    ? 'Skipped'
    : 'Unchecked';

  return {
    addToDonateLifeRegistry: afInfo.additionalFormInformationRegistration.value.organDonationRegistryYes,
    cdlDrivingType: afInfo.additionalFormInformationCDL.value.drivingType,
    conditionWorsened: afInfo.additionalFormInformationLicense.controls.lostLimbWorse
      ? afInfo.additionalFormInformationLicense.value.lostLimbWorse
      : null,
    disqualifiedFromCdl: afInfo.additionalFormInformationCDL.value.disqualification,
    donateToLifeFund: afInfo.additionalFormInformationRegistration.value.organDonationRegistryDonation,
    hadRecentOosCdl: afInfo.additionalFormInformationCDL.value.anotherState,
    idType: afInfo.additionalFormInformationIDType.value.idType,
    isVeteran: afInfo.additionalFormInformationRegistration.value.isVeteran,
    licenseInvalidated: afInfo.additionalFormInformationLicense.value.licenseRevoked,
    licenseRestored: afInfo.additionalFormInformationLicense.controls.licenseRevokedRestored
      ? afInfo.additionalFormInformationLicense.value.licenseRevokedRestored
      : null,
    lostLimbOrEye: afInfo.additionalFormInformationLicense.value.lostLimb,
    lostSinceLastLicense: afInfo.additionalFormInformationLicense.controls.lostLimbRenew
      ? afInfo.additionalFormInformationLicense.value.lostLimbRenew
      : null,
    optOutSelectiveService: afInfo.additionalFormInformationRegistration.controls.selectiveService
      ? afInfo.additionalFormInformationRegistration.value.selectiveService
      : null,
    receivedTreatment: afInfo.additionalFormInformationLicense.value.receiveTreatment,
    recentOosCdlState,
    registerToVote,
    sectionId,
    teensProgramParentId: afInfo.additionalFormInformationRegistration.controls.teensProgramCID
      ? afInfo.additionalFormInformationRegistration.value.teensProgramCID
      : null,
    usesDrivingAid: afInfo.additionalFormInformationLicense.value.hearingAid,
  };
};
