import { BaseTransactionStatus } from './transaction.model';

export interface HasTransactionGuardData {
  noTransactionRedirectUrl: string;
  failedStatusRedirectUrl: string;
  unauthorizedStatusRedirectUrl: string | boolean;
  failRedirectUrl: string | boolean;
  passableStatus?: BaseTransactionStatus[];
}

export enum HasTransactionStatus {
  UNAUTHORIZED = 'unauthorized',
  HAS_TRANSACTION = 'hasTransaction',
  NO_TRANSACTION = 'noTransaction',
}
