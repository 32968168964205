import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCounty from '../reducers/county.reducer';

export const selectCountiesState = createFeatureSelector<fromCounty.CountyState>(fromCounty.COUNTIES_FEATURE_KEY);

export const getCounties = createSelector(selectCountiesState, state => {
  return fromCounty.selectAllCounties(state);
});
export const getCountyEntities = createSelector(selectCountiesState, fromCounty.selectCountyEntities);

export const selectSelectedId = createSelector(selectCountiesState, state => state.selectedId);
export const selectSelectedCounty = createSelector(
  selectCountiesState,
  selectSelectedId,
  (state, selectedId) => state.entities[selectedId],
);
export const selectCountyOffices = createSelector(selectCountiesState, state => state.offices);
export const selectJurisdictions = createSelector(selectCountiesState, state => state.jurisdictions);
