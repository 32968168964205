import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LaunchDarklyFeatureFlagAdapter } from './adapters/launch-darkly-feature-flag.adapter';
import { FeatureFlagDisabledDirective } from './feature-flag-disabled/feature-flag-disabled.directive';
import { FeatureFlagAdapter } from './feature-flag.adapter';
import { FeatureFlagGuard } from './feature-flag.guard';
import { FeatureFlagDirective } from './feature-flag/feature-flag.directive';
import { ResidentGuard } from './resident.guard';

@NgModule({
  declarations: [FeatureFlagDirective, FeatureFlagDisabledDirective],
  exports: [FeatureFlagDirective, FeatureFlagDisabledDirective],
  imports: [CommonModule],
})
export class FeatureFlagModule {
  public static useLaunchDarkly(): ModuleWithProviders<FeatureFlagModule> {
    return {
      ngModule: FeatureFlagModule,
      providers: [
        {
          provide: FeatureFlagAdapter,
          useClass: LaunchDarklyFeatureFlagAdapter,
        },
        FeatureFlagGuard,
        ResidentGuard,
      ],
    };
  }
}
