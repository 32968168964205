// eslint-disable-next-line max-len
/*eslint no-trailing-spaces: "error"*/

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { PaymentService } from '@dmv/core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-payment-confirmation',
  styleUrls: ['./payment-confirmation.component.scss'],
  templateUrl: './payment-confirmation.component.html',
})
export class PaymentConfirmationComponent implements OnInit {
  constructor(
    private readonly _renderer2: Renderer2,
    private readonly _paymentservice: PaymentService,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  public ngOnInit(): void {
    const s = this._renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://api.demo.convergepay.com/hosted-payments/Checkout.js';
    s.text = ``;
    this._renderer2.appendChild(this._document.body, s);
  }

  public paymentToken() {
    this._paymentservice.createToken().then((res: unknown) => {
      if (res) {
        // eslint-disable-next-line no-console
        console.log(res);
      }
    });
  }
}
