import { AnswerPrompt, QuestionImpl, StepType } from '@dmv/public/shared/http';

export const checkAutoAdvance = (questions: QuestionImpl[], isReset: boolean, initialStep: StepType, autoStep: StepType): StepType => {
  let activeStep: StepType = initialStep;
  questions.forEach(question => {
    if (question.hasPreviouslyUsedUpload && !isReset) {
      const reuseAnswers: AnswerPrompt[] = question.answerPrompts.filter(
        answer => answer.previouslyUsedUpload && question.answers.includes(answer.id),
      );
      if (question.type === 'radio' && reuseAnswers.length === 1) {
        activeStep = autoStep;
      } else if (
        question.answers.filter(answer => !reuseAnswers.map(answer => answer.id).includes(answer)).length === 0 &&
        (question.pointsThreshold
          ? reuseAnswers.reduce((partialSum, a) => partialSum + a.metadata.points, 0) >= question.pointsThreshold
          : reuseAnswers.length >= question.minPoints)
      ) {
        activeStep = autoStep;
      }
    }
  });

  return activeStep;
};
