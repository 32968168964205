/* eslint-disable @typescript-eslint/member-ordering */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  host: {
    '[class.focusedOnce]': 'focusedOnce',
    '[class.focused]': 'dmvFocus',
  },
  selector: '[dmvFocus]',
})
export class FocusDirective implements OnInit {
  public focusedOnce = false;

  private _focus = false;
  private _focusOnce = false;
  private _focusEventName = '';
  constructor(private readonly _elementRef: ElementRef) {}

  public get focusEventName(): string {
    return this._focusEventName;
  }
  @Input()
  public set focusEventName(focusEventName: string) {
    this._focusEventName = focusEventName;
  }
  public get dmvFocus(): boolean {
    return this._focus;
  }
  @Input()
  public set dmvFocus(f: boolean) {
    const value = coerceBooleanProperty(f);
    this._focus = value;
  }
  public get focusOnce(): boolean {
    return this._focusOnce;
  }
  @Input()
  public set focusOnce(f: boolean) {
    const value = coerceBooleanProperty(f);
    this._focusOnce = value;
  }

  public ngOnInit(): void {
    // Set focus on the element if the focus attribute is set to true
    if (this._focus && this.focusOnce && !this._elementRef.nativeElement.classList.contains('focusedOnce')) {
      this.focusedOnce = true;
      this._doFocus(true);
    }

    // Listen for focus event and set focus on the element
    document.addEventListener(this._focusEventName, _ => {
      if (this._focus) {
        this._doFocus();
      }
    });
  }

  private _doFocus(refocusOnSkipLink = false): void {
    setTimeout(() => {
      this._elementRef.nativeElement.focus();

      if (refocusOnSkipLink) {
        const docBody = document.querySelector<HTMLInputElement>('a.main-skip-link');
        (docBody.previousElementSibling as HTMLElement).focus();
      }
    }, 100);
  }
}
