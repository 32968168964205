import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-bottom-navigation',
  styleUrls: ['./bottom-navigation.component.scss'],
  templateUrl: './bottom-navigation.component.html',
})
export class BottomNavigationComponent {
  @Input() public showContinue = true;
  @Input() public showPrevious = false;
  @Input() public showSubmit = false;
  @Input() public set continueText(txt: string) {
    txt !== null && txt.length > 0 && (this._continueText = txt);
  }
  public get continueText() {
    return this._continueText;
  }
  @Input() public set previousText(txt: string) {
    txt !== null && txt.length > 0 && (this._previousText = txt);
  }
  public get previousText() {
    return this._previousText;
  }
  @Input() public set submitText(txt: string) {
    txt !== null && txt.length > 0 && (this._submitText = txt);
  }
  public get submitText() {
    return this._submitText;
  }

  @Input() public showLeftText = false;
  @Input() public submitDisabled = false;
  @Output() public readonly continueClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly previousClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly submitClick: EventEmitter<void> = new EventEmitter<void>();

  private _continueText = 'Continue';
  private _previousText = 'Previous';
  private _submitText = 'Submit';
}
