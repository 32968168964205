export enum DashboardCompletedStepTitle {
  SUBMITTED = 'Pre-Screening Submitted',
  APPLICATION_APPROVAL = 'Submission Pre-Approval',
  ADDITIONAL_REQUEST_SUBMITTED = 'Additional Request Submitted',
  APPLICATION_APPROVED = 'Submission Pre-Approved',
  APPOINTMENT_SCHEDULED = 'Appointment Scheduled',
  ONLINE_TEST_COMPLETED = 'Online Test Completed',
  PAY_INVOICE = 'Pay Your Invoice',
  MAKE_RESERVATION = 'Make Your Reservation',
}
