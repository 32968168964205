import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule } from '@dmv/common';
import { DashboardDocumentTypesComponent } from './dashboard-document-types.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DashboardDocumentTypesComponent],
  exports: [DashboardDocumentTypesComponent],
  imports: [AccordionModule, CommonModule, MatIconModule],
})
export class CommonDashboardDocumentTypesModule {}
