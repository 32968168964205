import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { PublicCommonModule } from '../../../public-common.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-communication-preference',
  standalone: true,
  styleUrls: ['./communication-preference.component.scss'],
  templateUrl: './communication-preference.component.html',
  imports: [CommonModule, MatRadioModule, MatFormFieldModule, PublicCommonModule],
})
export class CommunicationPreferenceComponent implements OnInit {
  @Output() public readonly selectedValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  public ngOnInit(): void {
    this.selectedValue.emit(false);
  }

  public radioChange(radioButton: MatRadioChange): void {
    this.selectedValue.emit(radioButton.value);
  }
}
