import { ElementRef, QueryList } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { ConfirmationModal, Question } from '@dmv/public/shared/http';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

export function getRequiredDocumentIds(questions: Question[]): number[] {
  let requiredDocumentIds: number[] = [];
  for (const question of questions) {
    for (const answerPrompt of question.answerPrompts) {
      if (question.answers.includes(answerPrompt.value)) {
        // Mapping and parsing the ids ensures that they are numeric
        requiredDocumentIds = [...requiredDocumentIds, ...answerPrompt.documentIds.map(id => parseInt(`${id}`))];
      }
    }
  }

  return requiredDocumentIds;
}

export function isSectionChanged(prev, curr): boolean {
  let changed = false;
  prev.forEach((section, index) => {
    if (section.status !== curr[index].status) {
      changed = true;
    }
  });

  return !changed;
}

/**
 * Builds the data for the confirmation modal
 *
 * Note: Intentional non-usage of CountyModel to avoid this project depending on common
 *
 * @param countyList an array of counties for the user to select from unless showCounties was false in which case it will be null
 */
export function dialogRefWithTitle(
  countyList: Array<{
    id?: number;
    name?: string;
    stateCounty?: 0 | 1;
    enabled?: boolean;
    stateRun?: boolean;
  }> | null, // Do not replace with CountyModel from common. We may need a Public CountyModel model to avoid increasing dependencies
) {
  const countyInformation = countyList && countyList.length > 0 ? ` Please select the county for your visit.` : ``;
  const afterSubmittingLabel = `After submitting, you will need to visit a DMV office to complete your transaction.${countyInformation}`;

  return {
    ariaLabel: afterSubmittingLabel,
    autoFocus: false,
    data: {
      confirmLabel: 'Submit pre-screening',
      content: afterSubmittingLabel,
      countyList,
      showConfirm: true,
      title: 'You are almost done!',
    } as ConfirmationModal,
    width: '500px',
  };
}

export function handleClickShowMore(
  showMore: number,
  question: Question,
  changes: Observable<QueryList<ElementRef | MatCheckbox>>,
  maxInitialQuestions: number,
): void {
  // increases the number of times that the show more button has been pressed
  // one time will show all the id upgrade eligible documents
  // two times will show all the documents regardless
  changes
    .pipe(
      tap((documentListResults: QueryList<ElementRef | MatCheckbox>) => {
        if (documentListResults.length > maxInitialQuestions) {
          const active = documentListResults.toArray();
          if (showMore < 2) {
            // sets the 5th document as selected after the first time more docs are requested
            const focusElement = active[maxInitialQuestions];
            setFocus(focusElement);
          }
        }
      }),
      take(1),
    )
    .subscribe();
}

function setFocus(focusElement: ElementRef | MatCheckbox): void {
  if (focusElement instanceof ElementRef) {
    focusElement.nativeElement.focus();
  } else if (focusElement instanceof MatCheckbox) {
    focusElement.focus();
  }
}

export function hasIneligiblePromptCheck(answerPrompts): number {
  const ineligiblePrompts = answerPrompts.filter(prompt => !prompt.metadata?.idUpgradeEligible);

  return answerPrompts.length === ineligiblePrompts.length ? 0 : ineligiblePrompts.length;
}

export const numberToEnglish = (n: number): string => {
  const numbers: Record<number, string> = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
  };

  return numbers[n];
};
