import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FindTransactionsParams, Owner, RegistrationListDTO, RegistrationModel, SearchResult, UpdateHoldModel, Vehicle } from '../models';
import { ValidateUtil } from '../utils';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  public transactionPath = 'registration';

  constructor(private readonly _http: HttpClient, @Inject('env') private readonly _env) {}

  public findRegistrations(paramsToValidate: FindTransactionsParams): Observable<SearchResult<RegistrationListDTO>> {
    return this._http.get<SearchResult<RegistrationListDTO>>(`${this._env.apiUrl}/registrations`, {
      params: ValidateUtil.validateHttpParams(paramsToValidate),
    });
  }

  public getRegistration(id: string): Observable<RegistrationModel> {
    return this._http.get<RegistrationModel>(`${this._env.apiUrl}/${this.transactionPath}/${id}`);
  }

  public approveTransaction(id: string, initials: string, office: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/approveRegistration`, { initials, office });
  }

  public rejectTransaction(id: string, message: string): Observable<void> {
    return this._http.put<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/reject`, { rejected: true, rejectionReason: message });
  }

  public unRejectTransaction(id: string): Observable<void> {
    return this._http.put<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/reject`, { rejected: false });
  }

  public generateMV82V(transactionId: string): Observable<Blob> {
    return this._http
      .get(`${this._env.apiUrl}/registration/${transactionId}/mv82/pdf`, { responseType: 'blob' })
      .pipe(map((res: Blob) => new Blob([res], { type: 'application/pdf' })));
  }

  public generateMV82BV(transactionId: string): Observable<Blob> {
    return this._http
      .get(`${this._env.apiUrl}/registration/${transactionId}/mv82-b/pdf`, { responseType: 'blob' })
      .pipe(map((res: Blob) => new Blob([res], { type: 'application/pdf' })));
  }

  public generateMV82SNV(transactionId: string): Observable<Blob> {
    return this._http
      .get(`${this._env.apiUrl}/registration/${transactionId}/mv82-sn/pdf`, { responseType: 'blob' })
      .pipe(map((res: Blob) => new Blob([res], { type: 'application/pdf' })));
  }

  public issuePlates = (id: string, fullFillmentData) => {
    return this._http.post(`${this._env.apiUrl}/${this.transactionPath}/${id}/issuePlates`, fullFillmentData);
  };

  public createRegistration(data: any): Observable<RegistrationModel> {
    return this._http.post<RegistrationModel>(`${this._env.apiUrl}/${this.transactionPath}/create`, data, this.httpOptions);
  }

  public updateRegistration(id: string, data: any): Observable<RegistrationModel> {
    return this._http.post<RegistrationModel>(`${this._env.apiUrl}/${this.transactionPath}/${id}/update`, data, this.httpOptions);
  }

  public requestDocuments(id: string, content: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/requestDocuments`, { content });
  }

  public setTransactionStatus(id: number, status: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/setStatus`, { status });
  }

  public saveOwner(registrationId: string, owner: Owner): Observable<Owner> {
    return this._http.post<Owner>(`${this._env.apiUrl}/${this.transactionPath}/${registrationId}/owner`, owner);
  }

  public saveVehicle(registrationId: string, vehicle: Vehicle): Observable<Vehicle> {
    return this._http.post<Vehicle>(`${this._env.apiUrl}/${this.transactionPath}/${registrationId}/vehicle-information`, vehicle);
  }

  public updateHoldStatus(id: string, updateHoldModel: UpdateHoldModel): Observable<void> {
    return this._http.patch<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}`, updateHoldModel);
  }

  public addViewAction(id: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/view`, this.httpOptions);
  }
}
