import { Injectable } from '@angular/core';
import { DocAiNotificationError } from '@dmv/public/shared/http';

@Injectable({
  providedIn: 'root',
})
export class DocAIQualityService {
  public setNotificationForQualityFail(): DocAiNotificationError {
    const notificationError: DocAiNotificationError = { showClose: false };
    notificationError.bodyText = 'Your document may be difficult to read. Please upload a better image of your document.';
    notificationError.headerText = 'Possible Poor Quality Detected';
    notificationError.iconClass = 'fail-icon fa-exclamation-triangle';
    notificationError.notificationClass = 'fail-color';
    notificationError.showClose = true;

    return notificationError;
  }
}
