import { Component, EventEmitter, Input, Output } from '@angular/core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'lib-close-button',
  styleUrls: ['./close-button.component.scss'],
  templateUrl: './close-button.component.html',
})
export class ModalCloseButtonComponent {
  @Output() public readonly closeModalEvent: EventEmitter<void> = new EventEmitter();
  @Input() public cssId: string;
  @Input() public type = 'button';

  public closeModal() {
    this.closeModalEvent.emit();
  }
}
