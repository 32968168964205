import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { AccordionComponent } from './accordion.component';

@NgModule({
  declarations: [AccordionComponent],
  exports: [AccordionComponent],
  imports: [CommonModule, MatExpansionModule, MatIconModule],
})
export class AccordionModule {}
