<div class="container">
  <div class="header">
    <h2>Example {{ data.title }}</h2>
    <button class="close" (click)="closeModal()" aria-label="close button"><i class="fas fa-times"></i></button>
  </div>

  <span>Make sure the image is high quality.</span>
  <div class="image-container">
    <img class="img-fluid" [src]="data.frontImage" alt="" />
    <img class="img-fluid" *ngIf="data.backImage" [src]="data.backImage" alt="" />
  </div>
</div>
