import { Directive, HostListener } from '@angular/core';
import { ScrollUtils } from '../../services/scroll-utils-service';

@Directive({
  selector: '[dmvScrollToTop]',
})
export class ScrollToTopDirective {
  constructor(private readonly _scrollUtils: ScrollUtils) {}
  @HostListener('click') public onClick(): void {
    this._scrollUtils.scrollToTop('main-skip-span');
  }
}
