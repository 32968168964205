import { Inject, Injectable } from '@angular/core';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, map, tap } from 'rxjs/operators';
import { FeatureFlagAdapter } from '../feature-flag.adapter';
import { FeatureFlagConfiguration, FEATURE_FLAG_CONFIGURATION } from '../feature-flag.type';
import { DEFAULT_FEATURE_FLAGS, FeatureKeys } from '../feature-flags.const';
import { FeatureFlagUserModel } from './../models/feature-flag-user.model';

@Injectable()
export class LaunchDarklyFeatureFlagAdapter implements FeatureFlagAdapter {
  // using a BehaviorSubject to have an initial flag set
  // as a fail safe in case LD doesn't initialize.
  // in isFeatureFlagEnabled we return true if the feature key doesn't exist.
  // _identified$ will be set in identify call once, additional calls to identify will not change the value.
  public readonly identified$: Observable<boolean>;
  public readonly initialLoaded$: Observable<boolean>;
  private readonly _featureFlags: BehaviorSubject<LDFlagSet> = new BehaviorSubject<LDFlagSet>({});
  private readonly _ldClient: LDClient;
  private _flags: LDFlagSet = {};
  private readonly _anonymousUserKey = 'anonymous-user';
  private readonly _identified = new ReplaySubject<boolean>(1);
  private readonly _initialLoaded = new ReplaySubject<boolean>(1);

  constructor(
    @Inject(FEATURE_FLAG_CONFIGURATION)
    private readonly _featureFlagConfiguration: FeatureFlagConfiguration,
    @Inject(DEFAULT_FEATURE_FLAGS)
    private readonly _defaultFeatureFlags: Record<string, boolean>,
  ) {
    this.identified$ = this._identified.asObservable();
    this.initialLoaded$ = this._initialLoaded.asObservable();
    this._ldClient = initialize(this._featureFlagConfiguration.launchDarkly.apiKey, {
      anonymous: true,
      key: this._anonymousUserKey,
      kind: 'device',
    });

    this._ldClient.on('ready', () => {
      this._setFlags();
    });

    this._ldClient.on('change', () => {
      this._setFlags();
    });

    this._ldClient.on('initialized', () => {
      this._initialLoaded.next(true);
      this._initialLoaded.complete();
    });

    this._ldClient.on('failed', () => {
      this._initialLoaded.next(false);
      this._initialLoaded.complete();
    });
  }

  public identify(featureFlagUserModel?: FeatureFlagUserModel) {
    if (!featureFlagUserModel) {
      this._identified.next(true);
      this._identified.complete();

      return;
    }
    from(this._ldClient.identify(featureFlagUserModel.toLaunchDarklySchema(), featureFlagUserModel.username))
      .pipe(
        tap(() => {
          this._identified.next(true);
          this._identified.complete();
        }),
        catchError(err => {
          console.error(err);
          this._identified.next(false);
          this._identified.complete();

          return of(true);
        }),
      )
      .subscribe();
  }

  public getFeatureFlagValue$<FeatureKeysT>(featureKey: FeatureKeysT): Observable<string | number | boolean> {
    return this._featureFlags.pipe(
      debounceTime(100),
      map((value: LDFlagSet) => this._getFeatureFlagValue<FeatureKeysT>(value, featureKey)),
    );
  }

  public getFeatureFlagValue<FeatureKeysT>(featureKey: FeatureKeysT): string | number | boolean {
    return this._getFeatureFlagValue<FeatureKeysT>(this._featureFlags.value, featureKey);
  }

  private _getFeatureFlagValue<FeatureKeysT>(value: LDFlagSet, featureKey: FeatureKeysT | FeatureKeys) {
    return (value[featureKey as string] as boolean) ?? this._defaultFeatureFlags[featureKey as string];
  }

  private _setFlags() {
    this._flags = this._ldClient.allFlags();
    this._featureFlags.next(this._flags);

    const tags: Record<string, string> = {};
    for (const [key, value] of Object.entries(this._flags)) {
      tags[`feature_${key}`] = value;
    }
  }
}
