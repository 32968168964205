import { Question, UploadDocumentDetail, UploadItem } from '@dmv/public/shared/http';

export const checkDocTypeChangeEligible = (questions: Question[], detail: UploadDocumentDetail): boolean => {
  if (
    !detail.classificationScoreDocAiResults ||
    detail.classificationScoreDocAiResults.length <= 0 ||
    detail.classificationScoreDocAiResults[0].isExpectedDocumentType === true ||
    detail.qualityScoreDocAiResults?.includes('FAIL')
  ) {
    return false;
  }

  const classifiedDocTypeId = detail.classificationScoreDocAiResults[0].classifiedDocumentTypeId?.toString();
  const questionWithDocs = questions.find(question => question.totalPoints > 0);
  if (!questionWithDocs) {
    return false;
  }

  let isDocEligible = questionWithDocs.answerPrompts.findIndex(answer => answer.id === classifiedDocTypeId) > -1;

  if (questionWithDocs.answers.includes(classifiedDocTypeId)) {
    isDocEligible = false;
  }

  const pointsThreshold = questionWithDocs.pointsThreshold;

  if (pointsThreshold) {
    const newDoc = questionWithDocs.answerPrompts.find(answer => answer.id === classifiedDocTypeId);
    const oldDoc = questionWithDocs.answerPrompts.find(answer => answer.id === detail.documentTypeId.toString());

    //check if we remove the old doc and add the new doc, are we still above the min points threshold?
    const pointsValid = questionWithDocs.totalPoints - oldDoc?.metadata.points + newDoc?.metadata.points >= pointsThreshold;

    isDocEligible = isDocEligible && pointsValid;
  }

  return isDocEligible;
};

export const createNewUploadItems = (newUploadItem: UploadItem, oldUploadItem: UploadItem, uploads: UploadItem[]): UploadItem[] => {
  const uploadItemIndex = uploads.findIndex(item => item.id === oldUploadItem.id);
  const newDetails = [];

  // since we're only changing the type here, carry over the files, preview img, and set re-upload flag from
  // original updateItem
  const newDetail = {
    ...new UploadDocumentDetail(newUploadItem.details[0]),
    docTypeChangeEligible: false,
    files: [...oldUploadItem.details[0].files],
    imageSrc: [...oldUploadItem.details[0].imageSrc],
    reUploadReq: true,
    uploaded: true,
  };
  newDetails.push(newDetail);
  if (newUploadItem.details.length > 1) {
    if (oldUploadItem.details.length === 1) {
      newDetails.push({
        ...new UploadDocumentDetail(newUploadItem.details[1]),
        uploaded: false,
      });
    } else {
      newDetails.push({
        ...new UploadDocumentDetail(newUploadItem.details[1]),
        files: [...oldUploadItem.details[1].files],
        imageSrc: [...oldUploadItem.details[1].imageSrc],
        status: oldUploadItem.details[1].status,
        uploaded: oldUploadItem.details[1].uploaded,
      });
    }
  }

  return [
    ...uploads.slice(0, uploadItemIndex),
    { ...new UploadItem(newUploadItem), details: newDetails, singleFileChoose: newDetails.length > 1 },
    ...uploads.slice(uploadItemIndex + 1, uploads.length),
  ];
};
