import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { SectionInfo, Status, SurveySectionType } from '@dmv/public/shared/http';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-navigation-stepper',
  styleUrls: ['./navigation-stepper.component.scss'],
  templateUrl: './navigation-stepper.component.html',
})
export class NavigationStepperComponent implements AfterViewInit, OnDestroy {
  @Input() public currentSection!: SurveySectionType;
  @Input() public homeLabel = 'Checklist';
  @Input() public activeParentSection: string | undefined = undefined;
  @Input() public showHome = true;
  @Input() public showNumber = false;
  @Output() public readonly navigateTo = new EventEmitter<{ parentSection?: string | undefined; sectionId: SurveySectionType }>();
  @Output() public readonly navigateToHome = new EventEmitter();

  @ViewChild('navListContainer') private readonly _navListContainer: ElementRef;
  @ViewChildren('navListContainer') private readonly _navListContainerX?: QueryList<ElementRef>;

  public isMobile = false;
  private _sections: SectionInfo[] = [];

  private readonly _destroyed$: Subject<void> = new Subject<void>();
  private readonly _navListContainer$: Subject<SectionInfo[]> = new Subject<SectionInfo[]>();

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {
    this.isMobile = window.navigator.maxTouchPoints > 0;
  }

  public get sections(): SectionInfo[] {
    return this._sections;
  }

  @Input() public set sections(sections: SectionInfo[]) {
    this._sections = sections;
    this._navListContainer$.next(sections);
  }

  public ngAfterViewInit(): void {
    this._navListContainer$
      .asObservable()
      .pipe(
        tap(sections => {
          this._changeDetectorRef.detectChanges();
          if (!this.currentSection || !this._navListContainer) {
            return;
          }
          const idx: number = sections.findIndex(section => section.sectionId === this.currentSection);
          if (idx < 0) {
            return;
          }

          const parentRect = this._navListContainer.nativeElement.getBoundingClientRect();
          const childRect: DOMRect = this._navListContainer.nativeElement.children[idx].getBoundingClientRect();
          parentRect &&
            childRect &&
            this._navListContainer.nativeElement.scrollTo({ behavior: 'smooth', left: childRect.x - parentRect.x, top: 0 });
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  public ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public navClicked(parentSection: string | undefined, sectionId: SurveySectionType, status: Status) {
    if (status === 'complete' || sectionId === this.currentSection) {
      return;
    }

    this.navigateTo.emit({ parentSection, sectionId });
  }
}
