import { ValidationErrors } from 'ngrx-forms';

export abstract class AbstractDataService {
  protected readonly _validationErrors: Record<string, Record<string, string>> = {};

  protected constructor(validationErrors: Record<string, Record<string, string>>) {
    this._validationErrors = validationErrors;
  }
  public getValidationError(key: string, errors: ValidationErrors): string | null {
    return errors ? this._validationErrors[key][Object.keys(errors)[0]] : null;
  }
  public getValidationErrorKeys(): string[] {
    return Object.keys(this._validationErrors);
  }
}
