import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SurveySectionType } from '@dmv/public/shared/http';
import { WindowReferenceService } from '@dmv/public/shared/utils';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, delay, filter, map, tap } from 'rxjs/operators';
import { ModalActions, NavigationActions } from '../actions';
import { NavigationSelectors } from '../selectors';

@Injectable()
export class NavigationEffects {
  // todo consider handling this action in the transaction type effects file
  public navigateToApplicationChecklist$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(NavigationActions.navigateToApplicationChecklist),
      map(_ =>
        NavigationActions.navigateToTransactionPage({
          urlFragments: [SurveySectionType.APPLICATION_CHECKLIST],
        }),
      ),
    );
  });

  public navigateToApplicationChecklistAndShowSubmit$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(NavigationActions.navigateToApplicationChecklistAndShowSubmit),
      concatMap(action => {
        return [
          NavigationActions.navigateToApplicationChecklist(),
          ModalActions.showCompletionDialog({ showCounties: action.showCounties }),
        ];
      }),
    );
  });

  public navigateToPage$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(NavigationActions.navigateToPage),
        concatLatestFrom(() => [this._store.select(NavigationSelectors.selectTransactionUri)]),
        tap(([action, transactionUri]) => {
          return this._router.navigate(['home', transactionUri, ...action.urlFragments]);
        }),
      );
    },
    { dispatch: false },
  );

  public navigationEnd$ = createEffect(
    () =>
      this._router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        delay(500), // Delay the scroll action
        tap(() => this._windowsReferenceService.scrollTo(0, 0)),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _router: Router,
    private readonly _windowsReferenceService: WindowReferenceService,
  ) {}
}
