import { Inject, Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { FeatureFlagService, FeatureFlagValueType } from '@libs/feature-flag';
import { switchMap } from 'rxjs/operators';
import { Environment } from './types/environment.type';
import { DeviceRiskClientType } from './types/device-risk-client.type';

// Declare the external library's main object
declare let SigmaDeviceManager: DeviceRiskClientType;

@Injectable({
  providedIn: 'root',
})
export class DeviceRiskSdk {
  private readonly _socureSdkDownloadUrl = 'https://sdk.dv.socure.io/latest/device-risk-sdk.js';

  constructor(@Inject('env') private readonly _env: Environment, private readonly _featureFlagService: FeatureFlagService) {}

  // The string in this case is a signed JWT containing a UUID session id claim.
  public getDeviceSessionId$(): Observable<string> {
    return from(SigmaDeviceManager.getSessionToken());
  }

  // Gives an Observable under which the Socure script is configured with
  // the environment specific SDK key.
  // This needs to be dynamic, otherwise it fails with a 401 from Socure.
  public loadDeviceRiskScript$(): Observable<void> {
    return this._featureFlagService.getFeatureFlagValue$('public-socure-environment').pipe(
      switchMap(socureEnvironment => {
        const socureSdkKey = this._getSdkKeyForSocureEnvironment(socureEnvironment);
        if (document.querySelector(`script[data-public-key="${socureSdkKey}"]`)) {
          // Do nothing if the script, identified by SDK key, is already loaded
          return of(undefined); // Emit undefined, then complete
        }

        return this._appendDeviceRiskScript$(socureSdkKey);
      }),
    );
  }

  private _appendDeviceRiskScript$(socureSdkKey: string): Observable<void> {
    this._unloadUnusedDeviceRiskScripts();

    return new Observable<void>(observer => {
      const script = document.createElement('script');
      script.defer = true;
      script.src = this._socureSdkDownloadUrl;
      script.setAttribute('data-public-key', socureSdkKey);
      script.onload = () => {
        observer.next();
        observer.complete();
      };
      script.onerror = () => observer.error(new Error(`Failed to load script: ${this._socureSdkDownloadUrl}`));
      document.body.appendChild(script);
    });
  }

  private _unloadUnusedDeviceRiskScripts(): void {
    let selectedScript = document.querySelector(`script[data-public-key="${this._env.socureSdkKey_sandbox}"]`);
    if (selectedScript) {
      document.body.removeChild(selectedScript);
    }
    selectedScript = document.querySelector(`script[data-public-key="${this._env.socureSdkKey_cert}"]`);
    if (selectedScript) {
      document.body.removeChild(selectedScript);
    }
    selectedScript = document.querySelector(`script[data-public-key="${this._env.socureSdkKey_prod}"]`);
    if (selectedScript) {
      document.body.removeChild(selectedScript);
    }
  }

  private _getSdkKeyForSocureEnvironment(socureEnvironment: FeatureFlagValueType): string {
    let socureSdkKey: string;
    switch (socureEnvironment) {
      case 'certification':
        socureSdkKey = this._env.socureSdkKey_cert;
        break;
      case 'production':
        socureSdkKey = this._env.socureSdkKey_prod;
        break;
      default:
        socureSdkKey = this._env.socureSdkKey_sandbox;
    }

    return socureSdkKey;
  }
}
