import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import { MatButtonModule } from '@angular/material/button';
import { CustomToastComponent } from './toast.component';
import { ToastService } from './toast.service';

@NgModule({
  declarations: [CustomToastComponent],
  exports: [CustomToastComponent],
  imports: [A11yModule, CommonModule, MatButtonModule],
  providers: [ToastService],
})
export class CommonToastModule {}
