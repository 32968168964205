import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderData } from './header.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-shared-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() public userName!: string;
  @Input() public headData!: HeaderData;
  @Input() public nearestAppointmentsDateInfo!: { date: string; stateRunCounty: boolean };

  @Output() public readonly clickMainSkip = new EventEmitter<void>();
  @Output() public readonly logoutClick = new EventEmitter<void>();
  @Output() public readonly backClick = new EventEmitter<string>();
}
