export enum UploadStatus {
  BARCODED_INVALID = 'Error: Barcode Not Detected or Is Invalid. Please try again.',
  CORRUPTED_FILE = 'Corrupted File',
  FAILED = 'Upload Failed',
  FILELENGTH = 'Allows only 10 files',
  FILESIZE = 'Max file size is 15MB',
  MINFILESIZE = 'File is corrupt (0 bytes)',
  INVALID = 'Invalid Document Type',
  PAGE_COUNT_EXCEEDED = 'Page count exceeded',
  PENDING = 'Pending upload of other side',
  REQUIRED = 'Upload Required',
  SUCCESS = 'Success!',
  UPLOADING = 'Uploading',
  VALID = 'Valid',
  PASSWORDPROTECTED = 'File is password protected',
}
