import { HttpErrorResponse } from '@angular/common/http';
import { Branch } from '@dmv/common';
import { TaxRateJurisdictionModel } from '@dmv/public/shared/http';
import { createAction, props } from '@ngrx/store';
import { County } from '../models/county.model';

export const getCountyById = createAction('[County] Load County By Id Success', props<{ id: number }>());

export const loadCounties = createAction('[County] Load Counties');

export const loadCountyOffices = createAction('[County] Load County Offices');

export const loadCountiesSuccess = createAction('[County] Load Counties Success', props<{ counties: County[] }>());

export const loadCountyOfficesSuccess = createAction('[County] Load County Offices Success', props<{ countyOffices: Branch[] }>());

export const loadCountiesFailure = createAction('[County] Load Counties Failure', props<{ error: HttpErrorResponse }>());

export const loadJurisdictions = createAction('[County] Load Jurisdictions');
export const loadJurisdictionsSuccess = createAction(
  '[County] Load Jurisdictions Success',
  props<{ jurisdictions: TaxRateJurisdictionModel[] }>(),
);

export const loadJurisdictionsFailure = createAction('[County] Load Jurisdictions Failure', props<{ error: HttpErrorResponse }>());
export const removeCounty = createAction('[County] Remove County', props<{ id: number }>());

export const setSelectedCountyId = createAction('[County] Select County By Id', props<{ id: number }>());
