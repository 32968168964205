import { BaseOption } from './base-option.model';

export const idTypeOptions: BaseOption[] = [
  {
    label: 'Driver License',
    value: 'DRIVER_LICENSE',
  },
  {
    label: 'Learner Permit',
    value: 'LEARNER_PERMIT',
  },
  {
    label: 'Non-Driver ID',
    value: 'NON_DRIVER_ID',
  },
];
