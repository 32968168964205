import { VehicleType } from './vehicle-type.model';

export enum DtfTransactionType {
  GIFT_OTHER_THAN_FAMILY = 'Gift of a motor vehicle to a person other than spouse, parent, child, stepparent, or stepchild.',
  PURCHASE_LOWER_THAN_MARKET_OUTSIDE_FAMILY = 'Purchase of a motor vehicle at below fair market by a person other than spouse, parent, child, stepparent, or stepchild',
  ATV_TRAILER_BOAT_SNOWMOBILE_GIFT = 'Gift of a trailer, ATV, boat, or snowmobile',
  PURCHASE_ATV_TRAILER_ETC = 'Purchase of a trailer ATV, boat, boat/trailer combination, or snowmobile at below fair market value',
  GIFT_OR_PURCHASE = 'Gift or purchase of a motor vehicle to spouse, parent, child, stepparent, or stepchild',
  NONE = 'None of the above',
}

export const BOAT_TRAILER_PRICE_CAP = 230000;

export function calculatePurchasePrice(
  dtfBalanceOfPaymentsAssumedValue: number,
  dtfPurchaseAmountValue: number,
  dtfValueOfNonCashPropertyValue: number,
  vehicleType: VehicleType,
): number {
  let purchasePrice = dtfBalanceOfPaymentsAssumedValue + dtfPurchaseAmountValue + dtfValueOfNonCashPropertyValue;

  if (vehicleType === VehicleType.BOAT || vehicleType === VehicleType.BOAT_TRAILER) {
    purchasePrice = purchasePrice < BOAT_TRAILER_PRICE_CAP ? purchasePrice : BOAT_TRAILER_PRICE_CAP;
  }

  return purchasePrice;
}

export enum DtfRelationshipToPreviousOwner {
  NONE = 'None',
  SPOUSE = 'Spouse',
  PARENT = 'Parent',
  CHILD = 'Child',
  STEPPARENT = 'Stepparent',
  STEPCHILD = 'Stepchild',
  OTHER = 'Other',
}

export interface TransactionInformation {
  previousOwner: string | null;
  previousOwnerIndividualOrBusiness: string | null;
  previousOwnerFirstName?: string | null;
  previousOwnerMiddleInitial?: string | null;
  previousOwnerLastName?: string | null;
  previousOwnerBusinessName?: string | null;
  previousOwnerAddress: string | null;
  previousOwnerApartment: string | null;
  previousOwnerCity: string | null;
  previousOwnerState: string | null;
  previousOwnerZip: string | null;
  previousOwnerCounty: string | null;
  previousOwnerCountyId: number | null;
  vehicleDeliveryCounty?: string | null;
  vehicleStorageCounty: string | null;

  dtfDateOfTransaction?: string | null;
  dtfVehicleStorageJurisdictionId: number | null;
  dtfVehicleDeliveryJurisdictionId: number | null;
  dtfVehicleStorageCityNameOther?: string | null;
  dtfVehicleDeliveryCityNameOther?: string | null;
  dtfHiddenDateOfTransaction?: string | null;
  dtfRelationshipToPreviousOwner: DtfRelationshipToPreviousOwner | null;
  dtfRelationshipToPreviousOwnerOther?: string | null;
  dtfTransactionType: DtfTransactionType | null;
  dtfPurchaseAmount: number | null;
  dtfBalanceOfPaymentsAssumed: number | null;
  dtfTaxRate: number | null;
  dtfValueOfNonCashProperty: number | null;
  dtfPriceLowerThanMarketValue: boolean | null;
  vehicleResidenceInCounty?: boolean | null;
  showVehicleInfo?: boolean | null;

  transactionId?: string;
}
