export enum ToastPosition {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_RIGHT = 'bottom-right',
}

export enum JustifyPosition {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum AlignPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}
