import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { DEVICE_BLOCKED_RESUME_KEY } from '@dmv/shared/utils-authentication';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-device-blocked',
  styleUrls: ['./device-blocked.component.scss'],
  templateUrl: './device-blocked.component.html',
})
export class DeviceBlockedComponent implements OnInit {
  public resumeUrl: string;

  constructor(private readonly _router: Router) {}

  public ngOnInit(): void {
    this.resumeUrl = sessionStorage.getItem(DEVICE_BLOCKED_RESUME_KEY);
    if (!this.resumeUrl) {
      this.resumeUrl = 'home';
    }
  }

  public async onContinueClick(): Promise<void> {
    await this._router.navigate([this.resumeUrl]);
  }
}
