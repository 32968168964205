<div class="communication-preference">
  <label class="radio-label" id="communicationPreference">Please select your contact preference</label>
  <mat-radio-group
    (change)="radioChange($event)"
    name="communicationPreference"
    aria-labelledby="communicationPreference"
    aria-label="Please select your contact preference"
  >
    <mat-radio-button [value]="false" [checked]="true">Email Only</mat-radio-button>
    <mat-radio-button [value]="true">Email and text/SMS</mat-radio-button>
    <div class="notice sms-notice">
      Standard data fees and text messaging rates may apply based on your plan with your mobile phone carrier.
    </div>
  </mat-radio-group>
</div>
