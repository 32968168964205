import { InjectionToken } from '@angular/core';
import { OktaAuthOptions } from '@okta/okta-auth-js';

export interface AuthenticationConfiguration {
  okta: OktaAuthOptions;
}

export const AUTHENTICATION_CONFIGURATION = new InjectionToken<AuthenticationConfiguration>('AUTHENTICATION_CONFIGURATION');

/**
 * Application defined user type. Each provider should include it's user model and fields referenced here.
 */
export type User = OktaUser;

export interface OktaUser {
  username: string;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface AuthenticatedInfo {
  isAuthenticated: boolean;
  user: User;
}
