// eslint-disable-next-line max-len
/*eslint no-trailing-spaces: "error"*/

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'text/plain' }),
  };

  constructor(private readonly _http: HttpClient, @Inject('env') private readonly _env) {}

  // service for getting all the Payments
  public getPayments() {
    return this._http.get(`${this._env.apiUrl}/payments`);
  }

  // service for getting payment token for a specified invoiceId
  public getPaymentTransctionToken(invoiceId: string): Observable<any> {
    return this._http.get<any>(`${this._env.apiUrl}/payment-token/${invoiceId}`);
  }

  // service for adding a payment
  public addPayment(status, transactionId, invoiceId, amount) {
    const postObject = {
      amount,
      invoice_id: invoiceId,
      status,
      transaction_id: transactionId,
    };

    return this._http.post(`${this._env.apiUrl}/payments`, JSON.stringify(postObject), {
      headers: this.httpOptions.headers,
    });
  }

  // service for getting the payment page
  public getPaymentPage(amount) {
    const postObject = {
      amount,
    };

    return this._http.post(`${this._env.apiUrl}/payment-page`, JSON.stringify(postObject), {
      headers: this.httpOptions.headers,
    });
  }

  // service for making the payment
  public makePayment(transactionId, invoiceId): Observable<string> {
    const postObject = {
      invoiceId,
      transactionId,
    };

    return this._http.post<any>(`${this._env.apiUrl}/make-payment`, JSON.stringify(postObject), {
      headers: this.httpOptions.headers,
    });
  }

  public createToken() {
    return fetch(
      // eslint-disable-next-line max-len
      `https://api.demo.convergepay.com/hosted-payments/transaction_token?ssl_merchant_id=011029&ssl_user_id=webpage&ssl_pin=2D5T9QB0Q1TOQPQ7HBOFMARKYOBTBUMD2E8UNKRKZ39WPZM1JGTHO0A02HNLKXDT&ssl_transaction_type=ccsale&ssl_amount=10.00`,
      {
        headers: {},
        method: 'POST',
        mode: 'no-cors',
      },
    );
  }
}
