import { Action, createReducer, on } from '@ngrx/store';

export const APPLICATION_STATUS_FEATURE_KEY = 'applicationStatus';
import * as ApplicationStatusActions from '../actions/application-status.actions';

export interface ApplicationStatus {
  loading: boolean | null;
}

export const initialState: ApplicationStatus = {
  loading: null,
};

const workflowReducer = createReducer(
  initialState,
  on(ApplicationStatusActions.showSpinner, (state, { showSpinner }) => ({ ...state, loading: showSpinner })),
);

export function applicationStatusReducer(state: ApplicationStatus = initialState, action: Action) {
  return workflowReducer(state, action);
}
