export const accessPermissionKeys: PermissionKey[] = [
  'access-doccs-ndid',
  'access-original-permit',
  'access-original-registration',
  'access-license-reciprocity',
  'access-real-id',
  'access-original-ndid',
  'access-docai-admin-dashboard',
];

export type PermissionKey =
  | 'access-doccs-ndid'
  | 'access-original-permit'
  | 'access-original-registration'
  | 'access-license-reciprocity'
  | 'access-original-ndid'
  | 'access-real-id'
  | 'auto-assign'
  | 'county-drop-down-filter'
  | 'document-detail-view-view-personal-information'
  | 'document-detail-view-view-activity-log'
  | 'document-detail-view-view-submitted-documents-list'
  | 'document-detail-view-view-documents'
  | 'document-detail-view-view-print-all'
  | 'document-detail-view-view-history'
  | 'document-detail-view-hold'
  | 'document-detail-view-release-others-transactions'
  | 'document-detail-view-release-your-own-transactions'
  | 'document-detail-view-print-from-link'
  | 'document-detail-view-print-from-document-modal'
  | 'document-detail-view-download-from-document-modal'
  | 'document-detail-view-actions-add-additional-document-request'
  | 'document-detail-view-actions-edit-additional-document-request'
  | 'document-detail-view-actions-delete-additional-document-request'
  | 'document-detail-view-actions-update-document-dropdown-status'
  | 'document-detail-view-actions-approve'
  | 'document-detail-view-actions-send-message'
  | 'document-detail-view-actions-request-info'
  | 'document-detail-view-actions-reject'
  | 'document-detail-view-actions-cancel'
  | 'document-detail-view-actions-undo-reject'
  | 'document-detail-view-actions-complete-fulfillment'
  | 'document-detail-view-actions-invoice-adjustment'
  | 'document-detail-view-actions-edit-invoice'
  | 'document-detail-view-actions-delete-invoice'
  | 'document-detail-view-actions-back-to-invoice-paid'
  | 'document-detail-view-actions-confirm-title-received'
  | 'document-detail-view-actions-no-show'
  | 'filter-office-acted-upon'
  | 'filter-appointment-location'
  | 'filter-appointment-date'
  | 'filter-appointment-office-full'
  | 'filter-assigned-to-me'
  | 'list-view-status-tabs-new'
  | 'list-view-status-tabs-info-requested'
  | 'list-view-status-tabs-info-received'
  | 'list-view-status-tabs-approved'
  | 'list-view-status-tabs-on-hold'
  | 'list-view-status-tabs-denied'
  | 'list-view-search-full'
  | 'list-view-search-archived'
  | 'list-view-view-full'
  | 'list-view-view-archived'
  | 'list-view-status-tabs-invoice-sent'
  | 'list-view-status-tabs-invoice-paid'
  | 'list-view-status-tabs-fulfilled'
  | 'list-view-status-tabs-title-received'
  | 'list-view-status-tabs-non-compliant'
  | 'list-view-status-tabs-rejected'
  | 'sorting-created-date-oldest-to-newest'
  | 'sorting-created-date-newest-to-oldest'
  | 'sorting-appointment-date-oldest-to-newest'
  | 'sorting-appointment-date-newest-to-oldest'
  | 'document-detail-view-add-notes'
  | 'document-detail-view-view-notes'
  | 'document-detail-view-actions-total-charges-view'
  | 'document-detail-view-actions-resend-invoice'
  | 'document-detail-view-actions-successful-visit'
  | 'document-detail-view-actions-unsuccessful-visit'
  | 'access-docai-admin-dashboard';
