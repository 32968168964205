<div class="spinner-container">
  <ngx-spinner
    [showSpinner]="showSpinner"
    [fullScreen]="fullScreen"
    [size]="getValidSize()"
    [name]="name"
    [template]="template"
    [disableAnimation]="disableAnimation"
    [bdColor]="getValidBackdropColor()"
    [color]="getValidColor()"
    [type]="type"
  >
    <p class="loading-text" *ngIf="loadingText">
      {{ loadingText }}
    </p>
  </ngx-spinner>
</div>
