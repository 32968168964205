import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowReferenceService {
  public get nativeWindow(): Window {
    return window;
  }

  public scrollTo(x: number, y: number): void {
    this.nativeWindow.scrollTo(x, y);
  }
}
