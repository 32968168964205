import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleTagManagerAdapter } from './adapters/google-tag-manager.adapter';
import { EventsAdapter } from './events.adapter';
import { GoogleTagManager } from './events.type';

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule],
})
export class EventsModule {
  public static useGoogleTagManager(googleTagManager: GoogleTagManager): ModuleWithProviders<EventsModule> {
    return {
      ngModule: EventsModule,
      providers: [
        {
          provide: EventsAdapter,
          useClass: GoogleTagManagerAdapter,
        },
        { provide: 'googleTagManagerId', useValue: googleTagManager.id },
      ],
    };
  }
}
