export enum PrefilledPdfFormType {
  ID_UPGRADE_MV44 = 'ID_UPGRADE_MV44',
  LICENSE_RECIPROCITY_MV44 = 'LICENSE_RECIPROCITY_MV44',
  ORIGINAL_NDID_MV44 = 'ORIGINAL_NDID_MV44',
  ORIGINAL_PERMIT_MV44 = 'ORIGINAL_PERMIT_MV44',
  ORIGINAL_REGISTRATION_V2_MV82 = 'ORIGINAL_REGISTRATION_V2_MV82',
  ORIGINAL_REGISTRATION_V2_MV82B = 'ORIGINAL_REGISTRATION_V2_MV82B',
  ORIGINAL_REGISTRATION_V2_MV82SN = 'ORIGINAL_REGISTRATION_V2_MV82SN',
  ORIGINAL_REGISTRATION_V2_DTF802 = 'ORIGINAL_REGISTRATION_V2_DTF802',
}
