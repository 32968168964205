import { SectionStatus } from './base-transaction.model';
import { ContactPreferenceType } from './contact-information.model';
import { PersonalInformationRequest } from './personal-information.model';
import { TransactionDocument } from './transaction-document.model';
import { AppointmentStatus, BaseTransactionStatus, UsedDocumentTypes } from './transaction.model';
import { ConditionalQuestions } from './workflow.type';

export enum LicenseReciprocitySectionId {
  ADDITIONAL_FORM_INFORMATION = 'additional-form-information',
  PERSONAL_INFORMATION = 'personal-information',
  CURRENT_LICENSE = 'current-license',
  PROOF_OF_SOCIAL_SECURITY = 'proof-of-social-security',
  PROOF_OF_RESIDENCY = 'proof-of-residency',
  PROOF_OF_IDENTITY = 'proof-of-identity',
  PROOF_OF_NAME = 'proof-of-name',
  PROOF_OF_BIRTH = 'proof-of-birth',
  MV_FOUR_FOUR = 'mv-four-four',
}

export interface LicenseReciprocityResponseDTO {
  conditionalQuestions: ConditionalQuestions;
  id: number;
  sections: SectionStatus[];
  status: BaseTransactionStatus;
  submittedOn: string | null;
  transactionId: string;
  updatedAt: string;

  isCdl?: boolean;
  firstName?: string;
  contactPreferenceType?: ContactPreferenceType;
  appointmentCounty?: string;
  appointmentReservationNumber?: string;
  appointmentStatus?: AppointmentStatus;
  appointmentTime?: string;
  barcodeUrl?: string;
  branchAddress?: string;
  branchAddress2?: string;
  branchCity?: string;
  branchName?: string;
  branchZip?: string;
  documents?: TransactionDocument[];
  hasInfoBeenRequested?: boolean;
  rejectionReason?: string;
  usedDocuments?: UsedDocumentTypes[];
}

export interface LicenseReciprocityRequest {
  'current-license': {
    isCdl: boolean;
    document: {
      typeId?: number;
      originalStageIds?: number[];
      additionalRequestId?: number;
    };
  };
  'personal-information': PersonalInformationRequest;
}
