<div class="spinner-container">
  <ngx-spinner
    [showSpinner]="showSpinner"
    [fullScreen]="fullScreen"
    [size]="size"
    [name]="name"
    [disableAnimation]="disableAnimation"
    [bdColor]="bdColor"
    [color]="color"
    [type]="type"
  >
    <p *ngIf="loadingText && !fullScreen">
      {{ loadingText }}
    </p>
    <p *ngIf="loadingText && fullScreen" style="color: #3b3737f0; font-size: 1.5rem">
      {{ loadingText }}
    </p>
  </ngx-spinner>
</div>
