import { Injectable } from '@angular/core';
import { AnswerPrompt, Question, QuestionType } from '@dmv/public/shared/http';

@Injectable({
  providedIn: 'root',
})
export class SurveyDataService {
  constructor() {}

  public updateAnswers(answers: string[], answerValue: string, questionType: QuestionType, checked?: boolean): string[] {
    let updatedAnswers: string[] = answers;
    switch (questionType) {
      case 'checkbox':
      case 'points_checkbox':
        updatedAnswers = checked
          ? [...answers.filter(val => val !== answerValue), answerValue]
          : answers.filter(val => val !== answerValue);
        break;
      case 'radio':
      case 'select':
      case 'text':
        updatedAnswers = [answerValue];
        break;
      default:
        break;
    }

    return updatedAnswers;
  }

  public updatePoints(answerPrompt: AnswerPrompt, currentPoints: number, question: Question, checked?: boolean): number {
    const applicableCheckedTypes: QuestionType[] = ['checkbox', 'points_checkbox'];
    let points = 1;

    if (question.pointsThreshold) {
      points = answerPrompt.metadata.points || 1;
    }
    if (applicableCheckedTypes.includes(question.type)) {
      points = checked ? currentPoints + points : currentPoints - points;
    } else {
      // This allows readio options to have options with points of 0
      // without affecting anything checkboxes already do
      points = answerPrompt.metadata.points || 0;
    }

    return points;
  }
}
