import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PermissionKey } from '../role-permissions.const';
import { RolePermissionsService } from '../role-permissions.service';

/**
 * This directive provides an easy way to enable/disable a DOM element based on the user's role.
 * Use it like a *ngIf directive
 * ```html
 *    <button libPermissionDisabled="permission-key">Potentially disabled</button>
 * ````
 * To use this directive, add it to the imports array of the module you wish to use it in.
 *
 * Note:  This does not work on material specific DOM elements such as <mat-select>.
 * For more information see: https://github.com/angular/components/issues/24006
 */

@UntilDestroy()
@Directive({
  selector: '[libPermissionDisabled]',
})
export class PermissionDisabledDirective implements OnInit {
  @HostBinding('disabled')
  public rolePermissionDisabled!: boolean;
  @Input('libPermissionDisabled')
  public set permissionKey(key: PermissionKey) {
    this._permissionKey$.next(key);
  }

  private readonly _permissionKey$ = new ReplaySubject<PermissionKey>(1);

  constructor(private readonly _rolePermissionsService: RolePermissionsService) {}

  public ngOnInit() {
    this._permissionKey$
      .pipe(
        distinctUntilChanged(),
        switchMap(key => this._rolePermissionsService.hasPermission$(key)),
        untilDestroyed(this),
      )
      .subscribe(hasPermission => {
        this.rolePermissionDisabled = !hasPermission;
      });
  }
}
