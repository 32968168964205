export interface TransactionDocument {
  documentQualifier?: string;
  documentTypeName: string;
  id: number;
  documentTypeId: number;
  status: DocumentStatus;
}

export enum DocumentStatus {
  CORRECT = 'Correct',
  MISSING = 'Missing',
  NEEDSCORRECTIONS = 'NeedsCorrections',
  PENDING = 'pending',
  UPDATED = 'updated',
}
