<div class="my-5">
  <div class="row justify-content-md-center text-center">
    <div class="col col-lg-6">
      <p><i class="far fa-check-circle"></i></p>
      <h1 class="mb-3 font-weight-bolder">THANK YOU FOR PAYING YOUR VEHICLE REGISTRATION FEE</h1>

      <p>
        <button class="btn btn-primary btn-lg" (click)="paymentToken()" type="submit">Create Token</button>
      </p>
    </div>
  </div>
</div>
<hr />
