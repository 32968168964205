import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FocusDirective } from './focus/focus.directive';
import { ScrollToTopDirective } from './scroll-to-top/scroll-to-top.directive';

const DECLARE_EXPORT = [FocusDirective, ScrollToTopDirective];

@NgModule({
  declarations: DECLARE_EXPORT,
  exports: DECLARE_EXPORT,
  imports: [CommonModule],
})
export class CommonDirectivesModule {}
