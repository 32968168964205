<h2 class="current-step-title" *ngIf="step.title">{{ step.title }}</h2>
<div class="sub-title" *ngIf="step.subtitle">{{ step.subtitle }}</div>
<p *ngIf="step.body" [innerHTML]="step.body"></p>
<ng-content></ng-content>
<button
  class="active-step-button"
  [hidden]="step.buttonHidden"
  [disabled]="step.buttonDisabled"
  (click)="submitClick.emit()"
  mat-flat-button
  color="primary"
>
  {{ step.buttonText }}
</button>
<ng-content select="[additionalInfo]"></ng-content>
<mat-divider></mat-divider>
