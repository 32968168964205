import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-banner',
  styleUrls: ['./banner.component.scss'],
  templateUrl: './banner.component.html',
})
export class BannerComponent implements OnInit {
  @Input() public bannerText!: string;
  public bannerText$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  public ngOnInit(): void {
    this.bannerText$.next(
      this.bannerText ||
        'TEST ENVIRONMENT: Please do not submit real applications or documents | AMBIENTE DE PRUEBA: No envíe solicitudes o documentos reales',
    );
  }
}
