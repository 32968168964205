import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { last, map, switchMap } from 'rxjs/operators';
import { FeatureFlagAdapter } from './feature-flag.adapter';
import { DEFAULT_FEATURE_FLAGS, FeatureFlagValueType, FeatureKeys } from './feature-flags.const';
import { FeatureFlagUserModel } from './models/feature-flag-user.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(
    private readonly _featureFlagAdapter: FeatureFlagAdapter,
    @Inject(DEFAULT_FEATURE_FLAGS)
    private readonly _defaultFeatureFlags: Record<string, boolean>,
  ) {}

  /**
   *
   * Used for feature flags that are values other than boolean
   */
  public getFeatureFlagValue$(featureKey: FeatureKeys): Observable<FeatureFlagValueType> {
    return this._featureFlagAdapter.initialLoaded$.pipe(
      last(),
      switchMap(() => this._featureFlagAdapter.getFeatureFlagValue$(featureKey)),
    );
  }

  /**
   * The default way of checking if a feature flag is enabled
   */
  public isFeatureFlagEnabled$(featureKey: FeatureKeys, waitForIdentify?: boolean): Observable<boolean> {
    const obs = [this._featureFlagAdapter.initialLoaded$.pipe(last())];
    if (waitForIdentify) {
      obs.push(this._featureFlagAdapter.identified$.pipe(last()));
    }

    return forkJoin(obs).pipe(
      switchMap(() => {
        return this._featureFlagAdapter
          .getFeatureFlagValue$(featureKey)
          .pipe(map(value => (value as boolean) ?? this._defaultFeatureFlags[featureKey as unknown as string]));
      }),
    );
  }

  /**
   * Used to Identify the user with the feature flag provider
   * and to control flag values by user. Passing null is a no-op.
   */
  public identify(featureFlagUserModel?: FeatureFlagUserModel) {
    this._featureFlagAdapter.identify(featureFlagUserModel);
  }

  /**
   * Return the observable that emits once the call to resident/login has returned
   * a response.  This will only emit false if a resident existed such that their
   * user email is tied to a specific launch darkly configuration.
   */
  public residentIdentified$(): Observable<boolean> {
    return this._featureFlagAdapter.identified$.pipe(last());
  }

  /**
   * Is this feature flag currently enabled, this should only be used on f.e.
   * callbacks.
   * When the current value is needed at that moment.
   */
  public isFeatureFlagEnabled(featureKey: FeatureKeys): boolean {
    return !!this._featureFlagAdapter.getFeatureFlagValue(featureKey);
  }

  /**
   * Are any of these feature flags enabled? return true if so, otherwise return false
   */
  public isFeatureFlagEnabledFromArray(featureKeyArray: FeatureKeys[]): boolean {
    return featureKeyArray.some(key => {
      return !!this._featureFlagAdapter.getFeatureFlagValue(key);
    });
  }

  /**
   * Get a Record of feature flag values for the supplied list
   */
  public getFeatureFlagValues(featureKeyArray: FeatureKeys[]): Record<FeatureKeys, FeatureFlagValueType> {
    const flagsEnabled: Record<FeatureKeys, FeatureFlagValueType> | {} = {};

    featureKeyArray.forEach(key => {
      // The `!!` is used to ensure the value is converted to a boolean in case the method returns a truthy/falsy value instead of a strict boolean.
      flagsEnabled[key] = !!this._featureFlagAdapter.getFeatureFlagValue(key);
    });

    return flagsEnabled as Record<FeatureKeys, FeatureFlagValueType>;
  }
}
