import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticatedInfo, User } from '..';

@Injectable()
export abstract class AuthenticationAdapter {
  public abstract login(redirectUrl?: string): Promise<void>;

  public abstract logout(): Promise<boolean | void>;

  public abstract clearSession(): Promise<void>;

  public abstract getUser$(): Observable<User>;

  public abstract getIsAuthenticated$(): Observable<boolean>;

  public abstract getAuthenticationInfo$(): Observable<AuthenticatedInfo>;

  public abstract getAccessToken(): string;

  public abstract getOriginalUri(): string;
}
