export interface DecisionResponse {
  outcome: DecisionOutcome;
  expiration?: number | null;
}

export type DecisionOutcome = 'ACCEPT' | 'REFER' | 'REJECT' | 'RESUBMIT' | 'REVIEW' | 'UNKNOWN';

export const DEFAULT_DECISION: DecisionResponse = { outcome: 'ACCEPT' };
export const DEVICE_BLOCKED_RESUME_KEY = '_next';
export const PREVIOUS_BLOCKED_RESUME_KEY = '_prev';
export const DEVICE_RISK_OUTCOME_KEY = '_di_dro';
export const ORIGINAL_PERMIT_CHECKLIST_URL = '/home/original-permit/application-checklist/';
export const ORIGINAL_PERMIT_CONFIRMATION_URL = '/home/original-permit/confirmation';
