import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HeaderData } from '@dmv/shared/ui';
import { AuthenticationService, DecisionService } from '@dmv/shared/utils-authentication';
import { EventsService, EventsTag } from '@libs/events';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { SharedService } from './shared/services/shared.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  @ViewChild('mainContent', { static: true }) public mainContent: ElementRef;

  public isTestEnvironment = !environment.production;
  public headData: HeaderData;
  public authInfo$ = this._authenticationService.getAuthenticationInfo$();
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public nearestAppointmentsDateInfo$: Observable<{ date: string; stateRunCounty: boolean }>;

  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _eventsService: EventsService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _decisionService: DecisionService,
    private readonly _sharedService: SharedService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _titleService: Title,
  ) {
    // need to handle okta session authentication before ngOnInit
    if (!sessionStorage.getItem('persist_session')) {
      this._decisionService.clearDeviceRiskOutcome();
      this._authenticationService.clearSession();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public ngOnInit() {
    // Load the Socure SDK library and obtain a deviceSessionId
    this._decisionService.getDeviceSessionId$().pipe(takeUntil(this.destroy$)).subscribe();

    // authentication
    this._authenticationService
      .getIsAuthenticated$()
      .pipe(
        switchMap(isAuthenticated => {
          if (isAuthenticated) {
            return this._sharedService.residentLogin();
          } else {
            return EMPTY;
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    // GTM
    combineLatest([
      this._eventsService.initialize().pipe(filter(isAvailable => isAvailable === true)),
      this._router.events.pipe(filter(event => event instanceof NavigationEnd && !event.url.startsWith('/callback'))),
      this._authenticationService.getUser$(),
    ])
      .pipe(
        tap(([_isAvailable, event, user]) => {
          let child = this._activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }

          this.headData = child.snapshot.data.header;
          this._titleService.setTitle(child.snapshot.data.title ? child.snapshot.data.title : 'NY DMV');

          const eventsTag: EventsTag = {
            event: 'page',
            pageName: (event as NavigationEnd).url,
            time: new Date(),
            userEmail: user.email,
            userName: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.username,
          };
          this._eventsService.pushEvent(eventsTag);

          this._changeDetectorRef.markForCheck();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    document.addEventListener('dmv-real-id-upgrade-appointment-date', (data: CustomEvent) => {
      this.nearestAppointmentsDateInfo$ = of(data.detail);
      this._changeDetectorRef.markForCheck();
    });
  }

  public onClickMainSkip() {
    document.dispatchEvent(new CustomEvent('dmv-focus-main-content-event'));
  }

  public onBackClick(url: string): void {
    const [path, queryParams] = url.split('?');
    const params = new URLSearchParams(queryParams);
    const objectParams: Record<string, string> = {};
    params.forEach((value, key) => {
      objectParams[key] = value;
    });

    this._router.navigate([path], { queryParams: objectParams });
  }

  public async onLogoutClick(): Promise<void> {
    document.dispatchEvent(new CustomEvent('dmv-public-logout'));
  }
}
