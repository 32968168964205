import { NgModule } from '@angular/core';
import { AuthenticationAdapter } from '../authentication.adapter';
import { SharedUtilsAuthenticationModule } from '../shared-utils-authentication.module';
import { MockAuthenticationAdapter } from './authentication.adapter.mock';

@NgModule({
  exports: [SharedUtilsAuthenticationModule],
  imports: [SharedUtilsAuthenticationModule],
  providers: [
    {
      provide: AuthenticationAdapter,
      useClass: MockAuthenticationAdapter,
    },
  ],
})
export class AuthenticationTestingModule {}
