import { ActionReducerMap } from '@ngrx/store';
import { ApplicationStatusReducer, CountyReducer, NavigationReducer } from './lib/reducers';

export * from './lib/actions';
export * from './lib/effects';
export * from './lib/facade';
export * from './lib/models';
export * from './lib/reducers';
export * from './lib/selectors';

export interface AppState {
  applicationStatus: ApplicationStatusReducer.ApplicationStatus;
  counties: CountyReducer.CountyState;
  navigation: NavigationReducer.NavigationState;
}

export const initialState: AppState = {
  applicationStatus: null,
  counties: null,
  navigation: null,
};

export const reducers: ActionReducerMap<AppState> = {
  applicationStatus: ApplicationStatusReducer.applicationStatusReducer,
  counties: CountyReducer.countyReducer,
  navigation: NavigationReducer.navigationReducer,
};
