import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from '@dmv/public/common';
import { ConfirmationModal, SurveySectionType } from '@dmv/public/shared/http';
import { dialogRefWithTitle } from '@dmv/public/shared/utils';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { concatMap, exhaustMap, map, skipWhile } from 'rxjs/operators';
import { ModalActions, NavigationActions } from '../actions';
import { CountySelectors, SurveySectionSelectors } from '../selectors';

@Injectable()
export class ModalEffects {
  public restartSection$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ModalActions.showRestartSectionModal),
      concatMap(action => {
        const content = 'For security purposes, starting over will clear your previous responses and uploads for this section.';
        const dialogRef = this._dialog.open(ConfirmationModalComponent, {
          autoFocus: false,
          data: {
            cancelLabel: 'Cancel',
            confirmLabel: 'Restart',
            content,
            showCancel: true,
            showConfirm: true,
            title: 'Are you sure you want to restart this section?',
          },
          width: '400px',
        });

        return this._modalClose(dialogRef, action);
      }),
      concatMap((result: { confirmed; sectionId; transactionUri }) => {
        if (result.confirmed) {
          return of(
            NavigationActions.navigateToTransactionPage({
              urlFragments: [result.sectionId],
            }),
          );
        } else {
          return EMPTY;
        }
      }),
    );
  });

  public showCompletionDialog$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ModalActions.showCompletionDialog, ModalActions.showSubmitApplicationDialog),
      concatLatestFrom(_ => this._store.select(CountySelectors.getCounties)),
      skipWhile(([_, countyList]) => !countyList || countyList.length === 0),
      concatMap(([action, countyList]) => {
        const dialogRef = this._dialog.open(ConfirmationModalComponent, dialogRefWithTitle(action.showCounties ? countyList : null));

        return dialogRef.afterClosed();
      }),
      concatMap(({ confirmed, countyId }) => {
        if (confirmed) {
          return of(ModalActions.submitTransactionForReview({ countyId }));
        } else {
          return EMPTY;
        }
      }),
    );
  });

  public showLeaveSectionModal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ModalActions.showLeaveSectionModal),
      concatLatestFrom(() => this._store.select(SurveySectionSelectors.selectSurveySection)),
      exhaustMap(([action, section]) => {
        if (section) {
          if (section && !section?.isDirty) {
            return of({
              confirmed: true,
              sectionId: action.sectionId as SurveySectionType,
            });
          }
        }

        const modalData: ConfirmationModal = {
          cancelLabel: 'Cancel',
          confirmLabel: 'Leave',
          content: 'The information you have entered for this section will not be saved.',
          showCancel: true,
          showConfirm: true,
          title: 'Are you sure you want to leave this section?',
        };

        const dialogRef = this._dialog.open(ConfirmationModalComponent, {
          autoFocus: false,
          data: modalData,
          width: '400px',
        });

        return this._modalClose(dialogRef, action);
      }),
      concatMap((result: { confirmed; sectionId }) => {
        if (result.confirmed) {
          return of(
            NavigationActions.navigateToTransactionPage({
              urlFragments: [result.sectionId],
            }),
          );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
  ) {}

  private _modalClose(dialogRef: MatDialogRef<ConfirmationModalComponent>, action: any) {
    return dialogRef.afterClosed().pipe(
      map(response => ({
        confirmed: response.confirmed,
        sectionId: action.sectionId,
        transactionUri: action.transactionUri,
      })),
    );
  }
}
