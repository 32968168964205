import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApplicationStatusActions } from '../actions';
import { ApplicationStatusSelectors } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusFacade {
  public selectIsLoading: Observable<boolean | null> = this._store.select(ApplicationStatusSelectors.selectIsLoading);

  constructor(private readonly _store: Store) {}

  public showSpinner(showSpinner: boolean | null): void {
    this._store.dispatch(ApplicationStatusActions.showSpinner({ showSpinner }));
  }
}
