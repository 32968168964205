import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Document } from '@dmv/public/shared/http';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  selector: 'dmv-upload-list-review',
  standalone: true,
  styleUrls: ['./upload-list-review.component.scss'],
  templateUrl: './upload-list-review.component.html',
})
export class UploadListReviewComponent {
  @Input() public displayDocumentImages;

  @Input() public uploads: { title: string; documents: Document[] } | undefined = { documents: [], title: 'Document(s)' };

  @Input() public docAIFeatureFlagEnabled: boolean;
}
