import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DoccsNdid, FindTransactionsParams, DoccsNdidListDTO, SearchResult, UpdateHoldModel } from '../models';
import { ValidateUtil } from '../utils';

@Injectable({ providedIn: 'root' })
export class DoccsNdidService {
  private readonly _httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  private readonly _transactionPath = `${this._env.apiUrl}/mvr/v1/doccs-ndid`;

  constructor(private readonly _http: HttpClient, @Inject('env') private readonly _env) {}

  public findTransaction(paramsToValidate: FindTransactionsParams): Observable<SearchResult<DoccsNdidListDTO>> {
    return this._http.get<SearchResult<DoccsNdidListDTO>>(this._transactionPath, {
      params: ValidateUtil.validateHttpParams(paramsToValidate),
    });
  }

  public getTransaction(id: string): Observable<DoccsNdid> {
    return this._http.get<DoccsNdid>(`${this._transactionPath}/${id}`);
  }

  public approveTransaction(id: string, initials: string, office: string): Observable<void> {
    return this._http.post<void>(`${this._transactionPath}/${id}/approve`, { initials, office });
  }

  public fulfillTransaction(id: string): Observable<void> {
    return this._http.post<void>(`${this._transactionPath}/${id}/fulfill`, {});
  }

  public rejectTransaction(id: string, message: string): Observable<void> {
    return this._http.put<void>(`${this._transactionPath}/${id}/reject`, { rejectionReason: message });
  }

  public unrejectTransaction(id: string): Observable<void> {
    return this._http.put<void>(`${this._transactionPath}/${id}/unreject`, {});
  }

  public requestDocuments(id: string, content: string): Observable<void> {
    return this._http.put<void>(`${this._transactionPath}/${id}/requestDocuments`, { content });
  }

  public setTransactionStatus(id: number, status: string): Observable<void> {
    return this._http.post<void>(`${this._transactionPath}/${id}/status`, { status });
  }

  public updateHoldStatus(id: string, updateHoldModel: UpdateHoldModel): Observable<void> {
    return this._http.patch<void>(`${this._transactionPath}/${id}`, updateHoldModel);
  }

  public addViewAction(id: string): Observable<void> {
    return this._http.post<void>(`${this._transactionPath}/${id}/view`, this._httpOptions);
  }
}
