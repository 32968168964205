import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthState } from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root',
})
export class MockOktaAuthStateService {
  public readonly authState$: Observable<AuthState> = of({});
}
