import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlagService } from './feature-flag.service';
import { FeatureKeys } from './feature-flags.const';

@Injectable()
export class FeatureFlagGuard implements CanActivate {
  constructor(private readonly _router: Router, private readonly _featureFlagService: FeatureFlagService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    // If you are checking at the route level if a feature is on, then your Route should specify a featureKey property in data.
    if (!route.data?.featureKey) {
      console.error(`Error checking route data for feature flag key. You need to specify a featureKey in the Route's data.`);

      return of(false);
    }
    const featureKey = route.data.featureKey as FeatureKeys;
    const redirectUrl = route.data.redirectUrl || '/';

    return this._featureFlagService
      .isFeatureFlagEnabled$(featureKey, true)
      .pipe(map(enabled => (enabled ? true : this._router.parseUrl(redirectUrl))));
  }
}
