import { NgModule } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { MockOktaAuth } from './okta-auth.service.mock';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: OktaAuth,
      useClass: MockOktaAuth,
    },
  ],
})
export class OktaTestingModule {}
