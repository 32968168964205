import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { LOGIN_REDIRECT_CONFIGURATION } from './login-redirect.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-login-redirect',
  styleUrls: ['./login-redirect.component.scss'],
  templateUrl: './login-redirect.component.html',
})
export class LoginRedirectComponent {
  public outcome: string;

  constructor(
    @Inject(LOGIN_REDIRECT_CONFIGURATION)
    private readonly _loginRedirectUrl: string,
    private readonly _authenticationService: AuthenticationService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
  ) {}

  public async onRegisterClick() {
    window.location.href = this._loginRedirectUrl;
  }

  public async onLoginClick() {
    await this._authenticationService.login();
  }
}
