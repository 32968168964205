<dmv-banner *ngIf="isTestEnvironment"></dmv-banner>
<dmv-shared-header
  [userName]="(authInfo$ | async).user.username"
  [headData]="headData"
  [nearestAppointmentsDateInfo]="nearestAppointmentsDateInfo$ | async"
  (clickMainSkip)="onClickMainSkip()"
  (logoutClick)="onLogoutClick()"
  (backClick)="onBackClick($event)"
></dmv-shared-header>

<section id="main" role="main">
  <div class="container" id="main-content" #mainContent tabindex="-1">
    <router-outlet></router-outlet>
  </div>
</section>
