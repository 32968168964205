import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { BottomNavigationModule, CommonFooterModule } from '@dmv/common';
import { TerminalPageComponent } from './terminal-page.component';

@NgModule({
  declarations: [TerminalPageComponent],
  exports: [TerminalPageComponent],
  imports: [CommonModule, MatIconModule, MatDividerModule, BottomNavigationModule, CommonFooterModule],
})
export class TerminalPageModule {}
