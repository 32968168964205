import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AccordionModule } from '@dmv/common';
import { DashboardCompletedStepsComponent } from './dashboard-completed-steps.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [DashboardCompletedStepsComponent],
  exports: [DashboardCompletedStepsComponent],
  imports: [AccordionModule, CommonModule, MatDividerModule, MatIconModule],
})
export class DashboardCompletedStepsModule {}
