<ng-container *ngIf="showToast">
  <div class="float align-{{ alignPosition }} justify-{{ justifyPosition }}" *ngIf="float; else inline" aria-live="polite">
    <div *ngIf="alignPosition === 'bottom'" [@slideInUp]>
      <ng-container *ngTemplateOutlet="toastTemplate"> </ng-container>
    </div>

    <div *ngIf="alignPosition === 'top'" [@slideInDown]>
      <ng-container *ngTemplateOutlet="toastTemplate"> </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #inline>
  <ng-container *ngTemplateOutlet="toastTemplate"> </ng-container>
</ng-template>

<ng-template #toastTemplate>
  <div class="toast-container {{ type }}" #toastContent tabindex="-1">
    <div class="left">
      <div class="header">
        <div class="heading" *ngIf="heading">
          <h3>
            <i
              class="toast-container-icon"
              *ngIf="showIcon"
              [ngClass]="{
                'fas': true,
                'error': type === 'error',
                'info': type === 'info',
                'success': type === 'success',
                'warning': type === 'warning',
                'fa-exclamation': type === 'error',
                'fa-check': type === 'success',
                'fa-info': type === 'info'
              }"
              [attr.title]="iconTitles[type] || 'Default icon'"
            ></i>
            <span [ngClass]="{ icon: showIcon }">{{ heading }}</span>
          </h3>
        </div>
      </div>

      <div class="toast-content font-size-14" [ngClass]="{ icon: showIcon }">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="right" *ngIf="showCloseButton">
      <button class="close" (click)="hideToast()" aria-label="close button"><i class="fas fa-times"></i></button>
    </div>
  </div>
</ng-template>
