import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';

@Pipe({
  name: 'dmvmarkdown',
})
export class MarkdownPipe implements PipeTransform {
  public transform(value: string | null): string | Promise<string> {
    return value && typeof value === 'string' && value.length > 0 ? marked(value) : value;
  }
}
