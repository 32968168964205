import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FeatureFlagService } from '@libs/feature-flag';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { BaseApiService } from '../base/base-api.service';
import {
  AddressRequest,
  ContactInformationRequest,
  DocAiResponse,
  DropdownOption,
  FileUploadDriverLicense,
  FileUploadStaging,
  LicenseClassChangeDocumentTypes,
  LicenseClassChangeRequest,
  LicenseClassChangeStatusResponse,
  LicenseClassChangeTransaction,
  LicenseReciprocityRequest,
  LicenseReciprocityResponseDTO,
  OptOutPreference,
  RealIdEdlDTO,
  SectionResponse,
  SectionStatus,
  TransactionType,
} from '../models';
import { API_CONFIGURATION, ApiConfiguration } from './api.type';

@Injectable({
  providedIn: 'root',
})
export class SharedApiService extends BaseApiService {
  public featureFlagEnabled: boolean;
  public baseSchedulingUrl = '/v2/scheduling';

  constructor(
    @Inject(API_CONFIGURATION) protected readonly _apiConfiguration: ApiConfiguration,
    protected readonly _http: HttpClient,
    private readonly _featureFlag: FeatureFlagService,
  ) {
    super(_apiConfiguration, _http);
    this._featureFlag
      .isFeatureFlagEnabled$('public-applus-scheduling')
      .pipe(
        skipWhile(isEnabled => isEnabled === null),
        map(isEnabled => {
          if (isEnabled) {
            this.baseSchedulingUrl = '/v3/scheduling';
          }
        }),
      )
      .subscribe();
  }
  public getBranchNearestDate(entityId: number, transactionStatus: string, transactionType: TransactionType): Observable<string> {
    return this._handleGet$(`${this.baseSchedulingUrl}/services/branchNearestDate`, {
      params: {
        entityId,
        transactionStatus,
        transactionType,
      },
    });
  }

  public getLicenseClassChangeClientIdStatus(cid: string): Observable<LicenseClassChangeStatusResponse> {
    return this._handleGet$<LicenseClassChangeStatusResponse>(`/public/v1/license-class-change/client-ids/${cid}/status`);
  }

  public getLicenseClassTransactionHeaders(): Observable<LicenseClassChangeTransaction> {
    return this._handleHead$<LicenseClassChangeTransaction>(`/public/v1/license-class-change/transactions/current`);
  }

  public getLicenseClassChangeTransactionCurrent(): Observable<LicenseClassChangeTransaction> {
    return this._handleGet$<LicenseClassChangeTransaction>(`/public/v1/license-class-change/transactions/current`);
  }

  public getLicenseClassChangeDocumentTypes(): Observable<LicenseClassChangeDocumentTypes[]> {
    return this._handleGet$<LicenseClassChangeDocumentTypes[]>(`/public/v1/license-class-change/document-types`);
  }

  public getSmsPhoneNumbers(phoneNumber: string): Observable<OptOutPreference> {
    return this._handleGet$<OptOutPreference>(`/api/v1/sms-opt-out/phone-numbers/${phoneNumber}`);
  }

  public getIdUpgradeTransaction(): Observable<RealIdEdlDTO> {
    return this._handleGet$<RealIdEdlDTO>(`/v2/id-upgrade/transaction/`);
  }

  public getBarcodeScan(id: number): Observable<FileUploadDriverLicense> {
    return this._handleGet$<FileUploadDriverLicense>(`/public/v1/ids/staging/${id}`);
  }

  public postLicenseClassChangeTransactionCurrent(data: LicenseClassChangeRequest) {
    return this._handlePost$<LicenseClassChangeTransaction>(`/public/v1/license-class-change/transactions`, data);
  }

  public postStagingOriginalDocuments(formData: FormData): Observable<FileUploadStaging> {
    return this._handlePost$<FileUploadStaging>(`/public/v1/original-documents/staging`, formData);
  }

  public postStagingIds(formData: FormData): Observable<FileUploadDriverLicense> {
    return this._handlePost$<FileUploadDriverLicense>(`/public/v1/ids/staging`, formData);
  }

  public getProcessorResults(processorType: string, resultsId: string): Observable<DocAiResponse> {
    return this._handleGet$<DocAiResponse>(`/public/v1/original-documents/staging/processor-results/${resultsId}/${processorType}`);
  }

  public putCurrentLicense(currentLicense: unknown): Observable<SectionResponse> {
    return this._handlePut$<SectionResponse>(`/v2/id-upgrade/sections/current-id`, currentLicense, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public postCurrentLicenseAndPersonalInfo(licenseAndPersonalRequest: unknown): Observable<RealIdEdlDTO> {
    return this._handlePost$<RealIdEdlDTO>(`/v2/id-upgrade/transactions`, licenseAndPersonalRequest, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public postLicenseReciprocityCurrentLicenseAndPersonalInfo(
    licenseAndContactRequest: LicenseReciprocityRequest,
  ): Observable<LicenseReciprocityResponseDTO> {
    return this._handlePost$<LicenseReciprocityResponseDTO>(`/public/v1/license-reciprocity/transactions`, licenseAndContactRequest, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public putPersonalInfo(personalInfo: any): Observable<SectionResponse> {
    return this._handlePut$<SectionResponse>(`/v2/id-upgrade/sections/contact-info`, personalInfo, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public putLicenseClassChangeAddressSection(addressRequest: AddressRequest): Observable<SectionStatus[]> {
    return this._handlePut$(`/public/v1/license-class-change/transactions/current/sections/address-change`, addressRequest);
  }

  public putLicenseClassChangeForReview(): Observable<LicenseClassChangeTransaction> {
    return this._handlePut$<LicenseClassChangeTransaction>(`/public/v1/license-class-change/transactions/current/review`, {}, {});
  }

  public getLanguageSelections(): Observable<DropdownOption[]> {
    return this._handleGet$(`/permit/test/lookup/language`);
  }

  public getEyeColorSelections(): Observable<DropdownOption[]> {
    return this._handleGet$(`/permit/test/lookup/eyeColor`);
  }

  public getPreFilledPdf(pdfFormType: string, transactionIdString: string): Observable<Blob> {
    return this._handleGet$(`/public/v1/formfill`, {
      params: {
        transaction_form: pdfFormType,
        transaction_id: transactionIdString,
      },
      responseType: 'blob',
    });
  }

  public getPreFilledPdfSummary(pdfFormType: string, transactionIdString: string): Observable<string> {
    return this._handleGet$(`/public/v1/formfill/summary`, {
      params: {
        transaction_form: pdfFormType,
        transaction_id: transactionIdString,
      },
      responseType: 'text/markdown; charset=UTF-8',
    });
  }

  public downloadFile(pdfUrl: string, fileName: string): void {
    // Create a new anchor element
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = fileName;
    downloadLink.style.display = 'none'; // Hide the link

    // Append to the document
    document.body.appendChild(downloadLink);

    // Trigger the download
    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);
  }
}
