import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CountyModel } from '@dmv/common';
import { Observable } from 'rxjs';
import { FindTransactionsParams, PermitModel, UpdateHoldModel } from '../models';
import { ValidateUtil } from '../utils';

@Injectable({ providedIn: 'root' })
export class PermitService {
  public allCounties: CountyModel[] = null;

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  public transactionPath = 'permit';

  constructor(private readonly _http: HttpClient, @Inject('env') private readonly _env) {}

  public findPermits(paramsToValidate: FindTransactionsParams) {
    return this._http.get(`${this._env.apiUrl}/permits`, {
      params: ValidateUtil.validateHttpParams(paramsToValidate),
    });
  }

  public getTransaction(id: string): Observable<PermitModel> {
    return this._http.get<PermitModel>(`${this._env.apiUrl}/${this.transactionPath}/${id}`);
  }

  public rejectTransaction(id: string, message: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/rejectPermit`, { rejectionReason: message });
  }

  public approveTransaction(id: string, initials: string, office: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/approvePermit`, { initials, office });
  }

  public requestDocuments(id: string, content: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/requestDocuments`, { content });
  }

  public setTransactionStatus(id: string, status: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/setStatus`, { status });
  }

  public updateHoldStatus(id: string, updateHoldModel: UpdateHoldModel): Observable<void> {
    return this._http.patch<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}`, updateHoldModel);
  }

  public addViewAction(id: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/view`, this.httpOptions);
  }
}
