export enum DocumentType {
  BILL_OF_SALE = 1,
  VEHICLE_REGISTRATION_APPLICATION = 2,
  INSURANCE_CARD = 3,
  MCO_MSO = 4,
  ORIGINAL_TITLE = 6,
  STATEMENT_OF_TRANSACTION = 7,
  CLAIM_FOR_SALES_AND_USE_TAX_EXEMPTION_TITLE_REGISTRATION = 8,
  DTF_802 = 7,
  DTF_803 = 8,
  DTF_804 = 9,
  LICENSE_PERMIT_NON_DRIVER_ID = 10,
  OUT_OF_STATE_LICENSE = 11,
  US_PASSPORT = 12,
  US_BIRTH_CERTIFICATE = 13,
  FOREIGN_PASSPORT_DEPRECATED = 14,
  MV_44_APPLICATION_FOR_ID = 15,
  SOCIAL_SECURITY_CARD = 16,
  SOCIAL_SECURITY_INELIGIBILITY_LETTER = 17,
  AFFIDAVIT_OF_NEVER_SSN = 18,
  FOREIGN_BIRTH_CERTIFICATE = 19,
  BORDER_CROSSING_CARD = 20,
  CERTIFICATE_OF_NATURALIZATION = 21,
  CONSULAR_ID_DOCUMENT = 22,
  ASSISTED_LIVING = 23,
  ANNUAL_SOCIAL_SECURITY_STATEMENT = 24,
  BANK_STATEMENT = 25,
  CANCELLED_CHECK = 26,
  COLLEGE_PHOTO_ID_CARD = 27,
  SALES_TAX_APPROVAL = 28,
  CERTIFICATE_OF_TITLE = 29,
  PAYMENT_AUTHORIZATION_FORM = 30,
  POWER_OF_ATTORNEY = 31,
  OTHER = 32,
  OTEHR_VERIFICATIONS = 33,
  LIEN_RELEASE = 34,
  SALES_TAX_FORM = 35,
  FOREIGN_DRIVER_LICENSE = 36,
  UNEXPIRED_PERMANENT_RESIDENT = 37,
  ST_REGIS_MOHAWK_TRIBAL_PHOTO_ID_CARD = 38,
  TRIBAL_BIRTH_CERTIFICATE = 39,
  US_CONSULAR_REPORT_BIRTH_ABROAD = 40,
  UNITED_STATES_MILITARY_PHOTO_ID_CARD = 41,
  MV_45B_STATEMENT_OF_IDENTITY = 43,
  MV_45A_DMV_STATEMENT_OF_IDENTITY = 44,
  MV_45_DMV_STATEMENT_OF_IDENTITY = 45,
  FEDERAL_OR_NY_STATE_INCOME_TAX_OR_EARNING_STATEMENT = 46,
  NEW_YORK_STATE_MEDICAL_CARD_CBIC = 50,
  NEW_YORK_STATE_PISTOL_PERMIT = 51,
  NEW_YORK_STATE_PROFESSIONAL_LICENSE = 52,
  OUT_OF_STATE_TRANSFERABLE_REGISTRATION = 57,
  US_MARRIAGE_DIVORCE_COURT_NAME_CHANGE_DECREE = 58,
  TRANSFERABLE_REGISTRATION = 61,
  OUT_OF_STATE_ALL_ID = 74,
  NEW_YORK_STATE_ENHANCED_DRIVER_LICENSE = 75,
  UTILITY_BILL = 76,
  HIGH_SCHOOL_DIPLOMA = 77,
  US_PAY_STUB_W2_OR_1099 = 78,
  US_MUNICIPAL_ID_CARD = 79,
  POSTMARKED_MAIL = 80,
  MV_44NYR = 81,
  NEW_YORK_STATE_FEDERAL_INCOME_TAX = 82,
  LIFE_INSURANCE_POLICY = 83,
  MEDICAL_EXAMINER_CERTIFICATE = 84,
  RESIDENCIAL_LEASE = 85,
  SUPPLEMENTAL_SOCIAL_SECURITY_INCOME_STATEMENT = 86,
  ANNUAL_SOCIAL_SECURITY_STATEMENT_CURRENT_YEAR = 87,
  PROOF_OF_HOMEOWNERS_RENTERS_INSURANCE = 88,
  PROOF_OF_CURRENT_MORTGAGE = 89,
  PROPERTY_SCHOOL_TAX_BILL = 90,
  VOTER_REGISTRATION_NOTIFICATION_CARD = 91,
  PROPERTY_DEED = 92,
  MILITARY_ORDERS = 93,
  WELFARE_BENEFIT_STATEMENT = 94,
  RETIREMENT_STATEMENT = 95,
  SELECTIVE_SERVICE_CARD = 96,
  JURY_DUTY_NOTICE = 98,
  PARENT_SPOUSE_ID = 99,
  MV_45_DMV_STATEMENT_OF_IDENTITY_BY_PARENT_GUARDIAN = 100,
  EMPLOYMENT_AUTHORIZATION_CARD = 102,
  EMPLOYMENT_AUTHORIZATION_CARD_ISSUED = 103,
  IRS_TAX_TRANSCRIPT = 108,
  NY_STATE_INTERIM_LICENSE = 109,
  MV_50 = 116,
  EXPIRED_PERMANENT_RESIDENT_CARD = 117,
  ORIGINAL_CERTIFIED_DRIVER_ABSTRACT_FROM_LAST_STATE = 118,
  REAL_ENHANCED_ID = 119,
  REAL_ENHANCED_NON_DRIVER_ID = 120,
  US_PASSPORT_CARD = 121,
  NEW_YORK_STATE_NON_DRIVER_ID = 122,
  STO_PAPERWORK = 123,
  US_COMPUTER_PRINTED_PAYSTUB = 124,
  CERTIFIED_COPY_OF_CERTIFICATE_OF_INCORPORATION = 126,
  CERTIFIED_COPY_OF_CERTIFICATE_OF_PARTNERSHIP = 127,
  CERTIFICATE_UNDER_SEAL_ISSUED_BY_NY_STATE_DEPARTMENT_OF_STATE = 128,
  NY_STATE_DEPARTMENT_OF_STATE_FILING_RECEIPT = 129,
  NY_STATE_TITLE_OF_REGISTRATION = 130,
  DOING_BUSINESS_AS_DBA_FILING_RECEIPT = 131,
  CERTIFIED_COPY_OF_CERTIFICATE_OF_THE_ASSUMED_NAME = 132,
  SNOWMOBILE_REGISTRATION_TITLE_APPLICATION = 133,
  CLUB_ASSOCIATION_VOUCHER = 134,
  MV82BV_BOAT_REGISTRATION = 135,
  COAST_GUARD_1270 = 136,
  COAST_GUARD_1258 = 137,
  CREDIT_CARD_STATEMENT = 138,
  PAY_STUB_W_SSN = 139,
  NYS_ORIGINAL_TITLE_BOAT = 140,
  NYS_ORIGINAL_TITLE_TRAILER = 141,
  NYS_TRANSFERABLE_REGISTRATION_BOAT = 142,
  NYS_TRANSFERABLE_REGISTRATION_TRAILER = 143,
  BOAT_LIEN_RELEASE = 144,
  TRAILER_LIEN_RELEASE = 145,
  MV_45C = 147,
  MV_1R = 148,
  DOCCS_RELEASED_OFFENDER_ID_CARD = 149,
  MISCELLANEOUS_DOCUMENTS = 150,
  UNEMPLOYMENT_BENEFIT_STATEMENT = 97,
  US_PAY_STUB_W2_OR_1099_SHOWING_FULL_SOCIAL_SECURITY_NUMBER = 151,
  MISCELLANEOUS_DOCUMENTS_LEGAL_NAME = 153,

  // These will be deprecated in a TECH-DEBT ticket
  DRIVING_ABSTRACT = 154,
  NEW_YORK_STATE_ORIGINAL_TITLE = 166,
  NEW_YORK_STATE_TRANSFERABLE_REGISTRATION = 167,
  MANUFACTURERS_CERTIFICATE_OF_ORIGIN = 168,

  OUT_OF_STATE_ORIGIN_TITLE = 169,
  OUT_OF_STATE_CERTIFIED_COPY_OF_TITLE = 170,
  OUT_OF_STATE_VEHICLE_ABSTRACT = 171,
  LETTER_OF_PERMISSION = 172,

  LEASE_AGREEMENT = 176,
  DEATH_CERTIFICATE = 177,
  TRANSFER_OF_VEHICLE_OF_DECEASED_PERSON = 178,
  AFFIDAVIT_FOR_TRANSFER_OF_MOTOR_VEHICLE = 179,
  LETTERS_TESTAMENTARY = 180,
  LETTERS_OF_ADMINISTRATION = 181,
  AFFIDAVIT_FROM_THE_VOLUNTARY_ADMINISTRATOR = 182,
  INSURANCE_CERTIFICATE = 183,
  LETTER_FROM_NYC_TAXI_AND_LIMOUSINE_COMMISSION = 184,
  CERTIFICATION_FOR_TAXI_PLATE = 185,
  EXCEPT_VEHICLE_CERTIFICATION = 186,
  CERTIFIED_FARM_VEHICLE_USE = 187,
  OUT_OF_STATE_DEALER_BILL_OF_SALE = 188,
  DEALER_BILL_OF_SALE = 189,
  FOREIGN_PASSPORT_WITH_VALID_VISA_AND_I_94 = 190,
  FOREIGN_PASSPORT_BIOPAGE = 191,
  I_94 = 192,
  FOREIGN_PASSPORT_VISA = 193,
  FOREIGN_PASSPORT_WITH_VALID_VISA_AND_CBP_STAMP = 194,
  CBP_STAMP = 195,
  FOREIGN_PASSPORT_WITH_I_551_STATEMENT_ON_THE_VISA = 196,
  FOREIGN_PASSPORT_VISA_WITH_I_551_STATEMENT = 197,
  FOREIGN_PASSPORT_WITH_I_551_STAMP = 198,
  I_551_STAMP = 199,
  FOREIGN_PASSPORT_WITH_PAROLEE_STAMP = 200,
  PAROLE_STAMP = 201,
  FOREIGN_PASSPORT_WITHOUT_ACCOMPANYING_DOCUMENTATION = 202,
}
