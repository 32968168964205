import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarkdownPipe } from './directives/markdown.pipe';
import { WindowReferenceService } from './services/window-reference.service';

@NgModule({
  declarations: [MarkdownPipe],
  exports: [MarkdownPipe],
  imports: [CommonModule],
  providers: [WindowReferenceService],
})
export class PublicSharedUtilsModule {}
