import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiConfiguration, API_CONFIGURATION } from '../api/api.type';

@Injectable()
export class BaseApiService {
  constructor(@Inject(API_CONFIGURATION) protected readonly _apiConfiguration: ApiConfiguration, protected readonly _http: HttpClient) {}

  protected _buildHttpParams(params: Record<string, any>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const validParams: Record<string, any> = {};

    Object.keys(params).forEach((key: string) => {
      const val = params[key];
      if (val) {
        validParams[key] = val;
      }
    });

    return validParams;
  }

  protected get _baseUrl(): string {
    return this._apiConfiguration.baseUrl;
  }

  protected _handleDelete$<T>(urlSubstring: string): Observable<T> {
    return this._http.delete<T>(`${this._baseUrl}${urlSubstring}`).pipe(catchError(this._handleError));
  }

  protected _handleGet$<T>(urlSubstring: string, urlParams = {}): Observable<T> {
    return this._http.get<T>(`${this._baseUrl}${urlSubstring}`, urlParams).pipe(catchError(this._handleError));
  }

  protected _handleHead$<T>(urlSubstring: string, urlParams = {}): Observable<T> {
    return this._http.head<T>(`${this._baseUrl}${urlSubstring}`, urlParams).pipe(catchError(this._handleError));
  }

  protected _handlePatch$<T>(urlSubstring: string, body = {}): Observable<T> {
    return this._http.patch<T>(`${this._baseUrl}${urlSubstring}`, body).pipe(catchError(this._handleError));
  }

  protected _handlePost$<T>(urlSubstring: string, body = {}, options = {}): Observable<T> {
    return this._http.post<T>(`${this._baseUrl}${urlSubstring}`, body, options).pipe(catchError(this._handleError));
  }

  protected _handlePut$<T>(urlSubstring: string, body = {}, options = {}): Observable<T> {
    return this._http.put<T>(`${this._baseUrl}${urlSubstring}`, body).pipe(catchError(this._handleError));
  }

  private _handleError(error: HttpErrorResponse): Observable<any> {
    // TODO: log with logging service

    return throwError(error);
  }
}
