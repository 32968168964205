<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ title }}
      <lib-docai-checklist-popover [documentAiChecklist]="documentAiChecklist"></lib-docai-checklist-popover>
    </h4>
    <lib-close-button (closeModalEvent)="close()"></lib-close-button>
  </div>
  <div class="modal-body pdf-loading" *ngIf="loading">
    <lib-spinner [showSpinner]="loading" bdColor="TRANSPARENT" color="DMVBLUE"></lib-spinner>
  </div>
</div>

<div class="h-100">
  <h5 class="modal-title modal-error" *ngIf="error">{{ errorTitle }}</h5>
  <ngx-extended-pdf-viewer
    #pdfViewer
    [showDrawEditor]="false"
    [showTextEditor]="false"
    [showStampEditor]="false"
    [showOpenFileButton]="false"
    [enablePrint]="showPrintButton"
    [showDownloadButton]="showDownloadButton"
    (pdfLoaded)="pageLoaded()"
  ></ngx-extended-pdf-viewer>
</div>
