import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { AlignPosition, JustifyPosition, ToastPosition } from '../../models/toast.model';
import { slideInDown, slideInUp } from './animations';

@Component({
  animations: [slideInUp, slideInDown],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-toast',
  styleUrls: ['./toast.component.scss'],
  templateUrl: './toast.component.html',
})
export class CustomToastComponent implements OnDestroy, AfterViewInit {
  @Input() public showCloseButton = false;
  @Input() public autohide = false;
  @Input() public delay = 3000;
  @Input() public heading: string | null = null;
  @Input() public type: 'error' | 'info' | 'success' | 'warning' = 'error';
  @Input() public float = false;
  @Input() public set show(showToast: boolean) {
    if (this.autohide === true) {
      setTimeout(() => {
        this.hideToast();
      }, this.delay);
    }
    this.showToast = showToast;
  }
  @Input() public set floatPosition(postion: ToastPosition) {
    if (postion) {
      this.alignPosition = postion.split('-')[0] as AlignPosition;
      this.justifyPosition = postion.split('-')[1] as JustifyPosition;
    }
  }

  @Input() public set showIcon(v: boolean) {
    this._showIcon = coerceBooleanProperty(v);
  }
  public get showIcon(): boolean {
    return this._showIcon;
  }

  // NOTE: if focus is needed, include 'setFocus' before 'show' in tag
  // e.g. <dmv-toast [setFocus]="true" [show]="CONDITION" [type]="'error'">
  @Input() public focusMonitor?: Observable<boolean>;
  @Input() public set setFocus(v: boolean) {
    const value = coerceBooleanProperty(v);
    if (value && value !== this._setFocus) {
      this._doFocus();
    }

    this._setFocus = value;
  }
  public get setFocus(): boolean {
    return this._setFocus;
  }

  @Output() public readonly toastHidden = new EventEmitter();

  @ViewChildren('toastContent') public toastContent?: QueryList<ElementRef>;

  public showToast = false;
  public alignPosition: AlignPosition = AlignPosition.BOTTOM;
  public justifyPosition: JustifyPosition = JustifyPosition.CENTER;

  public iconTitles = {
    error: 'Error icon',
    info: 'Info icon',
    success: 'Success icon',
    warning: 'Warning icon',
  };

  private _setFocus = true;
  private _showIcon = true;

  private readonly _destroyed: Subject<void> = new Subject<void>();

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.setFocus && this._doFocus();

    this.focusMonitor &&
      this.focusMonitor
        .pipe(
          takeUntil(this._destroyed),
          tap((r: boolean) => r === true && this._doFocus()),
        )
        .subscribe();
  }

  public ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public hideToast() {
    this.toastHidden.emit();
    this._changeDetectorRef.detectChanges();
  }

  private _doFocus(): void {
    if (!this.toastContent) {
      return;
    }

    if (this.toastContent && this.toastContent.first && this.focusMonitor) {
      this.toastContent.first.nativeElement.focus();

      return;
    }

    this.toastContent.changes
      .pipe(
        take(1),
        tap((r: QueryList<ElementRef>) => {
          if (this.showToast && r.length > 0) {
            r.first.nativeElement.focus();
            this._changeDetectorRef.detectChanges();
          }
        }),
      )
      .subscribe();
  }
}
