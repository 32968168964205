export enum TransactionType {
  DOCCS_NDID = 'doccsNdid',
  ID_UPGRADE = 'idUpgrade',
  LICENSE_CLASS_CHANGE = 'licenseClassChange',
  LICENSE_RECIPROCITY = 'licenseReciprocity',
  ORIGINAL_NON_DRIVER_ID = 'originalNdid',
  ORIGINAL_REGISTRATION = 'originalRegistration',
  PERMIT = 'permit',
  REGISTRATION = 'registration',
}
