import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockOktaAuth {
  public readonly authStateManager: Observable<boolean> = of(false);

  private originalUri = '';

  public isAuthenticated = () => true;

  public getOriginalUri = () => '';
  public setOriginalUri = (originalUri: string) => (this.originalUri = originalUri);
  public signInWithRedirect = (_redirectUrl: string) => {
    // empty method stub
  };
}
