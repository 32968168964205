import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppointmentInfoModalComponent } from './appointment-info-modal.component';

@NgModule({
  declarations: [AppointmentInfoModalComponent],
  exports: [AppointmentInfoModalComponent],
  imports: [CommonModule],
})
export class AppointmentInfoModalModule {}
