import { Injectable } from '@angular/core';
import { BottomNavigation, StepType } from '@dmv/public/shared/http';

@Injectable({
  providedIn: 'root',
})
export class MvWrapperDataService {
  private readonly _downloadStepConfiguration = {
    continueText: 'Continue',
    previousText: 'Previous',
    showContinue: true,
    showPrevious: false,
    showSubmit: false,
    submitText: '',
  };
  private readonly _uploadStepConfiguration = {
    continueText: 'Continue',
    previousText: 'Previous',
    showContinue: true,
    showPrevious: true,
    showSubmit: false,
    submitText: '',
  };
  private readonly _reviewStepConfiguration = {
    continueText: '',
    previousText: 'Edit',
    showContinue: false,
    showPrevious: true,
    showSubmit: true,
    submitText: 'Confirm',
  };

  public getStepConfiguration(currentStep: StepType): BottomNavigation {
    switch (currentStep) {
      case 'document_upload':
        return this._uploadStepConfiguration;
      case 'review':
        return this._reviewStepConfiguration;
      default:
        return this._downloadStepConfiguration;
    }
  }
}
