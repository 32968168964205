import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions } from '../actions';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  constructor(private readonly _store: Store) {}

  public handleLogout(): void {
    this._store.dispatch(AuthActions.handleLogout());
  }
}
