import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  constructor(@Inject('env') private readonly _env, private readonly _httpClient: HttpClient) {}

  public getQrCode$(reservationId: string): Observable<Blob> {
    return this._httpClient.get(`${this._env.apiUrl}/qrcode`, { params: { source: reservationId }, responseType: 'blob' });
  }
}
