import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule, CommonMaterialModule } from '@dmv/common';
import { CommonDashboardDocumentTypesModule } from '../dashboard-document-types/dashboard-document-types.module';
import { DashboardAppointmentInformationComponent } from './dashboard-appointment-information.component';

@NgModule({
  declarations: [DashboardAppointmentInformationComponent],
  exports: [DashboardAppointmentInformationComponent],
  imports: [AccordionModule, CommonModule, CommonMaterialModule, CommonDashboardDocumentTypesModule],
})
export class CommonDashboardAppointmentInformationModule {}
