<ng-container *ngIf="this.displayDocumentImages">
  <div class="object-title uploads-title">{{ uploads?.title }}</div>
  <ng-container *ngFor="let document of uploads?.documents">
    <ng-container *ngIf="document.imgSrc.length > 0">
      <div class="proof-title">{{ document.title }}</div>
      <div
        *ngFor="let image of document.imgSrc"
        [ngClass]="{ 'docai-image': docAIFeatureFlagEnabled, 'preview-image': !docAIFeatureFlagEnabled }"
      >
        <img class="img-fluid" [src]="image" alt="User uploaded document for {{ document.title }}" />
      </div>
    </ng-container>
  </ng-container>
</ng-container>
