import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@dmv/public/common';
import { Section, SurveySectionType } from '@dmv/public/shared/http';
import { AuthenticationService } from '@dmv/shared/utils-authentication';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { EMPTY, from, of, OperatorFunction } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AuthActions } from '../actions';

export const mapToLogoutModalAction: OperatorFunction<
  readonly [Action, Section, Section[], boolean],
  { activeSection: SurveySectionType | null; sections: Section[]; isDirty: boolean } & TypedAction<'[Modal] Show Logout Modal'>
> = map(([_, activeSection, sections, isDirty]) =>
  AuthActions.showLogoutModal({ activeSection: activeSection ? (activeSection.id as SurveySectionType) : null, isDirty, sections }),
);

@Injectable()
export class AuthEffects {
  public handleLogout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthActions.showLogoutModal),
      mergeMap(action => {
        // If there's no active section or the section is not dirty, log out directly
        if (!action.activeSection || !action.isDirty) {
          return of(AuthActions.logout());
        }

        // Otherwise, show a confirmation modal
        const dialogRef = this._dialog.open(ConfirmationModalComponent, {
          ariaLabel: 'Are you sure you want to log out?',
          autoFocus: false,
          data: {
            cancelLabel: 'Cancel',
            confirmLabel: 'Log out',
            content: 'The information you have entered for this section will not be saved',
            showCancel: true,
            showConfirm: true,
            title: 'Are you sure you want to log out?',
          },
          width: '400px',
        });

        // Only dispatch the logout action if the user confirms
        return dialogRef.afterClosed().pipe(mergeMap(({ confirmed }) => (confirmed ? of(AuthActions.logout()) : EMPTY)));
      }),
    ),
  );

  public logout$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AuthActions.logout),
        map(_ => from(this._authenticationService.logout())),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _dialog: MatDialog,
    private readonly _authenticationService: AuthenticationService,
  ) {}
}
