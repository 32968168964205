import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_CONFIGURATION, ApiConfiguration } from '../api/api.type';
import { BaseApiService } from '../base/base-api.service';
import { TaxRateJurisdictionModel, TaxRateJurisdictionResponse } from '../models/tax-rate-jurisdiction.model';

@Injectable({
  providedIn: 'root',
})
export class TaxRateJurisdictionService extends BaseApiService {
  public jurisdictionSubject = new BehaviorSubject<TaxRateJurisdictionModel[]>([]);

  constructor(@Inject(API_CONFIGURATION) protected readonly _apiConfiguration: ApiConfiguration, protected readonly _http: HttpClient) {
    super(_apiConfiguration, _http);
  }

  public getAllJurisdictions(): Observable<TaxRateJurisdictionResponse> {
    return this._handleGet$<TaxRateJurisdictionResponse>(`/public/v1/tax-rate/jurisdictions`).pipe(
      tap(response => this.jurisdictionSubject.next(response.jurisdictions)),
    );
  }

  public getApplicableCities(selectedCounty: string): TaxRateJurisdictionModel[] | null {
    if (this.jurisdictionSubject.value && this.jurisdictionSubject.value.length > 0) {
      return this.jurisdictionSubject.value.filter(jurisdiction => jurisdiction.countyName === selectedCounty);
    }

    return null;
  }

  public getCityName(jurisdictionId: number): string {
    if (this.jurisdictionSubject.value && this.jurisdictionSubject.value.length > 0) {
      return this.jurisdictionSubject.value.find(jsd => jsd.id === jurisdictionId)?.cityName || 'N/A';
    }

    return 'N/A';
  }

  public getJurisdiction(jurisdictionId: number | null): TaxRateJurisdictionModel | null {
    return this.jurisdictionSubject.value?.find(jsd => jsd.id === jurisdictionId) || null;
  }
}
