import { DocumentsReview, DocumentTypes, UploadItem } from './document-upload.model';
import { LicenseReciprocitySectionId } from './license-reciprocity.model';
import { OriginalNdidSectionId } from './original-ndid.model';
import { OriginalRegistrationSectionId } from './original-registration.model';
import { IdUpgradeSectionId } from './read-id-edl-dto.model';
import { SurveySectionType } from './transaction.model';
import { OriginalPermitSectionId } from './workflow.type';

export type QuestionType = 'checkbox' | 'points_checkbox' | 'radio' | 'select' | 'text';

export interface MultipleSurveyStatus {
  isComplete: boolean;
  hasError: boolean;
  errorText: string;
}

export type MultipleSurveyStatusType = Record<string, MultipleSurveyStatus>;

export interface ReviewQuestion {
  title: string;
  showList?: boolean;
  answer?: string;
}

export interface Question {
  additionalInfo?: string;
  answerPrompts: AnswerPrompt[];
  answers: string[];
  errorMessage?: string;
  hasPreviouslyUsedUpload?: boolean;
  id: string;
  maxLength?: number;
  maxPoints?: number;
  minPoints?: number;
  name: string;
  question: string;
  placeholder?: string;
  pointsThreshold?: number;
  stepName: string;
  stepSectionName: string;
  stepSectionFlowName: string;
  title?: string;
  totalPoints?: number;
  notes?: string[];
  type: QuestionType;
  warning?: string;
}

export const DEFAULT_QUESTION: Question = {
  additionalInfo: '',
  answerPrompts: [],
  answers: [],
  id: '',
  maxLength: 0,
  name: '',
  notes: [],
  placeholder: '',
  question: '',
  stepName: '',
  stepSectionFlowName: '',
  stepSectionName: '',
  type: 'radio',
};

export const INITIAL_SURVEY_STATE: Question = DEFAULT_QUESTION;

export class QuestionImpl implements Question {
  public answerPrompts: Question['answerPrompts'];
  public answers: Question['answers'];
  public errorMessage: Question['errorMessage'];
  public hasPreviouslyUsedUpload: Question['hasPreviouslyUsedUpload'];
  public id: Question['id'];
  public additionalInfo: Question['additionalInfo'];
  public name: Question['name'];
  public pointsThreshold: Question['pointsThreshold'];
  public maxPoints: Question['maxPoints'];
  public minPoints: Question['minPoints'];
  public question: Question['question'];
  public stepName: Question['stepName'];
  public stepSectionFlowName: Question['stepSectionFlowName'];
  public stepSectionName: Question['stepSectionName'];
  public title: Question['title'];
  public totalPoints: Question['totalPoints'];
  public type: Question['type'];
  public notes: Question['notes'];
  public placeholder: Question['placeholder'];
  public maxLength: Question['maxLength'];

  constructor(question: Question) {
    this.answerPrompts = question.answerPrompts.map(a => ({ ...a }));
    this.answers = [...question.answers];
    if (question.errorMessage) {
      this.errorMessage = question.errorMessage;
    }
    if ('hasPreviouslyUsedUpload' in question) {
      this.hasPreviouslyUsedUpload = question.hasPreviouslyUsedUpload;
    }
    this.id = question.id;
    this.additionalInfo = question.additionalInfo;
    this.name = question.name;
    if (question.pointsThreshold) {
      this.pointsThreshold = question.pointsThreshold;
    }
    if (question.maxPoints) {
      this.maxPoints = question.maxPoints;
    }

    if (question.minPoints) {
      this.minPoints = question.minPoints;
    }
    this.question = question.question;
    this.stepName = question.stepName;
    this.stepSectionFlowName = question.stepSectionFlowName;
    this.stepSectionName = question.stepSectionName;
    this.title = question.title;
    if ('totalPoints' in question) {
      this.totalPoints = question.totalPoints;
    }
    this.type = question.type;
    if (question.notes) {
      this.notes = question.notes;
    }
    if (question.placeholder) {
      this.placeholder = question.placeholder;
    }
    if (question.maxLength) {
      this.maxLength = question.maxLength;
    }
  }
}

export interface AnswerPrompt {
  additionalInfo?: string;
  customImage?: string;
  customPreview?: boolean;
  documentIds: number[];
  foreign?: boolean;
  hasBack?: boolean;
  id: string;
  instructions?: string;
  link?: string;
  metadata: AnswerMetadata;
  isReusable?: boolean;
  name?: string;
  popularity?: number;
  prompt: string;
  promptStyles?: { additionalInfo?: string[]; prompt?: string[] };
  previouslyUsedDocumentId?: number;
  previouslyUsedUpload?: boolean;
  value: string;
  verification?: boolean;
  warning?: string;
  order?: number;
}

export interface AnswerEvent {
  error?: string;
  question?: Question;
  questionId: string;
  questionIndex?: number;
  questionType: QuestionType;
  selection: AnswerPrompt;
  currentPoints?: number;
  points?: number;
}

export interface AnswerMetadata {
  points?: number;
  popularity?: number;
  idUpgradeEligible?: boolean;
  /**
   * Overriding whether a document is eligible for ID upgrade. Applies to Proof of Birth sections.
   */
  idUpgradeEligibleOpOverride?: boolean;
}
export interface CheckboxAnswerEvent extends AnswerEvent {
  checked: boolean;
  currentPoints?: number;
  error?: string;
  points?: number;
}

export interface SurveyEvent {
  questions: Question[];
  questionIndex: number;
}

export interface SurveySectionState {
  /**
   * A record of question ID to question context
   */
  answers: Record<string, { answerId: string; answers: string[]; questionPrompt: string }>;
  displayErrors: DisplayErrors;
  isReviewed: boolean;
  // TODO: we want to remove the transaction specific section ids from
  // the survey section state/store
  sectionId:
    | OriginalRegistrationSectionId
    | LicenseReciprocitySectionId
    | OriginalNdidSectionId
    | OriginalPermitSectionId
    | IdUpgradeSectionId
    | SurveySectionType;
  sectionReview: DocumentsReview;
  surveyWrapperState: SurveyWrapperState;
  uploadItems: UploadItem[];
}

// TODO: replace instances of current references to this model with new
// DisplayErrors interface
export interface DisplayErrors {
  display: boolean;
  errors: string[];
}

export interface SurveyWrapperState {
  questionIndex: number;
  questions: Question[];
  requiredDocumentIds: number[];
  /**
   * Used for dynamic survey sections where we deal with one question at a time.
   * This field stores the user's current selection which will be applied on Continue
   */
  tempRequiredDocumentIds?: number[];
}

export interface SurveySectionDocumentTypes {
  sectionName: OriginalNdidSectionId;
  documentTypes: DocumentTypes[];
}
