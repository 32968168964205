import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'lib-app-base',
  styleUrls: ['./base.component.scss'],
  templateUrl: './base.component.html',
})
export class BaseComponent implements OnInit, OnDestroy {
  public destroy$: Subject<boolean> = new Subject<boolean>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
