import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { from, Observable } from 'rxjs';
import { EventsAdapter } from '../events.adapter';
import { EventsTag } from '../events.service';

@Injectable()
export class GoogleTagManagerAdapter implements EventsAdapter {
  constructor(private readonly _googleTagManagerService: GoogleTagManagerService) {}

  public initialize(): Observable<boolean> {
    return from(this._googleTagManagerService.addGtmToDom());
  }

  public pushEvent(eventsTag: EventsTag): Promise<void> {
    // this will call addGtmToDom if required
    return this._googleTagManagerService.pushTag(eventsTag);
  }
}
