import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { OKTA_CONFIG } from '@okta/okta-angular';
import { AuthRequiredFunction } from '@okta/okta-angular/src/okta/models/okta.config';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { OktaAuthenticationAdapter } from './adapters/okta-authentication.adapter';
import { AuthenticationAdapter } from './authentication.adapter';
import { AuthenticationService } from './authentication.service';
import { OktaLoginComponent } from './okta-login/okta-login.component';

const onAuthRequired: AuthRequiredFunction = (oktaAuth, injector) => {
  // Use injector to access any service available within your application
  const router: Router = injector.get(Router);

  // Redirect the user to our custom login page
  router.navigate(['/login']);
};

@NgModule({
  declarations: [OktaLoginComponent],
  imports: [CommonModule, RouterModule, RouterModule.forChild([])],
})
export class SharedUtilsAuthenticationModule {
  public static useOkta(oktaAuthOptions: OktaAuthOptions): ModuleWithProviders<SharedUtilsAuthenticationModule> {
    const oktaAuth = new OktaAuth(oktaAuthOptions);

    return {
      ngModule: SharedUtilsAuthenticationModule,
      providers: [
        {
          provide: AuthenticationAdapter,
          useClass: OktaAuthenticationAdapter,
        },
        {
          provide: OKTA_CONFIG,
          useValue: {
            oktaAuth,
            onAuthRequired,
          },
        },
        AuthenticationService,
      ],
    };
  }
}
