import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlagUserModel } from './models/feature-flag-user.model';

@Injectable()
export abstract class FeatureFlagAdapter {
  public abstract initialLoaded$: Observable<boolean>;
  public abstract identified$: Observable<boolean>;

  /**
   * Used to Identify the user with the feature flag provider
   * and to control flag values by user. When null is passed,
   * it should successfully resolve the `identified$` observable.
   */
  public abstract identify(featureFlagUserModel?: FeatureFlagUserModel): void;

  public abstract getFeatureFlagValue$<FeatureKeysT>(featureKey: FeatureKeysT): Observable<string | number | boolean>;

  public abstract getFeatureFlagValue<FeatureKeysT>(featureKey: FeatureKeysT): string | number | boolean;
}
