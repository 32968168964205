import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(@Inject(OKTA_AUTH) protected readonly _oktaAuth: OktaAuth, protected readonly _router: Router) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const isAuthenticated = await this._oktaAuth.isAuthenticated();
    if (isAuthenticated) {
      return this._router.parseUrl(route.data.isAuthenticatedGuard.successRedirectUrl);
    } else {
      return this._router.parseUrl(route.data.isAuthenticatedGuard.failRedirectUrl);
    }
  }
}
